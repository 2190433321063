import React, { Component } from "react";
import "./ExpreriancePlanTrips.css";
import NewHeader from "../NewHeader/NewHeader";
import EmptyImage from "../../assets/images/Empty.png";
import CancelledImage from "../../assets/images/Cancelled.png";
import Completed from "../../assets/images/completed-empty.png";
import { ScrollLink, Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faChild,
  faGlassMartiniAlt,
  faWifi,
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons";

import {
  getUpcomingBooking,
  getCancelledBooking,
  getCompletedBooking,
  CancelBookingConfirm,
  CancelBookingConfirmHs,
  getExprerianceUpcomingBooking,
  getExprerianceCancelledBooking,
  getExprerianceCompletedBooking,
  CancelBookingConfirmHsTour

} from "../axios";
import Skeleton from "react-loading-skeleton";
import moment from "moment";

class ExpreriancePlanTrips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plannedTrips: true,
      upcoming: false,
      cancelled: false,
      Completed: false,
      ChangeColor: "reviewPlanned",
      upcomingData: null,
      cancelledData: null,
      completedData: null,
      ChangeColorNew: "tripStatusContainer reviewPlannedNew",
      upcomingTourData: null,
      cancelledTourData: null,
      completedTourData: null,
    };
  }

  PlannedChange = () => {
    this.setState({ plannedTrips: true });
    this.setState({ upcoming: false });
    this.setState({ cancelled: false });
    this.setState({ Completed: false });
    this.setState({ ChangeColor: "reviewPlanned" });
    this.setState({ ChangeColorNew: "tripStatusContainer reviewPlannedNew" });
  };

  upcomingChange = () => {
    this.setState({ plannedTrips: false });
    this.setState({ upcoming: true });
    this.setState({ cancelled: false });
    this.setState({ Completed: false });
    this.setState({ ChangeColor: "reviewUpcoming" });
    this.setState({ ChangeColorNew: "tripStatusContainer reviewUpcomingNew" });

    const userArr = localStorage.getItem("userDetails");
    const userArrNew = JSON.parse(userArr);
    const customer_id = userArrNew.uid;
    let booking_status = 1;
    getUpcomingBooking(customer_id, booking_status).then(
      (data) => {
        console.log(data);
        this.setState({ upcomingData: data });
      },
      (error) => {}
    );

    getExprerianceUpcomingBooking(customer_id, booking_status).then(
      (data) => {
        console.log(data);
        this.setState({ upcomingTourData: data });
      },
      (error) => {}
    );
  };

  cancelledChange = () => {
    this.setState({ plannedTrips: false });
    this.setState({ upcoming: false });
    this.setState({ cancelled: true });
    this.setState({ Completed: false });
    this.setState({ ChangeColor: "reviewCancelled" });
    this.setState({ ChangeColorNew: "tripStatusContainer reviewCancelledNew" });

    const userArr = localStorage.getItem("userDetails");
    const userArrNew = JSON.parse(userArr);
    const customer_id = userArrNew.uid;
    let booking_status = 3;
    getCancelledBooking(customer_id, booking_status).then(
      (data) => {
        console.log(data);
        this.setState({ cancelledData: data });
      },
      (error) => {}
    );

    getExprerianceCancelledBooking(customer_id, booking_status).then(
      (data) => {
        console.log(data);
        this.setState({ cancelledTourData: data });
      },
      (error) => {}
    );
  };

  CompletedChange = () => {
    this.setState({ plannedTrips: false });
    this.setState({ upcoming: false });
    this.setState({ cancelled: false });
    this.setState({ Completed: true });
    this.setState({ ChangeColor: "reviewCompleted" });
    this.setState({ ChangeColorNew: "tripStatusContainer reviewCompletedNew" });

    const userArr = localStorage.getItem("userDetails");
    const userArrNew = JSON.parse(userArr);
    const customer_id = userArrNew.uid;
    let booking_status = 1;
    getCompletedBooking(customer_id, booking_status).then(
      (data) => {
        console.log(data);
        this.setState({ completedData: data });
      },
      (error) => {}
    );

    getExprerianceCompletedBooking(customer_id, booking_status).then(
      (data) => {
        console.log(data);
        this.setState({ completedTourData: data });
      },
      (error) => {}
    );
  };
  CancelBookingConfirmButton = (Locator, CancellationPolicy) => {
    console.log(Locator);
    console.log(CancellationPolicy);

    let CurrentDate = new Date();
    let CurrentDateNew = moment(CurrentDate).format("YYYY-MM-DD");
    // console.log(CurrentDateNew);

    let PercentagePrice = 0;
    CancellationPolicy.map((item, key) => {
      let d1 = new Date("2021-05-10");
      let d2 = new Date(item.attributes.DateFrom);
      console.log(d2);
      if (d1.getTime() >= d2.getTime()) {
        // console.log(d1.getTime() + 'd1');
        // console.log(d2.getTime() + 'd2');
        PercentagePrice = item.attributes.PercentPrice;
        return PercentagePrice;
      }
    });

    console.log(PercentagePrice);

    CancelBookingConfirm(Locator, PercentagePrice).then(
      (data) => {
        //console.log(data);
        // this.setState({ completedData: data });
        window.location.reload(true);
      },
      (error) => {}
    );
  };

  //Hotal HS Cancellation

  CancelBookingConfirmButtonHs = (hsbooking_id) => {
    //console.log(hsbooking_id);
    CancelBookingConfirmHs(hsbooking_id).then(
      (data) => {
        //console.log(data);
        window.location.reload(true);
      },
      (error) => {}
    );
  };

  //Tour HS Cancellation

  CancelBookingConfirmButtonHsTour = (hstourbooking_id) => {
    //console.log(hsbooking_id);
    CancelBookingConfirmHsTour(hstourbooking_id).then(
      (data) => {
        //console.log(data);
        window.location.reload(true);
      },
      (error) => {}
    );
  };

  starsCount = (HotelCategory) => {
    if (HotelCategory == 1) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelCategory == 2) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelCategory == 3) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelCategory == 4) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#ccc"></FontAwesomeIcon>
        </>
      );
    } else if (HotelCategory == 5) {
      return (
        <>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
          <FontAwesomeIcon icon={faStar} color="#000"></FontAwesomeIcon>
        </>
      );
    }
  };
  render() {


    console.log(this.state.upcomingData);
    console.log(this.state.cancelledData);
    console.log(this.state.completedData);

    return (
      <div>
        <NewHeader page={"inner_listing"} />
        <div className="TripContainer" id="container">
          <div className={this.state.ChangeColor} style={{ marginTop: "-5px" }}>
            <div className="landingContainer">
              <h4>My Expreriance Trips</h4>
            </div>
          </div>

          <div className={this.state.ChangeColorNew}>
            <div className="tripStatusWrap">
              <ul>
                <li
                  className={
                    this.state.plannedTrips && "active ColorPlannedTrips"
                  }
                  onClick={this.PlannedChange}
                >
                  <a className="mytripmakeFlex">
                    <span className="cm__myTripSprt--tabUpcoming cm__myTripSprt appendRight10"></span>
                    <span className="tripStatusWrap__statusText">
                      <span className="font16 latoBlack capText">
                        Planned Trips
                      </span>
                      <span className="font12 latoBold"></span>
                    </span>
                  </a>
                </li>
                <li
                  className={this.state.upcoming && "active ColorUpcoming"}
                  onClick={this.upcomingChange}
                >
                  <a className="mytripmakeFlex">
                    <span className="cm__myTripSprt--tabUpcoming cm__myTripSprt appendRight10"></span>
                    <span className="tripStatusWrap__statusText">
                      <span className="font16 latoBlack capText">Upcoming</span>
                      <span className="font12 latoBold"></span>
                    </span>
                  </a>
                </li>
                <li
                  className={this.state.cancelled && "active ColorCancelled"}
                  onClick={this.cancelledChange}
                >
                  <a className="mytripmakeFlex">
                    <span className="cm__myTripSprt--tabCancelled--inactive cm__myTripSprt appendRight10"></span>
                    <span className="tripStatusWrap__statusText">
                      <span className="font16 latoBlack capText">
                        Cancelled
                      </span>
                      <span className="font12 latoBold"></span>
                    </span>
                  </a>
                </li>
                <li
                  className={this.state.Completed && "active ColorCompleted"}
                  onClick={this.CompletedChange}
                >
                  <a className="mytripmakeFlex">
                    <span className="cm__myTripSprt--tabCompleted--inactive cm__myTripSprt appendRight10"></span>
                    <span className="tripStatusWrap__statusText">
                      <span className="font16 latoBlack capText">
                        Completed
                      </span>
                      <span className="font12 latoBold"></span>
                    </span>
                  </a>
                </li>
              </ul>
              <div className="txtFild "></div>
            </div>
          </div>
          <div className="contentWrapper myTrips">
            <div>
              <div className="tripListingWrap">
                <div>
                  {this.state.plannedTrips == true ? (
                    <div className="myTripLstngLobCrd forPlanned">
                      <div className="emptyErr">
                        <div className="emptyErr--imgCont">
                          <img src={EmptyImage} alt="Upcoming Image" />
                        </div>
                        <div>
                          <p className="emptyErr--heading font22 latoBlack appendBottom15">
                            Looks empty, you've no planned trips.
                          </p>
                          <p className="emptyErr--subHeading fomt14 darkGreyText appendBottom25">
                            When you book a trip, you will see your itinerary
                            here.
                          </p>
                          <p>
                            <a href="/">
                              <button className="font14 latoBold blueText myTripBtn myTripBtn--primary ">
                                Plan a trip
                              </button>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {this.state.upcoming == true ? (
                    <div className="myTripLstngLobCrd forUpcoming">
                      {this.state.upcomingData == "noUpcoming" ? (
                        <div className="emptyErr">
                          <div className="emptyErr--imgCont">
                            <img src={EmptyImage} alt="Upcoming Image" />
                          </div>
                          <div>
                            <p className="emptyErr--heading font22 latoBlack appendBottom15">
                              Looks empty, you've no upcoming bookings.
                            </p>
                            <p className="emptyErr--subHeading fomt14 darkGreyText appendBottom25">
                              When you book a trip, you will see your itinerary
                              here.
                            </p>
                            <p>
                              <a href="/">
                                <button className="font14 latoBold blueText myTripBtn myTripBtn--primary ">
                                  Plan a trip
                                </button>
                              </a>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {this.state.upcomingData == null ? (
                            <>
                              <div
                                className="Container-Tile"
                                style={{ height: 250 }}
                              >
                                <div className="ContainerFlex">
                                  <div
                                    className="ContainerImage"
                                    style={{ padding: "15px 10px 10px 10px" }}
                                  >
                                    <Skeleton
                                      style={{ width: "100%", height: "165px" }}
                                    />
                                  </div>
                                  <div
                                    className="ContainerContent"
                                    style={{ padding: "10px 10px 10px 40px" }}
                                  >
                                    <div
                                      className="nameContainer"
                                      style={{ width: "100%" }}
                                    >
                                      <div className="paymakeFlex appendBottom5">
                                        <h3 className="latoBlack font22 blackText">
                                          <Skeleton
                                            style={{ width: "200px" }}
                                          />
                                        </h3>
                                        <span
                                          className="sRating"
                                          style={{
                                            display: "inline-flex",
                                            marginTop: "5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Skeleton
                                            style={{ width: "100px" }}
                                          />
                                        </span>
                                      </div>
                                      <p>
                                        <Skeleton style={{ width: "300px" }} />
                                      </p>
                                      <h3
                                        className="latoBlack font22 blackText"
                                        style={{
                                          marginTop: 10,
                                          fontSize: 18,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <Skeleton style={{ width: "150px" }} />
                                      </h3>

                                      <div class="chkContBooking">
                                        <div class="chkCont__col">
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText 444">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="chkCont__night">
                                            <span>
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.upcomingData.ejuniper && (
                                <>
                                  {" "}
                                  {this.state.upcomingData.ejuniper.propertyData.map(
                                    (item, index) => {
                                      const DataArr =
                                        item.ReadBookingResponse.BookingRS
                                          .Reservations.Reservation.Items
                                          .HotelItem;
                                      const HotelCategoryNew =
                                        DataArr.HotelInfo.HotelCategory;

                                      const HotelCategory = HotelCategoryNew.charAt(
                                        0
                                      );
                                      let date1 = new Date(
                                        DataArr.attributes.Start
                                      );
                                      let date2 = new Date(
                                        DataArr.attributes.End
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status =
                                        item.ReadBookingResponse.BookingRS
                                          .Reservations.Reservation.attributes
                                          .Status;
                                      const Locator =
                                        item.ReadBookingResponse.BookingRS
                                          .Reservations.Reservation.attributes
                                          .Locator;
                                      const CancellationPolicy =
                                        DataArr.CancellationPolicy.PolicyRules
                                          .Rule;

                                      let StatusMsg = "";
                                      if (Status == "PAG") {
                                        StatusMsg =
                                          "Booking confirmed and paid";
                                      } else if (Status == "CON") {
                                        StatusMsg = "Booking confirmed";
                                      } else if (Status == "CAN") {
                                        StatusMsg = "Booking cancelled";
                                      } else if (Status == "CAC") {
                                        StatusMsg = "Booking cancelled";
                                      } else if (Status == "PRE") {
                                        StatusMsg = "Booking on request";
                                      } else if (Status == "PDI") {
                                        StatusMsg = "Booking on request";
                                      } else if (Status == "QUO") {
                                        StatusMsg = "Quotation";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.upcomingData
                                                      .ejuniper.property_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 25 }}
                                                      >
                                                        {DataArr.HotelInfo.Name}
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          HotelCategory
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <span
                                                        className="font14 latoBold blueText myTripBtn myTripBtn--primary "
                                                        onClick={() =>
                                                          this.CancelBookingConfirmButton(
                                                            Locator,
                                                            CancellationPolicy
                                                          )
                                                        }
                                                        id="Cancel_Button"
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                      >
                                                        Cancel Booking
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {DataArr.HotelInfo.Address}
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {
                                                      DataArr.HotelRooms
                                                        .HotelRoom.Name
                                                    }
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {
                                                      DataArr.Prices.Price
                                                        .TotalFixAmounts.Service
                                                        .attributes.Amount
                                                    }
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            DataArr.attributes
                                                              .Start
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            DataArr.attributes
                                                              .Start
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            DataArr.attributes
                                                              .End
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            DataArr.attributes
                                                              .End
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}

                              {this.state.upcomingData.hotel_link && (
                                <>
                                  {" "}
                                  {this.state.upcomingData.hotel_link.propertyData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.upcomingData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.upcomingData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.upcomingData
                                        .hotel_link.bookingArr[index]
                                        .booking_status;
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 1) {
                                        StatusMsg = "Booking confirmed";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.upcomingData
                                                      .hotel_link
                                                      .property_image[index][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 25 }}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <span
                                                        className="font14 latoBold blueText myTripBtn myTripBtn--primary "
                                                        id="Cancel_Button"
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                      >
                                                        Cancel Booking
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {
                                                      item.property_rooms[0]
                                                        .property_rooms
                                                    }
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.upcomingData.hotel_link.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}

                              {this.state.upcomingData.hs_portal && (
                                <>
                                  {" "}
                                  {this.state.upcomingData.hs_portal.propertyData.map(
                                    (item, index) => {
                                      //   console.log('HS');
                                      let date1 = new Date(
                                        this.state.upcomingData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.upcomingData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.upcomingData
                                        .hs_portal.bookingArr[index]
                                        .booking_status;
                                      let hsbooking_id = this.state.upcomingData
                                        .hs_portal.bookingArr[index].id;

                                      // console.log(item);
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 1) {
                                        StatusMsg = "Booking confirmed";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.upcomingData
                                                      .hs_portal.property_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 25 }}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <span
                                                        className="font14 latoBold blueText myTripBtn myTripBtn--primary "
                                                        id="Cancel_Button"
                                                        onClick={() =>
                                                          this.CancelBookingConfirmButtonHs(
                                                            hsbooking_id
                                                          )
                                                        }
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                      >
                                                        Cancel Booking
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {
                                                      item.property_rooms[0]
                                                        .property_rooms
                                                    }
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.upcomingData.hs_portal.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .upcomingData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}



                              {this.state.upcomingTourData.expreriance && (
                                <>
                                  {" "}
                                  {this.state.upcomingTourData.expreriance.tourData.map(
                                    (item, index) => {
                                      //   console.log('HS');
                                      let date1 = new Date(
                                        this.state.upcomingTourData.expreriance.tourbookingArr[
                                          index
                                        ].tour_booking_date
                                      );
                                      let date2 = new Date(
                                        this.state.upcomingTourData.expreriance.tourbookingArr[
                                          index
                                        ].tour_booking_date
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.upcomingTourData
                                        .expreriance.tourbookingArr[index]
                                        .booking_status;
                                      let hstourbooking_id = this.state.upcomingTourData
                                        .expreriance.tourbookingArr[index].id;

                                      // console.log(item);
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 1) {
                                        StatusMsg = "Booking confirmed";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.upcomingTourData
                                                      .expreriance.tour_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 25 }}
                                                      >
                                                        {
                                                          item.tour
                                                            .tour_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.tour
                                                            .physical_rating
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div>
                                                      <span
                                                        className="font14 latoBold blueText myTripBtn myTripBtn--primary "
                                                        id="Cancel_Button"
                                                        onClick={() =>
                                                          this.CancelBookingConfirmButtonHsTour(
                                                            hstourbooking_id
                                                          )
                                                        }
                                                        style={{
                                                          borderRadius: 5,
                                                        }}
                                                      >
                                                        Cancel Booking
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.tour
                                                        .tour_location
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                
                                                    {/* {
                                                      item.property_rooms[0]
                                                        .property_rooms
                                                    } */}
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.upcomingTourData.expreriance.tourbookingArr[
                                                      index
                                                    ].tour_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .upcomingTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .upcomingTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .upcomingTourData
                                                              .expreriance
                                                              .tourbookingArr[index]
                                                              .tour_booking_date
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}



                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}

                  {this.state.cancelled == true ? (
                    <div className="myTripLstngLobCrd forCancelled">
                      {this.state.cancelledData == "noCancelled" ? (
                        <div className="emptyErr">
                          <div className="emptyErr--imgCont">
                            <img src={CancelledImage} alt="Upcoming Image" />
                          </div>
                          <div>
                            <p className="emptyErr--heading font22 latoBlack appendBottom15">
                              Looks empty, you've no cancelled bookings.
                            </p>
                            <p className="emptyErr--subHeading fomt14 darkGreyText appendBottom25">
                              When you book a trip, you will see your itinerary
                              here.
                            </p>
                            <p>
                              <a href="/">
                                <button className="font14 latoBold blueText myTripBtn myTripBtn--primary ">
                                  Plan a trip
                                </button>
                              </a>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {this.state.cancelledData == null ? (
                            
                            <>
                              <div
                                className="Container-Tile"
                                style={{ height: 250 }}
                              >
                                <div className="ContainerFlex">
                                  <div
                                    className="ContainerImage"
                                    style={{ padding: "15px 10px 10px 10px" }}
                                  >
                                    <Skeleton
                                      style={{ width: "100%", height: "165px" }}
                                    />
                                  </div>
                                  <div
                                    className="ContainerContent"
                                    style={{ padding: "10px 10px 10px 40px" }}
                                  >
                                    <div
                                      className="nameContainer"
                                      style={{ width: "100%" }}
                                    >
                                      <div className="paymakeFlex appendBottom5">
                                        <h3 className="latoBlack font22 blackText">
                                          <Skeleton
                                            style={{ width: "200px" }}
                                          />
                                        </h3>
                                        <span
                                          className="sRating"
                                          style={{
                                            display: "inline-flex",
                                            marginTop: "5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Skeleton
                                            style={{ width: "100px" }}
                                          />
                                        </span>
                                      </div>
                                      <p>
                                        <Skeleton style={{ width: "300px" }} />
                                      </p>
                                      <h3
                                        className="latoBlack font22 blackText"
                                        style={{
                                          marginTop: 10,
                                          fontSize: 18,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <Skeleton style={{ width: "150px" }} />
                                      </h3>

                                      <div class="chkContBooking">
                                        <div class="chkCont__col">
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText 444">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="chkCont__night">
                                            <span>
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.cancelledData.ejuniper && (
                                <>
                                  {this.state.cancelledData.ejuniper.propertyData.map(
                                    (item, index) => {
                                      const DataArr =
                                        item.ReadBookingResponse.BookingRS
                                          .Reservations.Reservation.Items
                                          .HotelItem;
                                      const HotelCategoryNew =
                                        DataArr.HotelInfo.HotelCategory;

                                      const HotelCategory = HotelCategoryNew.charAt(
                                        0
                                      );
                                      let date1 = new Date(
                                        DataArr.attributes.Start
                                      );
                                      let date2 = new Date(
                                        DataArr.attributes.End
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status =
                                        item.ReadBookingResponse.BookingRS
                                          .Reservations.Reservation.attributes
                                          .Status;
                                      let StatusMsg = "";
                                      if (Status == "PAG") {
                                        StatusMsg =
                                          "Booking confirmed and paid";
                                      } else if (Status == "CON") {
                                        StatusMsg = "Booking confirmed";
                                      } else if (Status == "CAN") {
                                        StatusMsg = "Booking cancelled";
                                      } else if (Status == "CAC") {
                                        StatusMsg = "Booking cancelled";
                                      } else if (Status == "PRE") {
                                        StatusMsg = "Booking on request";
                                      } else if (Status == "PDI") {
                                        StatusMsg = "Booking on request";
                                      } else if (Status == "QUO") {
                                        StatusMsg = "Quotation";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.cancelledData
                                                      .ejuniper.property_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 25 }}
                                                      >
                                                        {DataArr.HotelInfo.Name}
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          HotelCategory
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                  <p style={{ marginTop: 15 }}>
                                                    {DataArr.HotelInfo.Address}
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {
                                                      DataArr.HotelRooms
                                                        .HotelRoom.Name
                                                    }
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {
                                                      DataArr.Prices.Price
                                                        .TotalFixAmounts.Service
                                                        .attributes.Amount
                                                    }
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            DataArr.attributes
                                                              .Start
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            DataArr.attributes
                                                              .Start
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            DataArr.attributes
                                                              .End
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            DataArr.attributes
                                                              .End
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}

                              {this.state.cancelledData.hotel_link && (
                                <>
                                  {this.state.cancelledData.hotel_link.propertyData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.cancelledData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.cancelledData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.cancelledData
                                        .hotel_link.bookingArr[index]
                                        .booking_status;
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 3) {
                                        StatusMsg = "Booking Cancelled";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.cancelledData
                                                      .hotel_link
                                                      .property_image[index][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 25 }}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {
                                                      item.property_rooms[index]
                                                        .property_rooms
                                                    }
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.cancelledData.hotel_link.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}{" "}
                                </>
                              )}

                              {this.state.cancelledData.hs_portal && (
                                <>
                                  {this.state.cancelledData.hs_portal.propertyData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.cancelledData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.cancelledData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.cancelledData
                                        .hs_portal.bookingArr[index]
                                        .booking_status;
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 3) {
                                        StatusMsg = "Booking Cancelled";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.cancelledData
                                                      .hs_portal.property_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 25 }}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {
                                                      item.property_rooms[index]
                                                        .property_rooms
                                                    }
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.cancelledData.hs_portal.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .cancelledData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <> </>
                  )}

                  {this.state.Completed == true ? (
                    <div className="myTripLstngLobCrd forCompleted">
                      {this.state.completedData == "noCompleted" ? (
                        <div className="emptyErr">
                          <div className="emptyErr--imgCont">
                            <img src={CancelledImage} alt="Upcoming Image" />
                          </div>
                          <div>
                            <p className="emptyErr--heading font22 latoBlack appendBottom15">
                              Looks empty, you've no cancelled bookings.
                            </p>
                            <p className="emptyErr--subHeading fomt14 darkGreyText appendBottom25">
                              When you book a trip, you will see your itinerary
                              here.
                            </p>
                            <p>
                              <a href="/">
                                <button className="font14 latoBold blueText myTripBtn myTripBtn--primary ">
                                  Plan a trip
                                </button>
                              </a>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <>
                          {this.state.completedData == null ? (
                            <>
                              <div
                                className="Container-Tile"
                                style={{ height: 250 }}
                              >
                                <div className="ContainerFlex">
                                  <div
                                    className="ContainerImage"
                                    style={{ padding: "15px 10px 10px 10px" }}
                                  >
                                    <Skeleton
                                      style={{ width: "100%", height: "165px" }}
                                    />
                                  </div>
                                  <div
                                    className="ContainerContent"
                                    style={{ padding: "10px 10px 10px 40px" }}
                                  >
                                    <div
                                      className="nameContainer"
                                      style={{ width: "100%" }}
                                    >
                                      <div className="paymakeFlex appendBottom5">
                                        <h3 className="latoBlack font22 blackText">
                                          <Skeleton
                                            style={{ width: "200px" }}
                                          />
                                        </h3>
                                        <span
                                          className="sRating"
                                          style={{
                                            display: "inline-flex",
                                            marginTop: "5px",
                                            marginLeft: "10px",
                                          }}
                                        >
                                          <Skeleton
                                            style={{ width: "100px" }}
                                          />
                                        </span>
                                      </div>
                                      <p>
                                        <Skeleton style={{ width: "300px" }} />
                                      </p>
                                      <h3
                                        className="latoBlack font22 blackText"
                                        style={{
                                          marginTop: 10,
                                          fontSize: 18,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <Skeleton style={{ width: "150px" }} />
                                      </h3>

                                      <div class="chkContBooking">
                                        <div class="chkCont__col">
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText 444">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="chkCont__night">
                                            <span>
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                          <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="latoBlack font24 blackText appendBottom3">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                            <span class="font10 grayText">
                                              <Skeleton
                                                style={{ width: 150 }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {this.state.completedData.ejuniper && (
                                <>
                                  {this.state.completedData.ejuniper.propertyData.map(
                                    (item, index) => {
                                      const DataArr =
                                        item.ReadBookingResponse.BookingRS
                                          .Reservations.Reservation.Items
                                          .HotelItem;
                                      const HotelCategoryNew =
                                        DataArr.HotelInfo.HotelCategory;

                                      const HotelCategory = HotelCategoryNew.charAt(
                                        0
                                      );
                                      let date1 = new Date(
                                        DataArr.attributes.Start
                                      );
                                      let date2 = new Date(
                                        DataArr.attributes.End
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status =
                                        item.ReadBookingResponse.BookingRS
                                          .Reservations.Reservation.attributes
                                          .Status;
                                      let StatusMsg = "";
                                      if (Status == "PAG") {
                                        StatusMsg =
                                          "Booking confirmed and paid";
                                      } else if (Status == "CON") {
                                        StatusMsg = "Booking confirmed";
                                      } else if (Status == "CAN") {
                                        StatusMsg = "Booking cancelled";
                                      } else if (Status == "CAC") {
                                        StatusMsg = "Booking cancelled";
                                      } else if (Status == "PRE") {
                                        StatusMsg = "Booking on request";
                                      } else if (Status == "PDI") {
                                        StatusMsg = "Booking on request";
                                      } else if (Status == "QUO") {
                                        StatusMsg = "Quotation";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.completedData
                                                      .ejuniper.property_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 25 }}
                                                      >
                                                        {DataArr.HotelInfo.Name}
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          HotelCategory
                                                        )}
                                                      </span>
                                                    </div>
                                                    <div></div>
                                                  </div>
                                                  <p style={{ marginTop: 15 }}>
                                                    {DataArr.HotelInfo.Address}
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {
                                                      DataArr.HotelRooms
                                                        .HotelRoom.Name
                                                    }
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {
                                                      DataArr.Prices.Price
                                                        .TotalFixAmounts.Service
                                                        .attributes.Amount
                                                    }
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            DataArr.attributes
                                                              .Start
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            DataArr.attributes
                                                              .Start
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            DataArr.attributes
                                                              .End
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            DataArr.attributes
                                                              .End
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}

                              {this.state.completedData.hotel_link && (
                                <>
                                  {this.state.completedData.hotel_link.propertyData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.completedData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.completedData.hotel_link.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.completedData
                                        .hotel_link.bookingArr[index]
                                        .booking_status;
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 1) {
                                        StatusMsg = "Booking confirmed";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.completedData
                                                      .hotel_link
                                                      .property_image[index][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 25 }}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {
                                                      item.property_rooms[index]
                                                        .property_rooms
                                                    }
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.completedData.hotel_link.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hotel_link
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}{" "}
                                </>
                              )}

                              {this.state.completedData.hs_portal && (
                                <>
                                  {this.state.completedData.hs_portal.propertyData.map(
                                    (item, index) => {
                                      let date1 = new Date(
                                        this.state.completedData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_in
                                      );
                                      let date2 = new Date(
                                        this.state.completedData.hs_portal.bookingArr[
                                          index
                                        ].booking_checked_out
                                      );

                                      let timeDiff = Math.abs(
                                        date1.getTime() - date2.getTime()
                                      );
                                      let numberOfNights = Math.ceil(
                                        timeDiff / (1000 * 3600 * 24)
                                      );
                                      const Status = this.state.completedData
                                        .hs_portal.bookingArr[index]
                                        .booking_status;
                                      {
                                        /* const Locator = item.ReadBookingResponse.BookingRS.Reservations.Reservation.attributes.Locator; */
                                      }

                                      let StatusMsg = "";
                                      if (Status == 1) {
                                        StatusMsg = "Booking confirmed";
                                      }
                                      return (
                                        <>
                                          <div className="Container-Tile">
                                            <div className="ContainerFlex">
                                              <div
                                                className="ContainerImage"
                                                style={{
                                                  padding: "15px 1px 10px 1px",
                                                }}
                                              >
                                                <img
                                                  src={
                                                    this.state.completedData
                                                      .hs_portal.property_image[
                                                      index
                                                    ][0]
                                                  }
                                                />
                                              </div>
                                              <div
                                                className="ContainerContent"
                                                style={{
                                                  padding:
                                                    "10px 10px 10px 10px",
                                                }}
                                              >
                                                <div
                                                  className="nameContainer"
                                                  style={{ width: "100%" }}
                                                >
                                                  <div className="paymakeFlexNew">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <h3
                                                        className="latoBlack font22 blackText"
                                                        style={{ fontSize: 25 }}
                                                      >
                                                        {
                                                          item.property
                                                            .property_name
                                                        }
                                                      </h3>
                                                      <span
                                                        className="sRating"
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          marginTop: "5px",
                                                          marginLeft: "10px",
                                                          marginRight: 10,
                                                        }}
                                                      >
                                                        {this.starsCount(
                                                          item.property
                                                            .star_category_id
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <p>
                                                    {
                                                      item.property
                                                        .address_line_1
                                                    }
                                                  </p>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      marginTop: 5,
                                                      fontSize: 18,
                                                      marginBottom: 2,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    {
                                                      item.property_rooms[index]
                                                        .property_rooms
                                                    }
                                                  </h3>

                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Amount :{" "}
                                                    <FontAwesomeIcon
                                                      icon={faEuroSign}
                                                      style={{ fontSize: 16 }}
                                                      color="#000"
                                                    ></FontAwesomeIcon>{" "}
                                                    {this.state.completedData.hs_portal.bookingArr[
                                                      index
                                                    ].room_charges.slice(5, 13)}
                                                  </h3>
                                                  <h3
                                                    className="latoBlack font22 blackText"
                                                    style={{
                                                      fontSize: 18,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Status: {StatusMsg}
                                                  </h3>

                                                  <div
                                                    class="chkContBooking"
                                                    style={{ marginTop: 12 }}
                                                  >
                                                    <div class="chkCont__col">
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK IN
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText 444">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_in
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                      <div class="chkCont__night">
                                                        <span>
                                                          {numberOfNights}{" "}
                                                          Nights
                                                        </span>
                                                      </div>
                                                      <div class="paymakeFlex column">
                                                        <span class="font10 grayText appendBottom3">
                                                          CHECK OUT
                                                        </span>
                                                        <span class="latoBlack font24 blackText appendBottom3">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("D MMMM Y")}
                                                        </span>
                                                        <span class="font10 grayText">
                                                          {moment(
                                                            this.state
                                                              .completedData
                                                              .hs_portal
                                                              .bookingArr[index]
                                                              .booking_checked_out
                                                          ).format("dddd")}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <> </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpreriancePlanTrips;
