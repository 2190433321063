import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faChild, faGlassMartiniAlt, faWifi, faEuroSign } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter, Link, Switch, Route } from "react-router-dom";
import { Base64 } from 'js-base64';
import NewHeader from "./Component/NewHeader/NewHeader";
import moment from 'moment';
import {Helmet} from "react-helmet";

import './BookingPage.css'
import BookingPageSkeleton from './BookingPageSkeleton';

function BookingPage(props) {

    const [HotelImage, setHotelImage] = useState();
    const [HotelData, setHotelData] = useState({});
    const [StartDate, setCheckIn] = useState();
    const [Enddate, setCheckOut] = useState();
    const [TransactionID, setTransactionID] = useState();
    const [Message, setMessage] = useState();
    const [Status, setStatus] = useState();
    const [NoNight, setNoNight] = useState(null);
    const [GuestCount, setSearchData] = useState([]);


    const starsCount = () => {
        console.log(HotelData.star_category_id);
        if (HotelData.star_category_id == 1) {
            return (
                <>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                </>
            )
        } else if (HotelData.star_category_id == 2) {
            return (
                <>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                </>
            )
        } else if (HotelData.star_category_id == 3) {
            return (
                <>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                </>
            )
        } else if (HotelData.star_category_id == 4) {
            return (
                <>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                </>
            )
        } else if (HotelData.star_category_id == 5) {
            return (
                <>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                    <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                </>
            )
        }

    }

    useEffect(() => {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const checkin = urlParams.get('checkin');
        const query = urlParams.get('query');
        const hotelImage = urlParams.get('hotelImage');
        const checkout = urlParams.get('checkout');
        const data = urlParams.get('data');
        const transaction_id = urlParams.get('transaction_id');
        const message = urlParams.get('message');
        const status = urlParams.get('status');

        let querySearch = JSON.parse(query);
        let date1 = new Date(checkin);
        let date2 = new Date(checkout);

        let timeDiff = Math.abs(date1.getTime() - date2.getTime());
        let numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));

        setNoNight(numberOfNights);
        let dataH = JSON.parse(data);
  
        setHotelImage(hotelImage);
        setHotelData(dataH);
        setCheckIn(checkin);
        setCheckOut(checkout);
        setSearchData(querySearch);
        setTransactionID(transaction_id);
        setMessage(message);
        setStatus(status);

    }, [])

    console.log(HotelData);
    return (
        <>
         <Helmet>
            <title> Booking Confirmation @ Holidays Seychelles</title>
            <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
        </Helmet>
        <h1 style={{}}> Booking Confirmation @ Holidays Seychelles</h1>
            <NewHeader page={'inner_listing'} />
            {NoNight == null ? (
                <BookingPageSkeleton />
            ) : (
                <div className="container makeFlex spaceBetween">

                    <div className="container makeFlex spaceBetween" style={{ padding: '50px' }}>
                        <div className="Container-TileBooking" style={{ marginTop: 90, height: 360, padding: 40 }}>

                            <div className="Detail-Hotel">
                                <div className="Hotel-Name" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="nameContainer" style={{ width: 600 }}>

                                        <div className="paymakeFlex appendBottom5"><h3 className="latoBlack font22 blackText">{HotelData.property_name}</h3><span className="sRating" style={{ display: 'inline-flex', marginTop: '5px', marginLeft: '10px' }}>{starsCount(HotelData.star_category_id)}</span></div>
                                        <p>{HotelData.address_line_1}</p>
                                        <h3 className="latoBlack font22 blackText" style={{ marginTop: 10, fontSize: 18, fontWeight: 'bold' }}>Status: {Status}</h3>

                                        <p>Message: {Message}</p>
                                        <p>Transaction Id: {TransactionID}</p>

                                        <div class="chkContBooking">
                                            <div class="chkCont__col">
                                                <div class="paymakeFlex column">
                                                    <span class="font10 grayText appendBottom3">CHECK IN</span><span class="latoBlack font24 blackText appendBottom3">{moment(StartDate).format("D MMMM Y")}</span><span class="font10 grayText 444">{moment(StartDate).format('dddd')}, {HotelData.check_in} PM</span>
                                                </div>
                                                <div class="chkCont__night"><span>{NoNight} Nights</span></div>
                                                <div class="paymakeFlex column"><span class="font10 grayText appendBottom3">CHECK OUT</span><span class="latoBlack font24 blackText appendBottom3">{moment(Enddate).format("D MMMM Y")}</span><span class="font10 grayText">{moment(Enddate).format('dddd')}, {HotelData.check_out} PM</span></div>
                                            </div>
                                            <div class="chkCont__col"><p class="font16 blackText"><span class="latoBlack">{GuestCount.reduce((a, v) => a + (v.adultCounter * 1), 0)}</span>&nbsp;Adults,&nbsp;<span class="latoBlack">{GuestCount.reduce((a, v) => a + v.childCounter * 1, 0)}</span>&nbsp;Children&nbsp;| <span>{GuestCount.reduce((a, v) => a + v.roomCounter, 0)} Room</span></p></div>

                                        </div>

                                        <p class="errorLinkText latoBold font16 blueText"><Link to="/">Back To Home Page</Link></p>
                                    </div>
                                    <div className="imageContainerBooking">
                                        <img src={HotelImage} style={{ width: '100%' }} />
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            )}



        </>
    )
}

export default BookingPage;
