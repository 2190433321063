import React, { useState } from 'react';
import "./FixedPriceTile.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShare, faPlus } from '@fortawesome/free-solid-svg-icons';
import DropDownButtons from "../DropDownButtons/dropDownButton";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

function FixedPriceTile(props) {

    const [date, changeDate] = useState(null);
    const [dateOut,changeDateOut]=useState(null);
    const [classToggler,changeClass]=useState("price-C")
        window.onscroll=()=>{
            console.log(document.body.scrollTop);
                    if (document.body.scrollTop > 3200 || document.documentElement.scrollTop > 4200) {
                        console.log("price");
                        changeClass("price-container");
                    } 
                    else if (document.body.scrollTop > 480 || document.documentElement.scrollTop > 480){
                        console.log("fixed");
                        changeClass("fixed-price-container");
                    }else{

                        changeClass("price-C");
                    }
                }
    return (
        <div className={classToggler}>
            <div className="SubContainer">
                <div className="fixed-price-header">
                    <div><h1 style={{ display: "inline-block" }}>€659</h1><br /><span>per night</span></div>
                    <div style={{ marginLeft: "40%" }}>
                        <i>Good</i><p>350 reviews</p>
                    </div>
                    <div className="small-rating-tile">
                        <h4 style={{ color: "white" }}>4.0</h4>
                    </div>
                </div>
                <hr />
                <div className="price-inputs">
                    
                </div>
            </div>
        </div>
    )
}

export default FixedPriceTile;
