import React, { useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import NewHeader from "../Component/NewHeader/NewHeader.js";
import Footer from "../Component/Footer";
import {Helmet} from "react-helmet";

const Faq = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="StaticMainComponent">
      <div className="MainStaticMainComponentContaint">
      <Helmet>
      <title>FAQ's : Holidays Seychelles</title>
      <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
      <meta name="description" content="Solve all your queries on the best place for honeymoon Seychelles and beautiful places in the world." />
    </Helmet>
    <h1 style={{}}> FAQ's : Holidays Seychelles</h1>
        <NewHeader page={"inner_listing"} />
        <div
          className="StaticlandingCard appendBottom20 StaticSection page-section"
          style={{ marginTop: 150 }}
        >
          <div className="StaticContainer" id="container">
            <div class="row">
              <div className="aboutPage">
                <h1 className="aboutHeading">Booking FAQs</h1>
                <p className="text">
                  We hope the below cover the main things you need. If not, send
                  us an email. Contact details can be found on the Contact Us
                  page of this website.
                </p>

                <h3>Who can I contact in case of a problem?</h3>
                <p className="text">
                  The best person to speak to or email is us. Our name, address
                  and telephone number, plus email, can be found on the Contact
                  Us page of this web site.
                </p>
                <h3>How does the payment process work? </h3>
                <p className="text">
                  The website is connected to cyber source payment gateway. This
                  in itself provides all the necessary security and compliance
                  to international standards to ensure the safety of your
                  details and money. If you are familiar with online payment our
                  payment process will follow the same process and
                  authentication.
                </p>
                <h3>
                  Sometimes when I book I get instant confirmation, whereas
                  other times I get a message that your office will confirm
                  within 24 hours. Why is this?{" "}
                </h3>
                <p className="text">
                  When the accommodation provider or tour provider has given us
                  inventory (i.e. when they have assigned us empty rooms or tour
                  places for us to sell in advance) we store this in our
                  database and when a request comes in from the traveller we can
                  give instant confirmation. If we don't have this inventory in
                  our database, we need to call the hotel or tour company to
                  tell them we have your inquiry and to check to see if they
                  have a room or tour place free. This is where it takes a
                  little time - up to 24 hours - to respond.
                </p>
                <h3>
                  Am I actually booking with Holidays Seychelles or with the
                  local partner?{" "}
                </h3>
                <p className="text">
                  You are booking with Holidays Seychelles. However, the booking
                  details are transferred to the hotel or event supplier
                  instantly for prompt action and ensure a proper quality
                  service delivery.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showNews={"noNews"} />
    </div>
  );
};

export default Faq;
