import React, { Component } from 'react';
import Header from "../Header";
import "./AdventureDetails.css";
import HotelImage from "../../assets/images/background-images/agoda_homes_marketing_image.jpg";
import Review1 from "../../assets/images/reviews/avatar1.jpg";
import Review2 from "../../assets/images/reviews/avatar2.jpg";
import Review3 from "../../assets/images/reviews/avatar3.jpg";
import Footer from "../../Component/Footer";
import FixedPriceTile from "./FixedPriceTile/FixedPriceTile";
import NewHeader from "../../Component/NewHeader/NewHeader";
import Beach1 from "../../assets/images/trips/mahebeach01.jpg";
import Beach2 from "../../assets/images/trips/mahebeach02.jpg";
import Beach3 from "../../assets/images/trips/mahebeach03.jpg";
import Beach4 from "../../assets/images/trips/mahebeach04.jpg";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import {Link} from "react-scroll";
import GoogleMap from "./GoogleMap/GoogleMap";

class HotelDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            guests: 0,
            children: 0,
            adults: 0,
            visibleToggler: "buttons-invisible"
        }
    }

    guestToggleHandler = () => {
        if (this.state.visibleToggler === "buttons-invisible") {
            this.setState({
                visibleToggler: "buttons-stop"
            })
        }
        else {
            this.setState({
                visibleToggler: "buttons-invisible"
            })
        }
    }


    adultIncrementHandler = () => {
        this.setState({
            adults: this.state.adults + 1,
            guests: this.state.guests + 1
        })
    }

    adultDecrementHandler = () => {
        if (this.state.adults > 0 && this.state.guests > 0) {
            this.setState({
                adults: this.state.adults - 1,
                guests: this.state.guests - 1
            })
        }
    }

    childIncrementHandler = () => {
        this.setState({
            children: this.state.children + 1,
            guests: this.state.guests + 1
        })
    }

    childDecrementHandler = () => {
        if (this.state.children > 0 && this.state.guests > 0) {
            this.setState({
                children: this.state.children - 1,
                guests: this.state.guests - 1
            })
        }
    }


    render() {
        return (
            <div className="Detail-container">
                <NewHeader />
                <div className="Horizontal-nav">
                    <ul className="nav-container">
                        <li><Link to="Overview" smooth={true} duration={1000}>Overview</Link></li>
                        <li><Link to="Itinerary" smooth={true} duration={1000}>Itinerary</Link></li>
                        <li><Link to="Inclusion" smooth={true} duration={1000}>Inclusions and Exclusions</Link></li>
                        <li><Link to="Info" smooth={true} duration={1000}>Additional Info</Link></li>
                        <li><Link to="Policies" smooth={true} duration={1000}>Policies</Link></li>
                        <li><Link to="Reviews" smooth={true} duration={1000}>Review</Link></li>
                        <li><Link to="Maps" smooth={true} duration={1000}>Maps</Link></li>
                    </ul>
                </div>
                <FixedPriceTile guestHandler={this.guestToggleHandler}
                    guestClass={this.state.visibleToggler}
                    numberGuest={this.state.guests}
                    adultIncrement={this.adultIncrementHandler}
                    adultDecrement={this.adultDecrementHandler}
                    childIncrement={this.childIncrementHandler}
                    childDecrement={this.childDecrementHandler}
                    valueGuest={this.state.guests}
                    valueAdult={this.state.adults}
                    valueChildren={this.state.children}
                />
                {/* <div className="fixed-price-container">
                <div className="SubContainer">
                    <div className="fixed-price-header">
                        <div><h1 style={{ display: "inline-block" }}>€659</h1><br /><span>per night</span></div>
                        <div style={{ marginLeft: "40%" }}>
                            <i>Good</i><p>350 reviews</p>
                        </div>
                        <div className="small-rating-tile">
                            <h4 style={{ color: "white" }}>4.0</h4>
                        </div>
                    </div>
                    <hr />
                    <div className="price-inputs">
                        <form>
                            <input type="text" id="calendar" value="Check-In Check-Out" />
                            <input type="text" id="Guests" value={this.state.guests} />
                            <button className="check">Check Availabilty</button>
                            <button className="payment">Proceed to Payment</button>
                        </form>
                    </div>
                </div>
                <div className="btns">
                    <button className="shareBtn"><FontAwesomeIcon icon={faShare}></FontAwesomeIcon>Share</button>
                    <button className="SaveBtn"><FontAwesomeIcon icon={faHeart}></FontAwesomeIcon>Save</button>
                    <button className="AddBtn"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>Add to Trip</button>
                </div>
            </div> */}
                <div id="Overview" className="hotel-details">
                    <div className="Image-details" >
                        <AliceCarousel autoPlay autoPlayInterval="3000" animationType="fadeout" infinite="true" disableButtonsControls="true">
                            <img src={Beach1} className="sliderimg" />
                            <img src={Beach2} className="sliderimg" />
                            <img src={Beach3} className="sliderimg" />
                            <img src={Beach4} className="sliderimg" />
                        </AliceCarousel>
                    </div>
                    <div id="Itinerary" style={{height:"100px"}}></div>
                    <div  className="Text-Details">
                        <h4>Itinerary</h4>
                        <p>One of the most sedimentary beaches of the island, this small stretch of beach provide an excellent view onto the Ile Soleil, the place of our sunrise. Yellow in colour the beach is highly frequented by fisherman. Get to meet the locals and explore their trade. Take a stroll down the beach and feel the difference in the type of sand as it massages the sole of your feet, purely a heavenly gift. Reef Hotel Beach: Reef Hotel Beach is located nearer the Reef Hotel Gold Estate. As you stroll down this stretch of beach be dazzled by the silhouette of the islands of La Digue and gazing southwards is the headland of Pointe Au Sel and half moon shaped beach of Au Cap. These magnificent views are for your enjoyment.
<br />
Duration: 50 minutes</p>
                        <h4>Stop At: Pointe Au Sel, Seychelles</h4>
                        <p>Driving Southwards from Asian Fusion, jammed pack in the center of Point Au Sel a stone throw from Crown Beach and Villa El Dorado and next to M.S supermarket lies the Pointe Au Sel Beach. A quiet but nice place nestled beneath the green foliage of Takamaka trees. Duration: 30 minutes Stop At: Anse Royale beach, Mahe Island 12345 Seychelles Fairy Land will wow you with its stunning beauty and appeal to all your memories of the Seychelles.. Fairy land is really an enchanting place, ideal location for swimming and experiencing the richness of the deep blue of the Seychelles islands. The place provides a view to the Bay of Anse Royale against a backdrop of lush green mountain peak punctuated by granite outcrops. Stroll the beach taking photos of this picturesque location. This land of the Fairies is ideal for snorkeling and swimming thus providing you with awesome encounter with the abundance of our tropical marine paradise.
<br />
Duration: 40 minutes</p>
                        <h4>Stop At: AnseForbans Beach House, S Coast Rd, Seychelles</h4>
                        <p id="Inclusion">Takamaka is a a quiet beach in Mahe but splendid beach for bathing and snorkeling. Take time to get up close and personal with the giant tortoises located here. Feel the waves or just laze around and soak the tropical atmosphere with the surf gushing on the rocks surrounding this majestic bay. Better still take a stroll along its white sandy beaches which intermingles with stunning granite boulders providing you with the perfect hide away. Visit the surroundings of the Chez Baptista Kreol Restaurant as you make your way to Ros Sodier should weather and time permit.
<br />
Duration: 30 minutes</p>
                    </div>
                    <div  className="Text-Details">
                        <h4>Inclusion & Exclusions</h4>
                        <ol>
                            <li>Bottle of Water</li>
                            <li>Pick up and drop off from hotel</li>
                            <li>Entrance Fees (where applicable)</li>
                            <li>Tour Guide</li>
                            <li id="Info">Lunch and Alcohol is Excluded From This Package</li>
                            <li> Wi-Fi</li>
                        </ol>
                    </div>
                    <div  className="Text-Details">
                        <h4>Additional Info</h4>
                        <ol>
                            <li>Confirmation will be received at time of booking</li>
                            <li>Not wheelchair accessible</li>
                            <li>Stroller accessible</li>
                            <li>Near public transportation</li>
                            <li>Lunch and alcohol is excluded from this package</li>
                            <li id="Policies">This is a private tour/activity. Only your group will participate</li>
                        </ol>
                    </div>
                    <div  className="Text-Details">
                        <h4>Policies</h4>
                        <h6>For a full refund, cancel at least 24 hours in advance of the start date of the experience.</h6>
                        <p id="Reviews" style={{ fontStyle: 'italic' }}>
                            This experience requires good weather. If it’s canceled due to poor weather, you’ll be offered a different date or a full refund.

                            This experience requires a minimum number of travelers. If it’s canceled because the minimum isn’t met, you’ll be offered a different date/experience or a full refund. Learn more about cancellations.
                    </p>
                    </div>
                    <div  className="Reviews">
                        <h4>Reviews</h4>
                        <div className="all-reviews">
                            <div className="review-overview">
                                <div className="review-overview-card">
                                    <h1>8.5</h1>
                                    <h4>Superb</h4>
                                    <h6>Based on 4 Reviews</h6>
                                </div>
                                <div className="progressBar">
                                    <progress value="88" max="100">  </progress>
                                    <progress value="70" max="100">  </progress>
                                    <progress value="20" max="100">  </progress>
                                    <progress value="12" max="100">  </progress>
                                    <progress value="30" max="100">  </progress>
                                </div>
                            </div>
                            <div className="people-reviews">
                                <img src={Review1} />
                                <div className="the-review">
                                    <p style={{ fontStyle: 'italic' }}>Admin – April 03, 2016:</p>
                                    <p>Sed eget turpis a pede tempor malesuada. Vivamus quis mi at leo pulvinar hendrerit. Cum sociis natoque penatibus et magnis dis</p>
                                </div>
                            </div>
                            <div className="people-reviews">
                                <img src={Review2} />
                                <div className="the-review">
                                    <p style={{ fontStyle: 'italic' }}>Admin – April 03, 2016:</p>
                                    <p>Sed eget turpis a pede tempor malesuada. Vivamus quis mi at leo pulvinar hendrerit. Cum sociis natoque penatibus et magnis dis</p>
                                </div>
                            </div>
                            <div className="people-reviews">
                                <img src={Review3} />
                                <div className="the-review">
                                    <p style={{ fontStyle: 'italic' }}>Admin – April 03, 2016:</p>
                                    <p>Sed eget turpis a pede tempor malesuada. Vivamus quis mi at leo pulvinar hendrerit. Cum sociis natoque penatibus et magnis dis</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form className="form">
                        <label for="fname">First name:</label><br />
                        <input type="text" id="fname" name="fname" /><br />
                        <label for="lname">Last name:</label><br />
                        <input type="text" id="lname" name="lname" /><br />
                        <label for="Email">Email:</label><br />
                        <input type="email" id="Email" name="Email" /><br />
                        <label for="yourRating">Your Rating</label><br />
                        <select style={{ width: "80%", height: "40%" }} id="yourRating" name="ratings">
                            <option style={{ width: "80%" }} value="1">1</option>
                            <option style={{ width: "80%" }} value="2">2</option>
                            <option style={{ width: "80%" }} value="3">3</option>
                            <option style={{ width: "80%" }} value="4">4</option>
                            <option style={{ width: "80%" }} value="5">5</option>
                        </select><br />
                        <label for="reviewText">Your Review</label><br />
                        <textarea type="text" id="reviewText" name="reviewText" ></textarea><br />
                        <button className="submitBtn">Submit</button>
                    </form>
                    <div id="Maps" className="google-map">
                       <GoogleMap />
                    </div>

                </div>
                <Footer />
            </div>
        )
    }
}

export default HotelDetails;
