import React,{useState} from 'react';
import Header from "../NewHeader/NewHeader";
import Footer from "../Footer";
import "./customize.css";
import { Link } from "react-router-dom";
import { DatePicker, Space, Menu, Dropdown, Button } from "antd";
import 'antd/dist/antd.css';
import { Popup } from "reactjs-popup";
import Mahe from "../../assets/images/island/Mahe.jpg";
// import 'reactjs-popup/dist/index.css';

function Customize() {


    const increaseAdults=(e)=>{
        if(visitors.adults>=0){
            setVisitors(
                {
                    ...visitors,
                    adults:visitors.adults+1,
                    total:visitors.total+1
                }
            )
        }
        
    }

    const decreaseAdults=(e)=>{
        if(visitors.adults>0){
            setVisitors(
                {
                    ...visitors,
                    adults:visitors.adults-1,
                    total:visitors.total-1
                }
            )
        }
    }

    const increaseToddler=(e)=>{
        if(visitors.toddlers>=0){
            setVisitors(
                {
                    ...visitors,
                    toddlers:visitors.toddlers+1,
                    total:visitors.total+1
                }
            )
        }
    }

    const decreaseToddlers=(e)=>{
        if(visitors.toddlers>0){
            setVisitors(
                {
                    ...visitors,
                    toddlers:visitors.toddlers-1,
                    total:visitors.total-1
                }
            )
        }
    }

    const increaseInfants=(e)=>{
        if(visitors.infants>=0){
            setVisitors(
                {
                    ...visitors,
                    infants:visitors.infants+1,
                    total:visitors.total+1
                }
            )
        }
    }

    const decreaseInfants=(e)=>{
        if(visitors.infants>0){
            setVisitors(
                {
                    ...visitors,
                    infants:visitors.infants-1,
                    total:visitors.total-1
                }
            )
        }
    }

    
    const { RangePicker } = DatePicker;

    const [visitors,setVisitors]=useState({
        adults:0,
        toddlers:0,
        infants:0,
        total:0
    });
    const menu = (
        <Menu style={{ width: "400px" }}>
            <Menu.Item style={{ width: "100%" }}>
                <button style={{ borderRadius: "", padding: "5px", fontSize: "xx-large", border: "none" }}>-</button> Adults <button>+</button>
            </Menu.Item>
            <Menu.Item>
                <h4>Children</h4><button>-</button>  <button>+</button>
            </Menu.Item>
            <Menu.Item>
                <button>-</button> Infants <button>+</button>
            </Menu.Item>
        </Menu>
    );
    return (
        <div>
            <Header />

            <div className="content-inputs parallax">

                <h1 style={{ color: "white" }}><span style={{ backgroundColor: "#deb956" }}>Build</span> Your Own Customize Trip Plan</h1>
                <h3 style={{ color: "white" }}>When are you planning your Trip?</h3>
                <Space direction="vertical" size={10} style={{ padding: "0" }}>
                    <RangePicker />
                </Space>

                <Popup trigger={
                    <input type="text" placeholder="Guests" value={visitors.total}/>
                } position="bottom center">
                    <div className="guests-input p-5">
                        <div className="d-flex justify-content-between align-items-center">
                            <p style={{ fontSize: "large" }}>Adults</p>
                            <button onClick={decreaseAdults} className="btn btn-danger ">-</button>
                            <p style={{ fontSize: "large" }}>{visitors.adults}</p>
                            <button onClick={increaseAdults} className="btn btn-primary">+</button>

                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p style={{ fontSize: "large" }}>toddler</p>
                            <button onClick={decreaseToddlers} className="btn btn-danger">-</button>
                            <p style={{ fontSize: "large" }}>{visitors.toddlers}</p>
                            <button onClick={increaseToddler} className="btn btn-primary">+</button>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p style={{ fontSize: "large" }}>Infants</p>
                            <button  onClick={decreaseInfants}  className="btn btn-danger">-</button>
                            <p style={{ fontSize: "large" }}>{visitors.infants}</p>
                            <button  onClick={increaseInfants}  className="btn btn-primary">+</button>
                        </div>
                    </div>
                </Popup>



                <h5 style={{ color: "white" }}>Where You want to go?</h5>
                <Popup trigger={
                    <h6 style={{ color: "white", cursor: "pointer" }}>Chick here to choose</h6>
                } position="bottom center">
                    <div className="selection">
                        <h5>Popular Islands are as follows (Click to Add)</h5>
                        <div>
                            <figure class="figure">
                                <img src={Mahe} class="figure-img img-fluid  tile-img mr-5" alt="..." />
                                <figcaption class="figure-caption" style={{ fontSize: "large", color: "black", fontWeight: "bold" }}>Mahe </figcaption>
                            </figure>
                            <figure class="figure img-tile">
                                <img src={Mahe} class="figure-img img-fluid  tile-img mr-5" alt="..." />
                                <figcaption class="figure-caption" style={{ fontSize: "large", color: "black", fontWeight: "bold" }}>Mahe </figcaption>
                            </figure>
                            <figure class="figure img-tile">
                                <img src={Mahe} class="figure-img img-fluid  tile-img" alt="..." />
                                <figcaption class="figure-caption" style={{ fontSize: "large", color: "black", fontWeight: "bold" }}>Mahe </figcaption>
                            </figure>
                        </div>
                        <button disabled={false} onClick={(e) => { e.preventDefault(); console.log("clicked") }} className="btn btn-secondary ">Browse Other famous Islands</button>
                    </div>
                </Popup>

                <Link to="/citiesDuration">
                    <button className="continue-plan">Continue Planning</button>
                </Link>
                

            </div>
            {/* <pre>{JSON.stringify(visitors,null,4)}</pre> */}
            <Footer />
        </div>
    )
}

export default Customize;
