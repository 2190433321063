import React,{useState} from 'react';
import "./MobileBanner.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft,faArrowCircleLeft,faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import {Link,withRouter} from "react-router-dom";
import Calendar from 'react-calendar';
import Footer from '../../Component/Footer';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import { Calendar } from 'react-date-range';



function MobileBanner(props) {
    const [valueIn, onChange] = useState(new Date());
    const [valueOut, onChangeOut] = useState(new Date());

    const [calendar,calendarToggle]=useState({
        toggleState:false,
        toggleStateOut:false
    });

    const [roomsGuests,setroomsGuests]=useState({
        rooms:0,
        guests:0,
        guestToggler:"rooms-members-Selector"
    })

    const roomIncrementController=(e)=>{
        e.preventDefault();
        setroomsGuests({
            ...roomsGuests,
            rooms:roomsGuests.rooms+1
        })
    }

    const roomDecrementController=(e)=>{
        e.preventDefault();
        if(roomsGuests.rooms>0){
            setroomsGuests({
                ...roomsGuests,
                rooms:roomsGuests.rooms-1
            })   
        }
     
    }
    const guestIncrementController=(e)=>{
        e.preventDefault();
        setroomsGuests({
            ...roomsGuests,
            guests:roomsGuests.guests+1
        })  
    }
    const guestDecrementController=(e)=>{
        e.preventDefault();
        if(roomsGuests.guests>0){
            setroomsGuests({
                ...roomsGuests,
                guests:roomsGuests.guests-1
            })  
        }

    }




  const  roomGuestToggler=()=>{
        if(roomsGuests.guestToggler==="rooms-members-Selector"){
            setroomsGuests({
                ...roomsGuests,
                guestToggler:"rooms-members-Selector-visible"
            })
        }
        else{
            setroomsGuests({
                ...roomsGuests,
                guestToggler:"rooms-members-Selector"
            })
        }
    }

    const changeHanler=(e)=>{
        onChange(e);
        calendarToggleHandler();
    }

    const changeHanlerOut=(e)=>{
        onChangeOut(e);
        calendarToggleHandlerOut();
    }
    const calendarToggleHandlerOut=()=>{
        if(calendar.toggleStateOut === false){
            calendarToggle({
                toggleStateOut:true
            })
        }
        else{
            calendarToggle({
                toggleStateOut:false
            })
        }
    }

    const calendarToggleHandler=()=>{
        console.log("You have clicked me");
        if(calendar.toggleState === false){
            calendarToggle({
                toggleState:true
            })
        }
        else{
            calendarToggle({
                toggleState:false
            })
        }
    }


    const submitClickHandler=(e)=>{
        console.log(e);
        console.log("Button Clicked");
        props.history.push("/hotellisting");
    }
    return (
        <div className="mobileContainer">
           <div className="head">
               <Link to="/" style={{color:"black"}}>
                    <FontAwesomeIcon style={{ height:"20px", width:"60px"}} icon={faArrowLeft} ></FontAwesomeIcon></Link>
                    <div>
                    <h6 style={{color:"black"}}> Hotels and Accomadation Search</h6>
                    <p>India or International</p>
                    </div>
           </div>
           <div className="inputs">
               <form target="/hotellisting">
                <input className="cityName" type="text" placeholder="CityName" required />
                <input className="checkIn" onClick={calendarToggleHandler} required value={new Date(valueIn).toDateString()} type="text" placeholder="CheckIn" />
                <input className="checkOut" onClick={calendarToggleHandlerOut} required value={new Date(valueOut).toDateString()} type="text" placeholder="CheckOut" />
                <input className="Members" type="text" onClick={roomGuestToggler} required value={`Rooms = ${roomsGuests.rooms} Guests = ${roomsGuests.guests}`} placeholder="Rooms and Guests" />
                <input className="Members" type="submit" onClick={submitClickHandler} required  placeholder="ClickMe" />
                
                {
                    calendar.toggleState? 
                    <Calendar
                    onChange={changeHanler}
                    value={valueIn}
                    />:
                    null
                }
                 {
                    calendar.toggleStateOut? 
                    <Calendar
                    onChange={changeHanlerOut}
                    value={valueOut}
                    />:
                    null
                }
             
                {/* <input onClick={submitClickHandler} 
                className="Confirm" type="submit" /> */}
                 </form>
           </div>
           <div className={roomsGuests.guestToggler}>
               <button className="remove" onClick={roomGuestToggler}>x</button>
                   <div><button onClick={roomDecrementController} className="decrement">-</button><span className="text">Rooms {roomsGuests.rooms}</span><button onClick={roomIncrementController} className="increment">+</button></div> 
                   <br/>
                   <div><button onClick={guestDecrementController} className="decrement">-</button><span className="text">Guests {roomsGuests.guests}</span><button onClick={guestIncrementController} className="increment">+</button></div> 
           </div>
           <div className="footer">
                <Footer ></Footer>
           </div>
        </div>
    )
}

export default MobileBanner;
