import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
const borderColor = '#000'
const styles = StyleSheet.create({
    invoiceNoContainer: {
        //flexDirection: 'row',
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    // invoiceDateContainer: {
    //     flexDirection: 'row',
    // },
    // invoiceDate: {
    //         fontSize: 12,
    //         fontStyle: 'bold',
    // },
    // label: {
    //     width: 60
    // }
//  invoiceDateContainer: {
//     width: '20%',
//     borderRightColor: borderColor,
//     textAlign: 'left',
//     paddingRight: 8,
//     flexDirection: 'row',
//  },
//  invoiceData: {
//     width: '20%',
//     borderRightColor: borderColor,
//     borderRightWidth: 1,
//     textAlign: 'right',
//     paddingRight: 8,
//     flexDirection: 'row',
//  },
invoiceDate: {
   width: '50%',
   borderRightColor: borderColor,
   textAlign: 'left',
   paddingRight: 8,
   flexDirection: 'row',
   borderRightColor: borderColor,
  borderRightWidth: 1,
},
//  invoiceDateData: {
//     width: '25%',
//     borderRightColor: borderColor,
//     borderRightWidth: 1,
//     textAlign: 'left',
//     paddingRight: 8,
//     flexDirection: 'row',
//  },
invoiceAmount: {
   width: '50%',
   borderRightColor: borderColor,
   textAlign: 'left',
   flexDirection: 'row',
   paddingLeft: 10
},
//  invoiceAmountData: {
//     width: '25%',
//     borderRightColor: borderColor,
//     textAlign: 'left',
//     flexDirection: 'row',
//     paddingLeft: 10
//  },

    
  });



  const InvoiceNo = ({invoice}) =>{
   var invoice1 = (JSON.stringify(invoice));
   var invoice2 = JSON.parse(invoice1);
   var invoice3 = JSON.parse(invoice2);
   return(
      <Fragment>
      <View style={styles.invoiceNoContainer}>
          {/* <Text style={styles.invoiceDateContainer}>Refrence No:</Text>
          <Text style={styles.invoiceData}>202221-11</Text> */}
          <Text style={styles.invoiceDate}>Date: {invoice3.b_date}</Text>
          {/* <Text style={styles.invoiceDateData}>{invoice3.b_date}</Text> */}
          <Text style={styles.invoiceAmount}>Amount: {invoice3.booking_amount}</Text>
          {/* <Text style={styles.invoiceAmountData}>{invoice3.booking_amount}</Text> */}
      </View >
     
   </Fragment>
     );

  } 
  
  export default InvoiceNo