const baseUrl = window.location.href.includes("https://holidays-seychelles.com/")
//const baseUrl = window.location.href.includes("http://localhost:3000/")

let ALL_API_CONST = "";  
if(baseUrl){
    ALL_API_CONST ={
      cybersource:"https://api.holidays-seychelles.com/api/cybersource",
      tripurl:"https://trips.holidays-seychelles.com",
      supplier:"https://supplier.holidays-seychelles.com",
        hotel_data: {
            zonelistAccoData: "https://lvv7d266m3.execute-api.us-east-2.amazonaws.com/HS-Live",
            HotelListData: "https://q9p8jigvm4.execute-api.us-east-2.amazonaws.com/GetHotelsFromSources",
            getHSHotellist: "https://gddoxz0znh.execute-api.us-east-2.amazonaws.com/getHSHotellist",
            HotelListDataById: "https://zca5jlx0q9.execute-api.us-east-2.amazonaws.com/GetHotelList",
            HotelBookingRules: "https://7laottfw7g.execute-api.us-east-2.amazonaws.com/go_to_cart_and_check_availability",
            HotelConfirmBooking: "https://clh7hhdk57.execute-api.us-east-2.amazonaws.com/confirm_booking",
            getHotellistFilters:  "https://626bboabwa.execute-api.us-east-2.amazonaws.com/getFilter",
            getAccomadationBookingIdEJuniferData: "https://fnwg7pn6gg.execute-api.us-east-2.amazonaws.com/getAccomadationBookingIdEJuniferData"
          },
          tour_data:{
            ActivityZoneData : "https://ze6llcztr0.execute-api.us-east-2.amazonaws.com/activityzonedata",
            PackageZoneData : "https://gjbm8l8r2g.execute-api.us-east-2.amazonaws.com/packagezonedata",
            TransferZoneData : "https://fn1ycvauh4.execute-api.us-east-2.amazonaws.com/TransferZoneData",
            TransferSourceZoneData : "https://hrei71bndi.execute-api.us-east-2.amazonaws.com/TransferSourceZoneData",
            TransferDestinationZoneData : "https://t6zmtwkgg4.execute-api.us-east-2.amazonaws.com/TransferDestinationZoneData",
            TransferAirportType : "https://73rvi0nlj0.execute-api.us-east-2.amazonaws.com/TransferAirportType",
            TransferFerryType : "https://85h6r6iped.execute-api.us-east-2.amazonaws.com/TransferFerryType",
            TransferCityType : "https://4p6mnnxt4i.execute-api.us-east-2.amazonaws.com/TransferCityType",
            TransferToAirportCityType : "https://yzvicston6.execute-api.us-east-2.amazonaws.com/TransferToAirportCityType",
            TransferCityToAirportType : "https://6vt330tewf.execute-api.us-east-2.amazonaws.com/TransferCityToAirportType",
            TransferCityToCityType : "https://8y2r39iy21.execute-api.us-east-2.amazonaws.com/TransferCityToCityType",
            LiveAboardZoneData : "https://j08htx01c6.execute-api.us-east-2.amazonaws.com/liveAboardZoneData",
            getTourActivityList : "https://g50ok6pxh6.execute-api.us-east-2.amazonaws.com/getTourActivityList",
            getTourPackagelist : "https://7vy03drlvh.execute-api.us-east-2.amazonaws.com/gettourpackagelist",
            getTourTransferlist : "https://tpm32wk8ef.execute-api.us-east-2.amazonaws.com/gettourtransferlist",
            getTourLiveAboardList : "https://v4e40vmulb.execute-api.us-east-2.amazonaws.com/getTourLiveAboardList",
            TourRules : "https://ls9pgh51h0.execute-api.us-east-2.amazonaws.com/cartCheckTourAvailability",
            ActivityDetailsData : "https://6r2bf7rsb7.execute-api.us-east-2.amazonaws.com/activitydetailsdata",
            CheckAvailabilityData : "https://akf3b34u29.execute-api.us-east-2.amazonaws.com/checkavailabilitydata",
            PackageDetailsData :  "https://4p0vnga6ri.execute-api.us-east-2.amazonaws.com/getPackageDetailsData",
            TransferDetailsData : "https://gogskernzj.execute-api.us-east-2.amazonaws.com/getTransferDetailsData",
            LiveAboardDetailsData : "https://yk4x1nife7.execute-api.us-east-2.amazonaws.com/getLiveAboardDetailsData",
            getTourFilter : "https://h98d1uprn7.execute-api.us-east-2.amazonaws.com/getTourFilter",
            getTourFilterTransfer : "https://211tg1b00c.execute-api.us-east-2.amazonaws.com/getTourFilterTransfer",
            getTourFilterActivity : "https://24rx6pq4i9.execute-api.us-east-2.amazonaws.com/getTourFilterActivity",
            getTourFilterPackage : "https://6bp3i4y8eb.execute-api.us-east-2.amazonaws.com/getTourFilterPackage",
            getEventandActivity :  "https://ydrd2uuev1.execute-api.us-east-2.amazonaws.com/getEventandActivitity",
            checkAvailabilityCheckoutData : "https://0s04wq7j63.execute-api.us-east-2.amazonaws.com/checkAvailabilityCheckoutData",
            getalltransport : "https://umxasrs0uk.execute-api.us-east-2.amazonaws.com/getalltransport",
          },
          login_data:{
            setCustomerLogin : "https://q5dt1f9jv2.execute-api.us-east-2.amazonaws.com/LoginApi",
            setCustomerAgentLogin : "https://poctkxwqy5.execute-api.us-east-2.amazonaws.com/SetAgentCustomerLogin",
            setAgentSignup : "https://92vdshlxnk.execute-api.us-east-2.amazonaws.com/setAgentSignup",
            setAgentUpdatePassword : "https://skpwe70ts1.execute-api.us-east-2.amazonaws.com/setAgentUpdatePassword",
            setLoginConfirmationAgent : "https://irb6oaxk7c.execute-api.us-east-2.amazonaws.com/setLoginConfirmationAgent",
            setCustomerAgentLoginProfile : "https://9jid0e5dn2.execute-api.us-east-2.amazonaws.com/setCustomerAgentLoginProfile",
            registrationagentemailcheck : "https://sfw05tcfwl.execute-api.us-east-2.amazonaws.com/registrationagentemailcheck",
            getCustomerLogin : "https://y6e1h8wued.execute-api.us-east-2.amazonaws.com/customerLogin",
            LoginConfirmation : "https://7iduvxaq52.execute-api.us-east-2.amazonaws.com/customerLogin",
            LoginAgentConfirmation : "https://elkjkmptej.execute-api.us-east-2.amazonaws.com/ConfirmationAgentLogin",
            ConfirmationForgot : "https://k2l4to05hk.execute-api.us-east-2.amazonaws.com/confirmationForgot",
            UpdatePasswordReset : "https://jcz6j0hhx1.execute-api.us-east-2.amazonaws.com/UpdatePasswordReset",
            ConfirmationAgentForgot : "https://ou9a16btm1.execute-api.us-east-2.amazonaws.com/confirmationAgentForgot",
            setCustomerAgentLoginProfileNew : "https://5g8stikw46.execute-api.us-east-2.amazonaws.com/Profile_Image_Upload_V2",
          },
          booking_data:{
            getUpcomingBooking : "https://sg2yek2kh8.execute-api.us-east-2.amazonaws.com/getUpcomingBooking",
            getCancelledBooking : "https://ju5klatvrj.execute-api.us-east-2.amazonaws.com/getCancelledBooking",
            getCompletedBooking : "https://7nhh1ammhg.execute-api.us-east-2.amazonaws.com/getCompletedBooking",
            CancelBookingConfirm : "https://f64up0sfn2.execute-api.us-east-2.amazonaws.com/CancelBookingConfirm",
            CancelBookingConfirmHs : "https://1af5ijy4ya.execute-api.us-east-2.amazonaws.com/CancelBookingConfirmHs",
            CancelBookingConfirmHl :  "https://5sag763kr8.execute-api.us-east-2.amazonaws.com/gethotellinkCancellationBooking",
            TourConfirmBooking : "https://y2732s1faa.execute-api.us-east-2.amazonaws.com/tourConfirmBooking",
            getAgentCommissionDetails : "https://jomlhyd5t7.execute-api.us-east-2.amazonaws.com/getAgentCommissionDetails",
            getAgentAdditionChargesData : "https://5g9n2rdw1m.execute-api.us-east-2.amazonaws.com/getAgentAdditionChargesData",
            CartConfirmBooking :  "https://r5932d7pd6.execute-api.us-east-2.amazonaws.com/cartConfirmBooking",
            getExprerianceUpcomingBooking : "https://65yrkyvfmj.execute-api.us-east-2.amazonaws.com/getExprerianceUpcomingBooking",
            getExprerianceCancelledBooking : "https://i62xc0dgwd.execute-api.us-east-2.amazonaws.com/getExprerianceCancelledBooking",
            getExprerianceCompletedBooking : "https://dnd0q6ybx8.execute-api.us-east-2.amazonaws.com/getExprerianceCompletedBooking",
            CancelBookingConfirmHsTour : "https://99pc8w31oi.execute-api.us-east-2.amazonaws.com/CancelBookingConfirmHsTour",
            getExprerianceAgentBooking : "https://advh8kkpc3.execute-api.us-east-2.amazonaws.com/getExprerianceAgentBooking",
            getAccomodationShowDataBooking : "https://8wbtdpdvm1.execute-api.us-east-2.amazonaws.com/getAccomodationShowDataBooking",
            getExprerianceShowDataBooking : "https://3j1vxrg8x8.execute-api.us-east-2.amazonaws.com/getExprerianceShowDataBooking",
            WishlistDelete :  "https://77ikz1x9rl.execute-api.us-east-2.amazonaws.com/wishlistfitDelete",
            getwishlistCartData :  "https://mp98zmnb5k.execute-api.us-east-2.amazonaws.com/getwishlistfitCartData",
           // getwishlistfitCartData : "https://mp98zmnb5k.execute-api.us-east-2.amazonaws.com/getwishlistfitCartData",
           // wishlistfitDelete : "https://77ikz1x9rl.execute-api.us-east-2.amazonaws.com/wishlistfitDelete",
          },
          offer_data:{
            getSpecialOffersForHome : "https://aq41ftpglj.execute-api.us-east-2.amazonaws.com/specialoffershome",
            getSpecialOffersForHomeListing : "https://6fz5duy86j.execute-api.us-east-2.amazonaws.com/Offer_Home_Listing",
            getSpecialOfferEjuniferListing : "https://7fff7w37o4.execute-api.us-east-2.amazonaws.com/getSpecialOfferEjuniferListing",
            getSpecialOffersForFilterHome : "https://41e78ns1ma.execute-api.us-east-2.amazonaws.com/getSpecialOffersFilters",
            getSpecialOffersForHomeExperiencesFilters : "https://47kkffnl06.execute-api.us-east-2.amazonaws.com/getSpecialOffersForHomeExperiencesFilters",
            getSpecialOffersForHomeExperiencesAttractionFilters : "https://0rwz8gtxyc.execute-api.us-east-2.amazonaws.com/getSpecialOffersForHomeExperiencesAttractionFilters",
            getSpecialOffersForHomeExperiencesPackageFilters : "https://xey4rsu0s1.execute-api.us-east-2.amazonaws.com/getSpecialOffersForHomeExperiencesPackageFilters",
            getSpecialOffersForHomeExperiencesTransferFilters : "https://9ml04tlwy7.execute-api.us-east-2.amazonaws.com/getSpecialOffersForHomeExperiencesTransferFilters",
            getSpecialOffersForHomeExperiencesLiveAboardFilters : "https://wzxvl5x2yc.execute-api.us-east-2.amazonaws.com/getSpecialOffersForHomeExperiencesLiveAboardFilters",
            getSpecialOffersForHomeExperiences : "https://ynnbhmwfn6.execute-api.us-east-2.amazonaws.com/getspecialoffersforhomeexperiences",
            getSpecialOfferEjunifer : "https://jjw07m3fsf.execute-api.us-east-2.amazonaws.com/getSpecialOfferEjunifer",
            getSpecialOffersForHomeExperiencesAttraction : "https://vb4tc4qkc6.execute-api.us-east-2.amazonaws.com/getSpecialOffersForHomeExperiencesAttraction",
            getSpecialOffersForHomeExperiencesPackage :  "https://vo41vjcgy8.execute-api.us-east-2.amazonaws.com/getSpecialOffersForHomeExperiencesPackage",
            getSpecialOffersForHomeExperiencesTransfer :  "https://2c0jt2wt06.execute-api.us-east-2.amazonaws.com/getSpecialOffersForHomeExperiencesTransfer",
            getSpecialOffersForHomeExperiencesLiveAboard :  "https://busuc4vh18.execute-api.us-east-2.amazonaws.com/getSpecialOffersForHomeExperiencesLiveAboard",

          }
    }

}else{

    ALL_API_CONST ={
      cybersource:"https://testapi.holidays-seychelles.com/api/cybersourcetest",
      tripurl:"https://testtrips.holidays-seychelles.com",
      supplier:"https://testsupplier.holidays-seychelles.com",
        hotel_data: {
            zonelistAccoData: "https://4i1m5gtlu5.execute-api.us-east-2.amazonaws.com/getzonelist",
            HotelListData: "https://l9bd6p7gei.execute-api.us-east-2.amazonaws.com/GETONLYHOTELLIST",
            getHSHotellist: "https://opnazc340d.execute-api.us-east-2.amazonaws.com/getHSHotellist",
            HotelListDataById: "https://cj1eign6vg.execute-api.us-east-2.amazonaws.com/HSGETHotelListByHotelID",
            HotelBookingRules: "https://t3d85pci51.execute-api.us-east-2.amazonaws.com/CheckRoomAvailabilityCreateCart",
            HotelConfirmBooking: "https://cvifw0jj2f.execute-api.us-east-2.amazonaws.com/ConfirmBooking",
            getHotellistFilters: "https://vo0yk5w4mc.execute-api.us-east-2.amazonaws.com/GetFilters",
            getAccomadationBookingIdEJuniferData: "https://xuzohjh1el.execute-api.us-east-2.amazonaws.com/getAccomadationBookingIdEJuniferData",
          },
          tour_data:{
            ActivityZoneData : "https://tm4xq0q9j9.execute-api.us-east-2.amazonaws.com/ActivityZoneData",
            PackageZoneData : "https://bxp1tves49.execute-api.us-east-2.amazonaws.com/PackageZoneData",
            TransferZoneData : "https://6769i98jyj.execute-api.us-east-2.amazonaws.com/TransferZoneData",
            TransferSourceZoneData :"https://cf8hp9v7kl.execute-api.us-east-2.amazonaws.com/TransferFerryType",
            TransferDestinationZoneData :"https://i8gyiwkw40.execute-api.us-east-2.amazonaws.com/TransferDestinationZoneData",
            TransferAirportType : "https://d4lx8t2vqe.execute-api.us-east-2.amazonaws.com/TransferAirportType",
            TransferFerryType : "https://vb4xukex7a.execute-api.us-east-2.amazonaws.com/TransferFerryType",
            TransferCityType : "https://krwl2ummxk.execute-api.us-east-2.amazonaws.com/TransferCityType",
            TransferToAirportCityType : "https://iptpnbese3.execute-api.us-east-2.amazonaws.com/TransferToAirportCityType",
            TransferCityToAirportType : "https://3ft1w1cj5m.execute-api.us-east-2.amazonaws.com/TransferCityToAirportType",
            TransferCityToCityType : "https://1092qoufic.execute-api.us-east-2.amazonaws.com/TransferCityToCityType",
            LiveAboardZoneData : "https://yu3sbzpik9.execute-api.us-east-2.amazonaws.com/LiveAboardZoneData",
            getTourActivityList : "https://6rwipx79d8.execute-api.us-east-2.amazonaws.com/getTourActivityList",
            getTourPackagelist : "https://ny1l5lziza.execute-api.us-east-2.amazonaws.com/getTourPackagelist",
            getTourTransferlist : "https://b6p41z7ev0.execute-api.us-east-2.amazonaws.com/getTourTransferlist",
            getTourLiveAboardList : "https://h2zafpv747.execute-api.us-east-2.amazonaws.com/GetTourLiveAboardList",
            TourRules : "https://f2q46vtz3a.execute-api.us-east-2.amazonaws.com/CartCheckTourAvailability",
            ActivityDetailsData : "https://2lnelbye93.execute-api.us-east-2.amazonaws.com/ActivityDetailsData",
            CheckAvailabilityData : "https://7ajfkn4crl.execute-api.us-east-2.amazonaws.com/CheckAvailabilityData",
            PackageDetailsData :  "https://2a9pxgk1ub.execute-api.us-east-2.amazonaws.com/GetPackageDetailsData",
            TransferDetailsData : "https://mkq8y9ews6.execute-api.us-east-2.amazonaws.com/GetTransferDetailsData",
            LiveAboardDetailsData : "https://v6nflvnyae.execute-api.us-east-2.amazonaws.com/getLiveAboardDetailsData",
            getTourFilter : "https://fsf2j9t0v7.execute-api.us-east-2.amazonaws.com/getTourFilter",
            getTourFilterTransfer : "https://57cz7whlo7.execute-api.us-east-2.amazonaws.com/getTourFilterTransfer",
            getTourFilterActivity : "https://uszaye47o3.execute-api.us-east-2.amazonaws.com/getTourFilterActivity",
            getTourFilterPackage : "https://wvcojwm0rb.execute-api.us-east-2.amazonaws.com/getTourFilterPackage",
            getEventandActivity : "https://ihmli2q319.execute-api.us-east-2.amazonaws.com/getEventandActivitity",
            checkAvailabilityCheckoutData : "https://94st9x6qb9.execute-api.us-east-2.amazonaws.com/checkAvailabilityCheckoutData",
            getalltransport : "https://s15in68gp3.execute-api.us-east-2.amazonaws.com/getalltransport",
          },
          login_data:{
            setCustomerLogin : "https://7wk3nmmys7.execute-api.us-east-2.amazonaws.com/loginApi",
            setCustomerAgentLogin : "https://5l5o0eq1la.execute-api.us-east-2.amazonaws.com/SetAgentCustomerLogin",
            setAgentSignup : "https://oenp3rqhxk.execute-api.us-east-2.amazonaws.com/setAgentSignup",
            setAgentUpdatePassword : "https://akn57f5xol.execute-api.us-east-2.amazonaws.com/setAgentUpdatePassword",
            setLoginConfirmationAgent : "https://1remrwm8xi.execute-api.us-east-2.amazonaws.com/setLoginConfirmationAgent",
            setCustomerAgentLoginProfile : "https://e1s9cfd5rb.execute-api.us-east-2.amazonaws.com/setCustomerAgentLoginProfile",
            registrationagentemailcheck : "https://m9fatg6cqi.execute-api.us-east-2.amazonaws.com/registrationagentemailcheck",
            getCustomerLogin : "https://imotdtza4g.execute-api.us-east-2.amazonaws.com/getcustomerlogin",
            LoginConfirmation : "https://k2cmk6bwrh.execute-api.us-east-2.amazonaws.com/CustomerLogin",
            LoginAgentConfirmation : "https://dy1148o1ac.execute-api.us-east-2.amazonaws.com/ConfirmationAgentLogin",
            ConfirmationForgot : "https://7e6a5mv8w9.execute-api.us-east-2.amazonaws.com/confirmationForgot",
            UpdatePasswordReset :  "https://y61gzgdi2b.execute-api.us-east-2.amazonaws.com/UpdatePasswordReset",
            ConfirmationAgentForgot : "https://8pduvaamv3.execute-api.us-east-2.amazonaws.com/confirmationAgentForgot",
            setCustomerAgentLoginProfileNew : " https://dd5tv4tgy9.execute-api.us-east-2.amazonaws.com/profileImageUploadV2",
          },
          booking_data:{
            getUpcomingBooking : "https://je2td5j415.execute-api.us-east-2.amazonaws.com/GetUpcomingBooking",
            getCancelledBooking : "https://0kt1iu5l1m.execute-api.us-east-2.amazonaws.com/getCancelledBooking",
            getCompletedBooking : "https://zze8h65zv9.execute-api.us-east-2.amazonaws.com/getCompletedBooking",
            CancelBookingConfirm : "https://fma7lp5kle.execute-api.us-east-2.amazonaws.com/CancelBookingConfirm",
            CancelBookingConfirmHs : "https://coygekcu18.execute-api.us-east-2.amazonaws.com/CancelBookingConfirmHs",
            CancelBookingConfirmHl : "https://eiaxrflck4.execute-api.us-east-2.amazonaws.com/gethotellinkCancellationBooking",
            TourConfirmBooking : "https://0yly0d65j7.execute-api.us-east-2.amazonaws.com/tourConfirmBooking",
            getAgentCommissionDetails : "https://6c917egfaa.execute-api.us-east-2.amazonaws.com/getAgentCommissionDetails",
            getAgentAdditionChargesData : "https://mrpjmalmlk.execute-api.us-east-2.amazonaws.com/getAgentAdditionChargesData",
            CartConfirmBooking :  "https://01i5iqamib.execute-api.us-east-2.amazonaws.com/cartConfirmBooking",
            getExprerianceUpcomingBooking : "https://m9jld9p7mg.execute-api.us-east-2.amazonaws.com/getExprerianceUpcomingBooking",
            getExprerianceCancelledBooking : "https://by01pw8ut5.execute-api.us-east-2.amazonaws.com/getExprerianceCancelledBooking",
            getExprerianceCompletedBooking : "https://1fdj3y4fuh.execute-api.us-east-2.amazonaws.com/getExprerianceCompletedBooking",
            CancelBookingConfirmHsTour : " https://edogt0h461.execute-api.us-east-2.amazonaws.com/CancelBookingConfirmHsTour",
            getExprerianceAgentBooking : "https://u8rszrmea6.execute-api.us-east-2.amazonaws.com/getExprerianceAgentBooking",
            getAccomodationShowDataBooking : "https://utg2kwe5bj.execute-api.us-east-2.amazonaws.com/getAccomodationShowDataBooking",
            getExprerianceShowDataBooking : "https://ue4ckty2aj.execute-api.us-east-2.amazonaws.com/getExprerianceShowDataBooking",
            WishlistDelete :  "https://fwcqm4oi4f.execute-api.us-east-2.amazonaws.com/wishlistfitDelete",
            getwishlistCartData :  "https://vssy6byuze.execute-api.us-east-2.amazonaws.com/getwishlistfitCartData",
          },
          offer_data:{
            getSpecialOffersForHome : "https://cn9ysnl9f0.execute-api.us-east-2.amazonaws.com/SpecialOffers",
            getSpecialOffersForHomeListing : "https://61eilao3i5.execute-api.us-east-2.amazonaws.com/OfferHomeListing",
            getSpecialOfferEjuniferListing : "https://2oy8madop2.execute-api.us-east-2.amazonaws.com/getSpecialOfferEjuniferListing",
            getSpecialOffersForFilterHome :  "https://kp7ar6iab4.execute-api.us-east-2.amazonaws.com/getSpecialOffersFilters",
            getSpecialOffersForHomeExperiencesFilters : "https://aw09tt1733.execute-api.us-east-2.amazonaws.com/getSpecialOffersForHomeExperiencesFilters",
            getSpecialOffersForHomeExperiencesAttractionFilters : "https://gseoxq1ow9.execute-api.us-east-2.amazonaws.com/ExperienceAttractionFiltering",
            getSpecialOffersForHomeExperiencesPackageFilters : "https://nkj7pw1491.execute-api.us-east-2.amazonaws.com/ExperiencePackageFiltering",
            getSpecialOffersForHomeExperiencesTransferFilters : "https://fzw45z0yck.execute-api.us-east-2.amazonaws.com/ExperiencetransferFiltering",
            getSpecialOffersForHomeExperiencesLiveAboardFilters : "https://x2bfy9vq98.execute-api.us-east-2.amazonaws.com/ExperienceLiveAboardFiltering",
            getSpecialOffersForHomeExperiences : "https://2w3vmjtmv5.execute-api.us-east-2.amazonaws.com/TourSpecialOffers",
            getSpecialOfferEjunifer : "https://ihsz5xgrff.execute-api.us-east-2.amazonaws.com/getSpecialOfferEjunifer",
            getSpecialOffersForHomeExperiencesAttraction : "https://1ygx5vhvla.execute-api.us-east-2.amazonaws.com/ExperienceAttraction",
            getSpecialOffersForHomeExperiencesPackage : "https://8oizfbe6q8.execute-api.us-east-2.amazonaws.com/ExperiencePackage",
            getSpecialOffersForHomeExperiencesTransfer :  "https://vqzxo17ctg.execute-api.us-east-2.amazonaws.com/ExperienceTransfer",
            getSpecialOffersForHomeExperiencesLiveAboard :  "https://z4xa75ges0.execute-api.us-east-2.amazonaws.com/ExperienceLiveAboard",
          }
    }

}

export default ALL_API_CONST;