import React, { useState } from 'react';
import "./TourOffer.css";
import Skeleton from 'react-loading-skeleton';


function TourListSkeleton(props) {

    return (
        <div className="Container-Tile">

     
                <a href="#">
                    <div className="Image-Section">
                    <Skeleton className="imageSkeleton" />
                        {/* <div className="SmallImage">
                        <Skeleton className="imgThumb_New"  />
                        <Skeleton className="imgThumb_New"  />
                        <Skeleton className="imgThumb_New"  />
                        <Skeleton className="imgThumb_New"  />
                        </div> */}
                    </div>
                    <div className="Text-Section">
                        <div className="Text-Contents">
                            {/* <img src={Logo} className="logo" /> */}
                            <div className="Text-Ratings" style={{marginTop:'4px'}}>
                                <h3 className="text-Color" style={{width: '100px'}}><Skeleton /></h3>
                                <h3 className="text-Color" style={{width: '100px', marginLeft:'10px'}}><Skeleton /></h3>
                            </div>
                            <div className="Small-Text">
                                <p className="address" style={{ color: 'gray', width:400 }}><Skeleton /></p>
                                <p><Skeleton /></p>
                                <p className="highlight"><Skeleton /></p>
                                <div className="Extras">
                                    <p style={{ width: '60px' }}><Skeleton /></p>
                                    <p style={{ marginLeft: "10px", width: '60px' }}><Skeleton /></p>
                                    <p style={{ marginLeft: "10px", width: '60px' }}><Skeleton /></p>
                                </div>
                            </div>

                            <p style={{width: '200px'}}><Skeleton /></p>
                        </div>
                        <div className="Price" style={{padding:10}}>
                            <Skeleton  count={10}/>

                        </div>
                    </div>
                </a>
     
        </div>

    )
}

export default TourListSkeleton;
