import React, { useState } from 'react';
import "./styles.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';
// import { DatePicker, Space} from 'antd';
// import 'antd/dist/antd.css';
import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';
import "react-time-picker/dist/TimePicker.css"
import "react-datepicker/dist/react-datepicker.css";
import "react-clock/dist/Clock.css";




function TransportationModal() {
    const [startDate, setStartDate] = useState(new Date());
    const [value, onChange] = useState('10:00');



    return (
        <div className="container">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">x</span>
            </button>
            <h5>Select Transportation Mode</h5>
            <div className="row m-5" id="container-top" style={{ width: "100%" }}>
                <h6 style={{ margin: "auto" }}>Mahe / Seychelles =={'>'}  Praslin / Seychelles</h6>
            </div>
            <div className="row m-5">
                <div className="col-6 list">
                    <div className="container-fluid bg-light ">
                        <div className="row list-container" style={{ backgroundColor: "lightblue" }}>
                            <li className="col-12" style={{ listStyle: "none", fontSize: "xx-large", padding: "10px", cursor: "pointer" }}>Customize</li>
                            <p className="tip">Travelling any other way? Add your own Transportation</p>
                        </div>
                        <hr />
                        <div className="row list-container">
                            <li className="col-12" style={{ listStyle: "none", fontSize: "xx-large", padding: "10px", cursor: "pointer" }}><FontAwesomeIcon icon={faCar} />   Land Transport</li>
                            <p className="duration">Duration: 10 hrs 5 mins</p>

                        </div>
                        <hr />
                        <div className="row list-container">
                            <li className="col-12" style={{ listStyle: "none", fontSize: "xx-large", padding: "10px", cursor: "pointer" }}><FontAwesomeIcon icon={faCar} />   Fairy</li>
                            <p className="duration">Duration: 14 hrs 33 mins</p>
                        </div>
                        <hr />
                    </div>
                </div>
                <div className="col-6 items">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-5">
                                <p>Transpotation Modes(s)</p>
                                <select class="custom-select custom-select-md">
                                    <option selected>All Categories</option>
                                    <option value="2">Train</option>
                                    <option value="3">Car</option>
                                    <option value="4">Ship</option>
                                </select>
                            </div>
                            <div className="col-5">
                                <p>Total Fare(Optional)</p>
                                <select class="custom-select custom-select-md">
                                    <option selected>Mahe</option>
                                    <option value="2">Praslin</option>
                                    <option value="3">La-Digue</option>
                                    <option value="4">Sihoutte</option>
                                </select>
                            </div>
                            <div className="col-8">
                                <p></p>
                                <input type="text" className="form-control" placeholder="Total Fare" />
                            </div>
                            <div className="col-6 mt-3">
                                <p>Depart On</p>
                                <DatePicker className="form-control" dateFormat="dd/MM/yyyy" minDate={new Date()} selected={startDate} onChange={date => setStartDate(date)} />

                            </div>
                            <div className="col-6 mt-3">
                                <p>Time</p>
                                <TimePicker
                                    onChange={onChange}
                                    value={value}
                                />
                            </div>
                        
                            <div className="col-6 mt-3">
                                <p>Arrive On</p>
                                <DatePicker className="form-control" dateFormat="dd/MM/yyyy" minDate={new Date()} selected={startDate} onChange={date => setStartDate(date)} />

                            </div>
                            <div className="col-6 mt-3">
                                <p>Time</p>
                                <TimePicker
                                    onChange={onChange}
                                    value={value}
                                />
                            </div>
                            <p className="col-12 mt-3 text-warning">Please check that the timings have been entered according to local time zone</p>
                            <button  className="btn btn-warning btn-rounded col-3 mt-3 ml-auto mr-auto">Add this mode</button>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransportationModal;
