import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import TawkToWidget from "./TawkToWidget";

const TabsArr = [
  "Accommodations",
  "Attractions & Things to Do",
  "Packages",
  "Land & Ferry Transfers",
  "Liveaboard",
];


export default function Footer(props) {

  const { showNews, tabs, selected, setSelectedTab } = props;
  const [emailnewsletter, setemailnewsletter] = useState('');
  const [errors, setErrors] = useState({
    error_email_newsletter: "",
    success_email_newsletter: ""

  });
 
  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  function handleChange(e) {
    e.preventDefault();

    const { name, value } = e.target;
    let error = errors;
    let regex = /[A-Za-z]/;
    console.log("fgfgf" + name);


    switch (name) {
      case 'email_newsletter':



        if (value.trim() === '') {
          error.error_email_newsletter = 'Please enter a valid email address';
        } else if (validEmailRegex.test(value) == '') {

          error.error_email_newsletter = 'Email address is not valid!';
        } else {
          error.error_email_newsletter = '';
        }



        break;


      default:
        break;
    }
    setErrors({ ...errors, [name]: value })


    setemailnewsletter(value)

  };

  const onScroll = (i) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setSelectedTab(i)
  };

  const onSubmit = async (event) => {
    event.preventDefault(); // Prevent default submission
    const { name, value } = event.target;
    let error = errors;
    let regex = /[A-Za-z]/;

    axios
      .post("https://o8hieakppc.execute-api.us-east-2.amazonaws.com/Newsletter", {
        email: emailnewsletter,
      }, { headers: { 'Content-Type': 'application/json', Authorization: "Basic YWRtaW46MTIzNA==", "x-api-key": "neeraj@123" } })
      .then(function (response) {
        // htandle success

        if (response.data.message === 'Already exist') {
          error.success_email_newsletter = '';
          error.error_email_newsletter = 'Email already exist';
          setErrors({ ...errors, [name]: value })
        } else if (response.data.message === 'successfully inserted') {
          error.error_email_newsletter = '';
          error.success_email_newsletter = 'Thanks! for joining us';
          setErrors({ ...errors, [name]: value })
          setemailnewsletter('');
        } else if (response.data.message === 'Email required') {
          error.success_email_newsletter = '';
          error.error_email_newsletter = 'Please enter your email ID';
          setErrors({ ...errors, [name]: value })
        } else {
          error.success_email_newsletter = '';
          error.error_email_newsletter = 'There is some problem please try again';
          setErrors({ ...errors, [name]: value })
        }


      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });


  }
  return (
    <footer>
      <div  className="container">
        {showNews == "News" ? (
          <div className="row">
           <div className="col-md-12">
           <div className="newsd"style={{ backgroundColor: '#00224f', padding: 40, borderRadius: 10 }}><div className="col-md-6 col-sm-12 col-xs-12 offset-3">
              <div id="newsletter">
                <h6 style={{ color: '#fff', fontWeight: 400 }}> Save time, save money!</h6>
                <p >Subscribe to our newsletter and receive email packed with exclusive offers, promotions and updates.</p>
                <div id="message-newsletter"></div>
                <form onSubmit={onSubmit}
                  method="post"
                  action="#"
                  name="newsletter_form"
                 // onSubmit={onSubmit}
                  autoComplete="new-password"
                >
                  <div className="form-group">
                    <input
                      type="email"
                      name="email_newsletter"
                      id="email_newsletter"
                      className="form-control"
                      placeholder="Your email"
                      style={{ border: "1px solid #eee", backgroundColor: '#fff', borderRadius: 3 }}
                      onChange={handleChange}
                      autoComplete="off"
                      value={emailnewsletter}
                    />
                    <input
                      type="submit"
                      value="Subscribe"
                      id="submit-newsletter"
                    />

                  </div>
                  <p>
                    {errors.error_email_newsletter.length > 0 &&
                      <span className='errornews'>
                        {errors.error_email_newsletter}
                      </span>}
                    {errors.success_email_newsletter.length > 0 &&
                      <span className='succesnews' style={{ color: "Green" }}>
                        {errors.success_email_newsletter}
                      </span>}
                  </p>
                </form>
              </div>
            </div></div>

           </div>
          </div>
        ) : (<></>)}


        <div className="footer-line"></div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <h5>Reach Us</h5>
            <ul className="contacts">
              {/* <p><img src="images/logo/logo.png" alt="" className="logo_normal" /></p> */}
              <li>
                <div className="location">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                  Location
                </div>
                <p>ESPACE Seychelles Ltd <br></br>
                Au CAP, Mahe Seychelles,<br></br> 
                P.O Box 2021, Anse Aux Pins, <br></br>Mahe, Seychelles.</p>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-phone" aria-hidden="true"></i> +248 437
                  6666
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                  info@holidays-seychelles.com
                </a>
              </li>
            </ul>
            <div className="follow_us">
              <ul>
                <li>Follow us</li>
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <h5>Holidays Seychelles</h5>
            <ul className="links">
              <li>
                <a href="/aboutUs" target="_blank">
                  About Us
                </a>
              </li>

              <li>
                <a href="/terms" target="_blank">
                  Terms of Use
                </a>
              </li>

              <li>
                <a href="/faq" target="_blank">FAQ</a>
              </li>
              <li>
                <a href="/privacyPolicy" target="_blank">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <h5>Where To Go</h5>
            <ul className="links">
              {  TabsArr.map((i, j) => {
                let classAdd = ''
                if (selected == i) {
                  classAdd = ''
                }
                return (
                  <li>
                    <a href="javascript:;" onClick={() => onScroll(i)} className={classAdd}>{i}</a>
                  </li>
                )
              })}


            </ul>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
            <h5>Visit Planner</h5>
            <ul className="links">
              <li>
                <a href="/visitplanner" target="_blank">
                  Visit Planner
                </a>
              </li>
              <li>
                <a href="/beforeyourtrip" target="_blank">Before your trip</a>
              </li>
              <li>
                <a href="/duringyourtrip" target="_blank">During Your Trip</a>
              </li>
              <li>
                <a href="/family" target="_blank">Family and Groups</a>
              </li>
              <li>
                <a href="/travelblog" target="_blank">Travel Blog</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <ul id="additional_links">
              <li>
                <span>© 2024 Holidays Seychelles</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <TawkToWidget />
    </footer>
  );
}
