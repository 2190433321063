import React, { useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import NewHeader from "../Component/NewHeader/NewHeader.js";
import Footer from "../Component/Footer";
import {Helmet} from "react-helmet";

const Duringyourtrip = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="StaticMainComponent">
      <div className="MainStaticMainComponentContaint">
      <Helmet>
      <title>During Your Trip : Holidays Seychelles</title>
      <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
    </Helmet>
    <h1 style={{}}> During Your Trip : Holidays Seychelles</h1>
        <NewHeader page={"inner_listing"} />
        <div
          className="StaticlandingCard appendBottom20 StaticSection page-section"
          style={{ marginTop: 150 }}
        >
          <div className="StaticContainer" id="container">
            <div class="row">
              <div className="aboutPage">
                <h1 className="aboutHeading">During Your Trip</h1>
                <p>
                  <i>
                    {" "}
                    We are your local connection in the Republic of Seychelles!
                  </i>
                </p>
                <p className="text">
                  On arrival depending on what type of transfer you booked. Your
                  ground handler (Driver) will be there waiting for you.
                </p>
                <p className="text">
                  Since you may have booked your experience with multiple
                  supplier. You will have to keep note of the various supplier
                  and their pickup procedures to ensure a smooth holiday.
                </p>
                <p className="text">
                  We would strongly suggest that you keep the itinerary that you
                  created. However, Things on the ground may change. The weather
                  could change. The condition for the trip may change and become
                  hazardous. The supplier for this experience in this case will
                  get in touch with you. Secondly, you could change your mind.
                  If you do, please notify the experience supplier as per their
                  cancellation policy.
                </p>
                <p className="text">
                  Not all experiences maybe available to book on the same day
                  especially if it starts early in the morning. Try to book a
                  day before to help the experience provider plan and provide a
                  good service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showNews={"noNews"} />
    </div>
  );
};

export default Duringyourtrip;
