import React, { useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import NewHeader from "../Component/NewHeader/NewHeader.js";
import Footer from "../Component/Footer";
import {Helmet} from "react-helmet";

const AboutUs = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="StaticMainComponent">
      <div className="MainStaticMainComponentContaint">
      <Helmet>
      <title>About Us : Holidays Seychelles</title>
      <meta name="description" content="Seychelles is one of the most beautiful places in the world. The perfect stop for those looking for best honeymoon destination." />
      <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
    </Helmet>
    <h1 style={{}}>About Us : Holidays Seychelles</h1>
        <NewHeader page={"inner_listing"} />
        <div
          className="StaticlandingCard appendBottom20 StaticSection page-section"
          style={{ marginTop: 150 }}
        >
          <div className="StaticContainer" id="container">
            <div class="row">
              <div className="aboutPage">
                <h1 className="aboutHeading">About Us</h1>
                <p>
                  <i>
                    {" "}
                    We are your local connection in the Republic of Seychelles!
                  </i>
                </p>
                <p className="text">
                  Bonzour and meet Pascal Esparon and his team at Holidays
                  (Seychelles), your local connection in resplendent Seychelles!
                  Pascal is the director working on Mahe Island alongside a
                  small team of dedicated people.
                </p>
                <p className="text">
                  The Holidays Seychelles is registered and operate in the
                  Seychelles TIN (Tax Identify Number) 761569712.{" "}
                </p>
                <p className="text">
                  Holidays (Seychelles) is licensed as a TOUR Operator to work
                  with FIT clients (That is the fully independent traveler.) A
                  DMC (Destination Management Company) to work with overseas
                  travel agents and an OTA (On Line Travel Agency){" "}
                </p>
                <p className="text">
                  Our job is to ensure that we promote quality and affordable
                  products. These products are graded by our past visitors on a
                  scale 0-5.
                </p>
                <p className="text">
                  We believe that the Seychelles tourism industry should benefit
                  all citizens. Therefore, we extend all services from other
                  partners, like taxi drivers, ferry operators, tour providers
                  and small hotels owners at very competitive rates. All these
                  services are consolidated in our travel websites. For a good
                  online travel planning experience visit one of our travel
                  websites.
                </p>
                <p className="text">
                  We have been growing year on year and have gained recognition
                  in our field. Our partners have grown with us and so have our
                  number of visitors. As a travel company we specialised in
                  small budget accommodations like hotels, villas, self
                  catering, holiday rentals and guest houses. You will notice
                  that all these small establishments are local owned and
                  therefore you are contributing directly into our economy.
                  However, to complete the portfolio we have to include the
                  bigger hotels.
                </p>
                <p className="text" style={{ fontWeight: "bold" }}>
                  We are here in the Seychelles and because we are local, we
                  have a solid knowledge of the 115 gorgeous granitic and coral
                  islands in their various aspects, as well as the support of an
                  experienced team of professionals working on the ground in
                  Seychelles to provide you with the best eco-friendly travel
                  available in this tropical paradise. By booking with us, you
                  will be receiving expert technical advice as well as
                  personalised service ensuring your Seychelles vacation will be
                  unforgettable!{" "}
                </p>
                <p className="text" style={{ fontWeight: "bold" }}>
                  Our goal is to promote eco-tourism and adventure in a
                  professional and responsible manner. We are focused on
                  educating the inhabitants of the Seychelles as well as
                  tourists on the importance of conservation of the islands,
                  sea, coral reefs, and animal life. We firmly believe in the
                  importance of preserving the Seychelles' natural environment
                  and educating others with the hope that hundreds of years from
                  now the natural habitats and beauty of the islands will be
                  equable. We, along with future generations, must be aware of
                  the benefits, risks and dangers of mass tourism and we
                  understand in order to effect change we must be an active part
                  of that change and are focused on activities that contribute
                  to local sustainable development in the Seychelles.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showNews={"noNews"} />
    </div>
  );
};

export default AboutUs;
