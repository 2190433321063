import React, { useState, useEffect } from "react";
import uuid from "react-uuid";
import Header from "../../Component/NewHeader/NewHeader";
import "./PaymentDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faCaretDown,
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons";
import { TourRules, TourConfirmBooking, LoginConfirmation, CyberSource } from "../axios";
import PaymentDetailsSkeleton from "./PaymentDetailsSkeleton";
import moment from "moment";
import DialCode from "./DialCode";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
//import { Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";
import {Helmet} from "react-helmet";
import { setCustomerLogin, getGeoInfo, getCustomerLogin } from "../axios";
import Modal from "react-modal";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import SignUp from "../SignUp";
//import { faJediOrder } from "@fortawesome/free-brands-svg-icons";
import { Base64 } from "js-base64";
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
function PaymentDetailsTransfer() {
  const [cookies, setCookie] = useCookies(["userDetails"]);

  const [drop, toggledrop] = useState({
    class: "HotelInfo",
    formClass: "form-guest",
    buisnessFormClass: "buisnessShort",
    requestClass: "all-requests",
  });
  const [HotelImage, setHotelImage] = useState();
  const [Payment, setPayment] = useState(null);
  const [TaxPayment, setTaxPayment] = useState(0);
  const [GuestCount, setGuestCount] = useState([]);
  const [StartDate, setStartDate] = useState(null);
  const [CheckedTrip, setCheckedTrip] = useState(0);
  const [Enddate, setEnddate] = useState(null);
  const [TotalTourPrice, setTotalTourPrice] = useState(0);
  const [Guest, setGuest] = useState([
    {
      title: "Mr",
      age: "1",
      FirstName: "",
      LastName: "",
      ErrorFirstName: false,
      ErrorLastName: false,
    },
  ]);
  const [EmailId, setEmailId] = useState();
  const [MobileNumber, setMobileNumber] = useState("");
  const [MobileCode, setMobileCode] = useState("");
  const [redirect, setRedirect] = useState(false);
  //const [LoginPage, setLoginPage] = useState(false);
  const [customerDetails, setcustomerDetails] = useState(
    localStorage.getItem("userDetails") || "abc"
  );
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [facebookLogin, setfacebookLogin] = useState(false);
  const [facebookResponse, setfacebookResponse] = useState({});
  const [GoogleResponse, setGoogleResponse] = useState({});
  const [country, setcountry] = useState("");
  const [country_code, setcountry_code] = useState("");
  const [currency, setcurrency] = useState("");
  const [ip_address, setip_address] = useState("");
  const [ChangeLoginScreenClass, setChangeLoginScreenClass] = useState(
    "containerLogin"
  );
  const [openModal, setopenModal] = useState(false);
  const [openModalHS, setopenModalHS] = useState(false);
  const [LoginEmail, setLoginEmail] = useState("");
  const [LoginPassword, setLoginPassword] = useState("");
  const [errorPassword, seterrorPassword] = useState(false);
  const [EmailError, setEmailError] = useState(false);
  const [MobileError, setMobileError] = useState(false);
  const [TourDetails, setTourDetails] = useState("");
  const [TourOption, setTourOption] = useState("");
  const [TourLocation, setTourLocation] = useState("");
  const [Time, setTime] = useState("");
  const [TourD, setTourD] = useState(null);
  const [TransactionUUID, setTransactionUUID] = useState(uuid());
  const [DisablePayNowButton, setDisablePayNowButton] = useState(false);
  const [transporttype, setTransporttype] = useState("");
  const [SpecialReq, setSpecialReq] = useState("");
  const [ReturnReq, setReturnReq] = useState("");
  
  

  // let openModalDiv = () => {
  //   setopenModal(true);
  // };

  // let closeModalDiv = () => {
  //   setopenModal(false);
  // };

  // let openModalDivHS = () => {
  //   setopenModalHS(true);
  // };

  // let closeModalDivHS = () => {
  //   setopenModalHS(false);
  // };

  // let requestFormHandler = () => {
  //   if (drop.requestClass === "all-requests") {
  //     toggledrop({
  //       ...drop,
  //       requestClass: "all-requests-short",
  //     });
  //   } else {
  //     toggledrop({
  //       ...drop,
  //       requestClass: "all-requests",
  //     });
  //   }
  // };

  // let buisnessFormHandler = () => {
  //   if (drop.buisnessFormClass === "buisness") {
  //     toggledrop({
  //       ...drop,
  //       buisnessFormClass: "buisnessShort",
  //     });
  //   } else {
  //     toggledrop({
  //       ...drop,
  //       buisnessFormClass: "buisness",
  //     });
  //   }
  // };

  let closeModal = () => {
    setmodalIsOpen(false);
  };

  let submit = () => {
    confirmAlert({
      title: "Booking Confirmation", // Title dialog
      message: "Please wait. we're processing your request", // Message dialog
      childrenElement: () => (
        <div style={{ padding: 15 }}>
          <img
            src="https://supplier.holidays-seychelles.com/public/images/prop.png"
            alt=""
            className="loaderImage"
          />
          <div className="progress">
            <div className="indeterminate"></div>
          </div>
        </div>
      ), // Custom UI or Component
      overlayClassName: "overlay-custom-class-name", // Custom overlay class name
    });
  };

  let FormToggleHandler = () => {
    if (drop.formClass === "form-guest") {
      toggledrop({
        ...drop,
        formClass: "form-guest-short",
      });
    } else {
      toggledrop({
        ...drop,
        formClass: "form-guest",
      });
    }
  };

  let ChangeLoginEmail = (event) => {
    setLoginEmail(event.target.value);
  };

  let ChangeLoginPassword = (event) => {
    setLoginPassword(event.target.value);
  };

  let LoginConfirm = (e) => {
    e.preventDefault();
    seterrorPassword(false);
    let emailNew = LoginEmail;
    let email = "";
    if (emailNew.length > 0) {
      email = emailNew;
    } else {
      email = cookies.userDetails.email;
    }
    let password = LoginPassword;

    LoginConfirmation(email, password).then(
      (data) => {
       
        if (data == "not_correct") {
          seterrorPassword(true);
        } else {
          setcustomerDetails(data);

          let Verified = "";
          if (data.verified == 1) {
            Verified = true;
          }

          const dataSearch = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            country_code: data.country_code,
            verified: Verified,
            uid: data.id,
          };

          let JsonData = JSON.stringify(dataSearch);

          localStorage.setItem("userDetails", JsonData);
          window.location.reload(true);
        }
      },
      (error) => {}
    );
  };

  let OnChangeTitle = (event, index) => {
    let cats = [...Guest];

    cats[index]["title"] = event.target.value;

    setGuest(cats);
  };
  let OnChangeAge = (event, index) => {
    let cats = [...Guest];

    cats[index]["age"] = event.target.value;

    setGuest(cats);
  };

  let OnChangeFirstName = (event, index) => {
    let cats = [...Guest];

    cats[index]["FirstName"] = event.target.value;
    let firstregMatch = /^[a-zA-Z ]*$/.test(event.target.value);
    if (event.target.value.length >= 1 && firstregMatch) {
      cats[index]["FirstNameError"] = false;
    } else {
      cats[index]["FirstNameError"] = true;
    }

    setGuest(cats);
  };

  let OnChangeLastName = (event, index) => {
    let cats = [...Guest];
    let lastregMatch = /^[a-zA-Z ]*$/.test(event.target.value);
    cats[index]["LastName"] = event.target.value;

    if (event.target.value.length >= 1 && lastregMatch) {
      cats[index]["LastNameError"] = false;
    } else {
      cats[index]["LastNameError"] = true;
    }

    setGuest(cats);
  };

  let OnChangeEmailId = (event) => {
    const email = validEmailRegex.test(event.target.value);
    if (email == true) {
      setEmailId(event.target.value);
      setEmailError(false);
    } else {
      setEmailId(event.target.value);
      setEmailError(true);
    }
  };
  let OnChangeMobileNumber = (event) => {
    const mobile = event.target.value;
    let mobileregMatch = /^[0-9]*$/.test(event.target.value);
    if (mobile.length > 0 && mobile.length < 15 && mobileregMatch) {
      setMobileNumber(event.target.value);
      setMobileError(false);
    } else {
      setMobileNumber(event.target.value);
      setMobileError(true);
    }
  };

  let OnChangeSpecial = (event) => {
    const special = event.target.value;
    setSpecialReq(special);
    
  };
  let OnChangeReturn = (event) => {
    const returnreq = event.target.value;
    setReturnReq(returnreq);
    
  };
  let OnChangeMobileCode = (event) => {
   
    setMobileCode(event.target.value);
  };

  let addGuest = (e) => {
    setGuest((Guest) => [
      ...Guest,
      {
        title: "Mr",
        FirstName: "",
        LastName: "",
        age: "1",
        FirstNameError: false,
        LastNameError: false,
      },
    ]);
  };

  const  returnInputs = () =>{
    const numbers = Array.from({length: 120}, (_, i) => i + 1);
    const listItems = numbers.map((number) =>  <option value={number}>{number}</option>
    );
    return listItems;
 }

 

  let renderDropdownMenu = () => {
    return (
      <>
        {Guest.map((val, idx) => {
          const adultCounter = GuestCount.reduce(
            (a, v) => a + v.adultCounter * 1,
            0
          );
          const infantCounter = GuestCount.reduce(
            (a, v) => a + v.infantCounter * 1,
            0
          );
         
          const childCounter = GuestCount.reduce(
            (a, v) => a + v.childCounter * 1,
            0
          );

          const youthCounter = GuestCount.reduce(
            (a, v) => a + v.youthCounter * 1,
            0
          );
          const seniorCounter = GuestCount.reduce(
            (a, v) => a + v.seniorCounter * 1,
            0
          );
          const groupCounter = GuestCount.reduce(
            (a, v) => a + v.groupCounter * 1,
            0
          );
          const TotalGuest = adultCounter + infantCounter + childCounter + youthCounter +seniorCounter+ groupCounter;
           
          if (idx < TotalGuest) {
            return (
              <div className="paymakeFlex" key={idx}>
                <div className="guestDtls__col width70 appendRight10">
                  <p className="font11 capText appendBottom10">Title</p>
                  <div className="frmSelectCont">
                    <select
                      id="title"
                      value={Guest[idx].title}
                      className="frmSelect"
                      onChange={(e) => {
                        OnChangeTitle(e, idx);
                      }}
                    >
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Ms">Ms</option>
                    </select>
                  </div>
                </div>
                <div className="paymakeFlex column flexOne">
                  <div className="paymakeFlex">
                    <div className="guestDtls__col width247 appendRight10">
                      <div className="textFieldCol ">
                        <p className="font11 appendBottom10 guestDtlsTextLbl">
                          <span className="capText">
                            FULL NAME AS PER PASSPORT
                          </span>
                        </p>
                        <input
                          type="text"
                          id="fName"
                          name="fName"
                          className={`frmTextInput ${
                            Guest[idx].FirstNameError ? "Err" : ""
                          }`}
                          value={Guest[idx].FirstName}
                          onChange={(e) => OnChangeFirstName(e, idx)}
                          placeholder="First Name"
                        />
                        {Guest[idx].FirstNameError && (
                          <p className="error">  Please enter your First Name in Alphabet</p>
                        )}
                      </div>
                    </div>
                    <div className="guestDtls__col width247">
                      <div className="textFieldCol ">
                        <p className="font11 appendBottom10 guestDtlsTextLbl">
                          <span className="capText"></span>
                        </p>
                        <input
                          type="text"
                          id="lName"
                          name="lName"
                          className={`frmTextInput ${
                            Guest[idx].LastNameError ? "Err" : ""
                          }`}
                          value={Guest[idx].LastName}
                          onChange={(e) => OnChangeLastName(e, idx)}
                          placeholder="Last Name"
                        />

                        {Guest[idx].LastNameError && (
                          <p className="error">  Please enter your Last Name in Alphabet</p>
                        )}
                      </div>
                    </div>
                    <div className="guestDtls__col width71 appendRight11">
                      <p className="font11 capText appendBottom10">Age</p>
                      <div className="frmSelectCont">
                        <select
                         id="age"
                         value={Guest[idx].age}
                         className="frmSelect"
                         onChange={(e) => {
                           OnChangeAge(e, idx);
                         }}
                        >
                           {returnInputs()}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}
      </>
    );
  };

 
  let loginPage = (e) => {
    e.preventDefault();
    setmodalIsOpen(true);
  };

  let ChangeLoginScreen = () => {
    setChangeLoginScreenClass("containerLogin right-panel-active");
  };
  let ChangeLoginReverse = () => {
    setChangeLoginScreenClass("containerLogin");
  };

  let ConfirmBooking = (e) => {
  
    e.preventDefault();
    let CheckError = false;
    let CheckErrorEmail = false;
    let CheckErrorMobile = false;

    let GuestN = [...Guest];

    for (var i = 0; i < GuestN.length; i++) {
      let firstregMatch = /^[a-zA-Z ]*$/.test(GuestN[i].FirstName);
      if (GuestN[i].FirstName.length >= 1 && firstregMatch) {
        CheckError = true;
        GuestN[i]["FirstNameError"] = false;
      } else {
        CheckError = false;
        GuestN[i]["FirstNameError"] = true;
      }

      let lastregMatch = /^[a-zA-Z ]*$/.test(GuestN[i].LastName);
      if (GuestN[i].LastName.length >= 1 && lastregMatch) {
        CheckError = true;
        GuestN[i]["LastNameError"] = false;
      } else {
        CheckError = false;
        GuestN[i]["LastNameError"] = true;
      }
    }

    setGuest(GuestN);
    
    const email = validEmailRegex.test(EmailId);
    if (email == true) {
      setEmailError(false);
      CheckErrorEmail = true;
    } else {
      CheckErrorEmail = false;
      setEmailError(true);
    }
   
    let mobileregMatch = /^[0-9]*$/.test(MobileNumber);
    if (MobileNumber.length > 5 && MobileNumber.length < 15 && mobileregMatch) {
      CheckErrorMobile = true;
      setMobileError(false);
    } else {
      CheckErrorMobile = false;
      setMobileError(true);
    }
    if (
      CheckError == true &&
      CheckErrorEmail == true &&
      CheckErrorMobile == true
    ) {
     
      let text =
      "Please confirm your details and amount of purchase before proceeding forward.";
    if (window.confirm(text) == true) {
      setDisablePayNowButton(true);
      setRedirect(true);
      let customerArr = localStorage.getItem("userDetails");

      let CustomArr = JSON.parse(customerArr);

      let customer_id = CustomArr.uid;

      TourConfirmBooking(
        Payment,
        Guest,
        GuestCount,
        EmailId,
        MobileNumber,
        MobileCode,
        StartDate,
        TourD,
        customer_id,
        TotalTourPrice,
        TransactionUUID,
        SpecialReq,
        ReturnReq
      ).then(
        (data) => {
        
          const form = document.getElementById("confirmBookingButton");
          form.submit();
          // if (data.hs_portal == 'CON') {
          //     setRedirect(false);
          //     const url = "/TourBookingPage?checkin=" + encodeURIComponent(StartDate) + "&query=" + JSON.stringify(GuestCount) + "&hotelImage=" + encodeURIComponent(HotelImage) + "&data=" + encodeURIComponent(TourDetails) + "&tour_location=" + encodeURIComponent(TourLocation) + "&tour_time=" + encodeURIComponent(Time)+ "&PriceType=" + encodeURIComponent(Payment.price[0].price_type_id);
          //     window.location.replace(url);
          // }
        },
        (error) => {}
      );
    }
  }
  };

  let LoginFaceBook = (e) => {
    e.preventDefault();
    setfacebookLogin(true);
  };

  let responseFacebook = (response) => {
    
    if (response.accessToken) {
      setfacebookResponse(response);
      let Name = response.first_name;

      let first_name = response.first_name;
      let last_name = response.last_name;
      let email = response.email;
      let profile_image = response.picture.data.url;
      let birthday = moment(response.birthday).format("Y-MM-DD");
      let gender = response.gender;
      let login_source = 1;

      let mobile_number = "";
      let country1 = country;
      let country_code1 = country_code;
      let currency1 = currency;
      let ip_address1 = ip_address;
      let password = "";
      let verified = 1;

      let Verified = "";
      if (verified == 1) {
        Verified = true;
      }

      const dataSearch = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        country_code: country_code,
        verified: Verified,
        uid: "",
      };

      let JsonData = JSON.stringify(dataSearch);

      localStorage.setItem("userDetails", JsonData);
      setCookie("userDetails", JsonData, { path: "/" });

      setCustomerLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country1,
        country_code1,
        currency1,
        ip_address1,
        password,
        verified
      ).then(
        (data) => {
         
          if (data.id > 0) {
            window.location.reload(true);
          }
        },
        (error) => {}
      );
      setmodalIsOpen(false);
      // window.location.reload(true);
    }
  };

  let responseGoogle = (response) => {
   
    if (response.accessToken) {
      setGoogleResponse(response);

      let first_name = response.profileObj.givenName;
      let last_name = response.profileObj.familyName;
      let email = response.profileObj.email;
      let profile_image = response.profileObj.imageUrl;
      let birthday = "";
      let gender = "";
      let login_source = 2;

      let mobile_number = "";
      let country1 = country;
      let country_code1 = country_code;
      let currency1 = currency;
      let ip_address1 = ip_address;
      let password = "";
      let verified = 1;

      let Verified = "";
      if (verified == 1) {
        Verified = true;
      }

      const dataSearch = {
        first_name: first_name,
        last_name: last_name,
        email: email,
        country_code: country_code,
        verified: Verified,
        uid: "",
      };

      let JsonData = JSON.stringify(dataSearch);

      localStorage.setItem("userDetails", JsonData);
      setCookie("userDetails", JsonData, { path: "/" });

      setCustomerLogin(
        first_name,
        last_name,
        email,
        profile_image,
        birthday,
        gender,
        login_source,
        mobile_number,
        country1,
        country_code1,
        currency1,
        ip_address1,
        password,
        verified
      ).then(
        (data) => {
         
          if (data.id > 0) {
            window.location.reload(true);
          }
        },
        (error) => {}
      );
      setmodalIsOpen(false);
      //window.location.reload(true);
    }
  };

  let getDate = (data) => {
    let DataNew = "";
    data.map((i, j) => {
      if (i.penalty == "No Penalty") {
        const date = new Date(StartDate);
        date.setDate(date.getDate() - i.day_before_arrival);
       
        DataNew = moment(date).format("Do MMMM YYYY");
      }
    });
    return DataNew;
  };


  const displayPayment = (data) => {


    let PriceNew = [];
    data.map((item, key) => {
      if (item.group_price > 0) {
      //  PriceNew.push(item.group_price);
    
        // if (transporttype == 4) {
        //   PriceNew.push(item.group_price * 2);
          
        // } else {
        //   PriceNew.push(item.group_price);
        // }
        if(CheckedTrip ==1){
         // PriceNew.push(item.group_price * 2);

          if(item.flexible_id == 0){

            //   let adult_P = 0;
              let GroupTraveler = GuestCount.reduce(
              (a, v) => a + v.groupCounter * 1,
              0
              );
              
              if(GroupTraveler <=item.group_max){

               // PriceNew.push(item.group_price);
               PriceNew.push(item.group_price * 2);
              } 
          }else{
            let GroupTraveler = GuestCount.reduce(
              (a, v) => a + v.groupCounter * 1,
              0
              );
        
              if(GroupTraveler <=item.group_max && GroupTraveler >=item.group_min){

               // PriceNew.push(item.group_price);
               PriceNew.push(item.group_price * 2);
              } 
          }
       }else{
       // PriceNew.push(item.group_price);
       if(item.flexible_id == 0){

        //   let adult_P = 0;
          let GroupTraveler = GuestCount.reduce(
          (a, v) => a + v.groupCounter * 1,
          0
          );
          
          if(GroupTraveler <=item.group_max){

            PriceNew.push(item.group_price);
          
          } 
      }else{
        let GroupTraveler = GuestCount.reduce(
          (a, v) => a + v.groupCounter * 1,
          0
          );
    
          if(GroupTraveler <=item.group_max && GroupTraveler >=item.group_min){

           PriceNew.push(item.group_price);
          
          } 
      }
       }
        
      } else {
        let total = "";
        if (item.flexible_id == 0) {
          let adult_P = 0;
          let adultTraveler = GuestCount.reduce(
            (a, v) => a + v.adultCounter * 1,
            0
          );
          let infantTraveler = GuestCount.reduce(
            (a, v) => a + v.infantCounter * 1,
            0
          );
          let childTraveler = GuestCount.reduce(
            (a, v) => a + v.childCounter * 1,
            0
          );
          let youthTraveler = GuestCount.reduce(
            (a, v) => a + v.youthCounter * 1,
            0
          );
          let seniorTraveler = GuestCount.reduce(
            (a, v) => a + v.seniorCounter * 1,
            0
          );

          if (item.adult_price > 0) {
            adult_P = item.adult_price * adultTraveler;
          }
          let infant_P = 0;
          if (item.infant_price > 0) {
            infant_P = item.infant_price * infantTraveler;
          }
          let child_P = 0;
          if (item.child_price > 0) {
            child_P = item.child_price * childTraveler;
          }
          let youth_P = 0;
          if (item.youth_price > 0) {
            youth_P = item.youth_price * youthTraveler;
          }
          let senior_P = 0;
          if (item.senior_price > 0) {
            senior_P = item.senior_price * seniorTraveler;
          }

          total =
            adult_P + infant_P + child_P + youth_P + senior_P;

          // if (transporttype == 4) {
          //   PriceNew.push(total * 2);
          // } else {
          //   PriceNew.push(total);
          // }
          if(CheckedTrip ==1){
           let  Price = (total*2);
            PriceNew.push(+Price);
         }else{
          PriceNew.push(+total);
         }
        } else {
          let adultTraveler = GuestCount.reduce(
            (a, v) => a + v.adultCounter * 1,
            0
          );
          let infantTraveler = GuestCount.reduce(
            (a, v) => a + v.infantCounter * 1,
            0
          );
          let childTraveler = GuestCount.reduce(
            (a, v) => a + v.childCounter * 1,
            0
          );
          let youthTraveler = GuestCount.reduce(
            (a, v) => a + v.youthCounter * 1,
            0
          );
          let seniorTraveler = GuestCount.reduce(
            (a, v) => a + v.seniorCounter * 1,
            0
          );

          let adult_P = 0;
          if (
            adultTraveler <= item.adult_max &&
            adultTraveler >= item.adult_min
          ) {
            adult_P = item.adult_price * adultTraveler;
          }
          let infant_P = 0;
          if (
            infantTraveler <= item.infant_max &&
            infantTraveler >= item.infant_min
          ) {
            infant_P = item.infant_price * infantTraveler;
          }
          let child_P = 0;
          if (
            childTraveler <= item.child_max &&
            childTraveler >= item.child_min
          ) {
            child_P = item.child_price * childTraveler;
          }
          let youth_P = 0;
          if (
            youthTraveler <= item.youth_max &&
            youthTraveler >= item.youth_min
          ) {
            youth_P = item.youth_price * youthTraveler;
          }
          let senior_P = 0;
          if (
            seniorTraveler <= item.senior_max &&
            seniorTraveler >= item.senior_min
          ) {
            senior_P = item.senior_price * seniorTraveler;
          }



          total =
            adult_P + infant_P + child_P + youth_P + senior_P;
         
          if (total != 0) {
            // if (transporttype == 4) {
            //   PriceNew.push(total * 2);
            // } else {
            //   PriceNew.push(total);
            // }
            if(CheckedTrip ==1){
              Price = total*2;
              PriceNew.push(+Price);
           }else{
            PriceNew.push(total);
           }

          }
        }
      }

    });

    let Price = PriceNew.reduce((a, v) => a + v * 1, 0);


    if (Payment.specialOffer != "no_offer") {
      let result = [];

      Payment.specialOffer.map((i, j) => {

        if (i.combineoffer == "nocombine") {
          if (i.specialdata.discount_id == 2) {
            const disA = i.specialdata.rate_amount;
            result.push(+disA);
          } else {
            const disA = Price - i.specialdata.rate_amount;
            result.push(+disA);
          }
        } else {
          if (i.specialdata.discount_id == 2) {
            const disA = i.specialdata.rate_amount;
            result.push(+disA);
          } else {
            const disA = Price - i.specialdata.rate_amount;
            result.push(+disA);
          }
        }
      });


      const Amount = result.reduce((a, v) => a + v * 1, 0).toFixed(2);

      const $UpliftAmountSenior = (parseInt(Payment.priceuplift[0]) / 100) * Price;
      const UpliftGroupSenior = (parseInt(Price) + $UpliftAmountSenior);
      const balanceAmount =
        UpliftGroupSenior - (Math.round(Amount * UpliftGroupSenior) / 100).toFixed(2);

      let totalamount = 0;
      let taxamount = 0;
      if (TaxPayment != null && TaxPayment != 0) {
        let totaltax = (Math.round(TaxPayment * balanceAmount) / 100).toFixed(2);
        totalamount = balanceAmount + parseInt(totaltax);
        taxamount = parseInt(totaltax);
      } else {
        totalamount = balanceAmount
        taxamount = 0;
      }

      return (
        <div className="price-breakup">
          <div className="price-header">
          <h4 className="font16 latoBlack blackText">Price Breakup</h4>
          </div>
          <div className="total-amount">
            <div>
              <h6 style={{ color: "black" }}>
                {data.slice(0, 1).map((item, key) => {

                  return (
                    <>
                      {item.price_type_id == 1 ? (
                        <>
                          {GuestCount.reduce(
                            (a, v) => a + v.adultCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.adultCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Adults,&nbsp;
                              </>
                            )}

                          {GuestCount.reduce(
                            (a, v) => a + v.childCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.childCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Children&nbsp;
                              </>
                            )}

                          {GuestCount.reduce(
                            (a, v) => a + v.infantCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.infantCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Infant,&nbsp;
                              </>
                            )}

                          {GuestCount.reduce(
                            (a, v) => a + v.youthCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.youthCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Youth,&nbsp;
                              </>
                            )}

                          {GuestCount.reduce(
                            (a, v) => a + v.SeniorCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.SeniorCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Senior,&nbsp;
                              </>
                            )}
                        </>
                      ) : (
                        <>
                          {GuestCount.reduce(
                            (a, v) => a + v.groupCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.groupCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Travelers,&nbsp;
                              </>
                            )}
                        </>
                      )}
                    </>
                  )
                })}
              </h6>
              <p>Base Price</p>
            </div>
            <div>
              <p
                class="makeFlex hrtlCenter appendBottom10 directionRtl"
                style={{ textaAlign: "end", marginTop: 0 }}
              >
                <span class="redText font12">
                  <span>Save </span>
                  <span class="latoBold" id="hlistpg_hotel_saved_price">
                    <span>€</span>{((Amount * UpliftGroupSenior) / 100).toFixed(2)}
                  </span>
                </span>
                <span class="spriteNew  relative appendLeft5">
                  <span
                    class="latoBold font11 whiteText discountText"
                    id="hlistpg_hotel_saved_price_percentage"
                    style={{
                      lineHeight: "15px",
                      paddingRight: "5px",
                    }}
                  >
                    {Amount}%
                  </span>
                </span>
              </p>
              <p
                className="grayText font16 lineThrough appendBottom5"
                id="hlistpg_hotel_cut_price"
              >
                <span>€</span>{UpliftGroupSenior}
              </p>
              <p
                className="latoBlack font26 blackText appendBottom5"
                id="hlistpg_hotel_shown_price"
              >
                <span>€</span>{balanceAmount.toFixed(2)}
              </p>


            </div>
          </div>
          <div className="taxes">
            <h6 style={{ color: "black" }}>
            Taxes & Fees
            </h6>
            <h6 style={{ color: "black" }}>  {taxamount.toFixed(2) == 0.00 ? (<>Included</>) : (<><FontAwesomeIcon
              icon={faEuroSign}
              style={{ fontSize: "14px", marginRight: "5px" }}
            ></FontAwesomeIcon>  {taxamount.toFixed(2)}</>)}</h6>
          </div>
          <div className="total-amount">
            <h6 style={{ color: "black" }}>
              Total Amount to be paid
            </h6>
            <h6 style={{ color: "red" }}>
              <FontAwesomeIcon
                icon={faEuroSign}
                style={{ fontSize: "14px", marginRight: "5px" }}
              ></FontAwesomeIcon>
              {/* {Price} */}
              {totalamount.toFixed(2)}
            </h6>
          </div>
        </div>
      );
    } else {

      const $UpliftAmountSenior = (parseInt(Payment.priceuplift[0]) / 100) * Price;
      const UpliftGroupSenior = (parseInt(Price) + $UpliftAmountSenior);
      let totalamount = 0;
      let taxamount = 0;
      if (TaxPayment != null && TaxPayment != 0) {
        let totaltax = (Math.round(TaxPayment * UpliftGroupSenior) / 100).toFixed(2);
        totalamount = UpliftGroupSenior + parseInt(totaltax);
        taxamount = parseInt(totaltax);
      } else {
        totalamount = UpliftGroupSenior
        taxamount = 0;
      }

      return (

        <div className="price-breakup">
          <div className="price-header">
          <h4 className="font16 latoBlack blackText">Price Breakup</h4>
          </div>
          <div className="total-amount">
            <div>
              <h6 style={{ color: "black" }}>
                {data.slice(0, 1).map((item, key) => {

                  return (
                    <>
                      {item.price_type_id == 1 ? (
                        <>
                          {GuestCount.reduce(
                            (a, v) => a + v.adultCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.adultCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Adults,&nbsp;
                              </>
                            )}

                          {GuestCount.reduce(
                            (a, v) => a + v.childCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.childCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Children&nbsp;
                              </>
                            )}

                          {GuestCount.reduce(
                            (a, v) => a + v.infantCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.infantCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Infant,&nbsp;
                              </>
                            )}

                          {GuestCount.reduce(
                            (a, v) => a + v.youthCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.youthCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Youth,&nbsp;
                              </>
                            )}

                          {GuestCount.reduce(
                            (a, v) => a + v.SeniorCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.SeniorCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Senior,&nbsp;
                              </>
                            )}
                        </>
                      ) : (
                        <>
                          {GuestCount.reduce(
                            (a, v) => a + v.groupCounter * 1,
                            0
                          ) > 0 && (
                              <>
                                <span class="latoBlack">
                                  {GuestCount.reduce(
                                    (a, v) => a + v.groupCounter * 1,
                                    0
                                  )}
                                </span>
                                &nbsp;Travelers,&nbsp;
                              </>
                            )}
                        </>
                      )}
                    </>
                  )
                })}
              </h6>
              <p>Base Price</p>
            </div>
            <div>
              <h6 style={{ color: "grey" }}>
                <FontAwesomeIcon
                  icon={faEuroSign}
                  style={{ fontSize: "14px", marginRight: "5px" }}
                ></FontAwesomeIcon>
                {UpliftGroupSenior}
              </h6>
            </div>
          </div>
          <div className="taxes">
            <h6 style={{ color: "black" }}>
              Tax & Fees
            </h6>
            <h6 style={{ color: "black" }}>  {taxamount.toFixed(2) == 0.00 ? (<>Included</>) : (<><FontAwesomeIcon
              icon={faEuroSign}
              style={{ fontSize: "14px", marginRight: "5px" }}
            ></FontAwesomeIcon>  {taxamount.toFixed(2)}</>)}</h6>
          </div>
          <div className="total-amount">
            <h6 style={{ color: "black" }}>
              Total Amount to be paid
            </h6>
            <h6 style={{ color: "red" }}>
              <FontAwesomeIcon
                icon={faEuroSign}
                style={{ fontSize: "14px", marginRight: "5px" }}
              ></FontAwesomeIcon>
              {/* {Price} */}
              {totalamount}

            </h6>
          </div>
        </div>
      );
    }
  }

  let getDays = (data) => {
    let DataNew = "";
    data.map((i, j) => {
      if (i.penalty == "No Penalty") {
        const date = new Date(StartDate);
        date.setDate(date.getDate() - i.day_before_arrival);
       
        DataNew = moment(date).format("D MMMM ");
      }
    });

    return DataNew;
  };

  let CancellationModal = (data) => {
    let DataNew = "";
    let Value = "";
    return (
      <>
        {" "}
        {data.map((i, index) => {
          const date = new Date(StartDate);
          date.setDate(date.getDate() - i.day_before_arrival);
          
          DataNew = moment(date).format("Do MMMM YYYY");
          if (i.penalty == "No Penalty") {
            Value = 0;
          } else if (i.penalty == "Full Amount") {
            Value = 100;
          } else if (i.penalty == "Percentage") {
            Value = i.cancellation_value;
          } else if (i.penalty == "Nights") {
            Value = i.cancellation_value;
          } else if (i.penalty == "Fixed Amount") {
            Value = i.cancellation_value;
          }

          if (i.day_before_arrival == 1) {
            DataNew = "No Show";
          }

          return (
            <div className="cncpolicyTbl__row">
              <div className="cncpolicyTbl__col">
                {index == 0 ? "Before" : "After"} &#x202A;{DataNew}&#x202C;
              </div>
              <div className="cncpolicyTbl__col">
                {Value}% of booking amount
              </div>
            </div>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tourid = urlParams.get("tourid");
    const dateSelected = urlParams.get("dateSelected");
    const tourImage = urlParams.get("tourImage");
    const ratePlanId = urlParams.get("ratePlanId");
    const number = urlParams.get("number");
    const numberTraveler = urlParams.get("numberTraveler");
    const tourName = urlParams.get("tourName");
    const tourOption = urlParams.get("tourOption");
    const tourLocation = urlParams.get("tourLocation");
    const time = urlParams.get("time");
    const price_type = urlParams.get("price_type");
    const tour_operator_id = urlParams.get("tour_operator_id");
    const transfertype = urlParams.get("transfertype");
    const checkedroundtrip = urlParams.get("checkedroundtrip");

    if(checkedroundtrip =='true'){
      setCheckedTrip(1);
    }
    

    const ObjNew = {
      tour_id: Base64.atob(tourid),
      ratePlanId: Base64.atob(ratePlanId),
      number: Base64.atob(number),
      tour_operator_id: tour_operator_id,
      price_type: price_type,
      time: time,
    };

    setTourD(ObjNew);
    setTransporttype(transfertype);
  //  setCheckedTrip(checkedroundtrip);

    let numberTravelerNew = JSON.parse(numberTraveler);
    if (price_type == 1) {
      const adultCounter = numberTravelerNew.reduce(
        (a, v) => a + v.adultCounter * 1,
        0
      );
      const childCounter = numberTravelerNew.reduce(
        (a, v) => a + v.childCounter * 1,
        0
      );
      const youthCounter = numberTravelerNew.reduce(
        (a, v) => a + v.youthCounter * 1,
        0
      );
      const seniorCounter = numberTravelerNew.reduce(
        (a, v) => a + v.seniorCounter * 1,
        0
      );
      const infantCounter = numberTravelerNew.reduce(
        (a, v) => a + v.infantCounter * 1,
        0
      );

      const TotalCount =
        adultCounter * 1 +
        childCounter * 1 +
        youthCounter * 1 +
        seniorCounter * 1 +
        infantCounter * 1;

      let result = [];
      for (var i = 0; i < TotalCount; i++) {
        result.push({
          title: "Mr",
          age: "1",
          FirstName: "",
          LastName: "",
          ErrorFirstName: false,
          ErrorLastName: false,
        });
      }
      setGuest(result);
    } else {
      // const groupCounter = numberTravelerNew.reduce(
      //   (a, v) => a + v.groupCounter * 1,
      //   0
      // );
      const groupCounter = 1;

      let result = [];
      for (var i = 0; i < groupCounter; i++) {
        result.push({
          title: "Mr",
          age: "1",
          FirstName: "",
          LastName: "",
          ErrorFirstName: false,
          ErrorLastName: false,
        });
      }
      setGuest(result);
    }

    setGuestCount(numberTravelerNew);
    setTourDetails(tourName);
    setTourOption(tourOption);
    setTourLocation(tourLocation);
    setStartDate(dateSelected);
    setHotelImage(tourImage);
    setTime(time);

    TourRules(tourid, numberTravelerNew, ratePlanId, number, dateSelected, tour_operator_id).then(
      (result) => {
        if(result.extracharges !='no_extracharge'){
          setTaxPayment(result.extracharges);
        }else{
          setTaxPayment(0);
        }
        if (result.tourAvail == "available") {
          setPayment(result);
        
          let PriceArr = result;
          let Price = [];
          PriceArr.price[0].map((item, index) => {
           // let Price = 0;
            if (item.group_price > 0) {
            //   if(CheckedTrip ==1){
            //  // Price = item.group_price*2;
            //  let PriceN = item.group_price*2;
            //  Price.push(+PriceN);
            //   }else{
            //    // Price = item.group_price;
            //    Price.push(+item.group_price);
            //   }
            if(CheckedTrip ==1){
            if(item.flexible_id == 0){

                let GroupTraveler = numberTravelerNew.reduce(
                  (a, v) => a + v.groupCounter * 1,
                  0
                );
                
                if(GroupTraveler <=item.group_max){
  
                 // Price.push(+item.group_price);
                 let PriceN = item.group_price*2;
                 Price.push(+PriceN);
                } 
            }else{
              
              let GroupTraveler = numberTravelerNew.reduce(
                (a, v) => a + v.groupCounter * 1,
                0
              );
          
                if(GroupTraveler <=item.group_max && GroupTraveler >=item.group_min){
  
                 // Price.push(+item.group_price);
                 let PriceN = item.group_price*2;
                 Price.push(+PriceN);
                } 
            }
          }else{
               
            if(item.flexible_id == 0){

              let GroupTraveler = numberTravelerNew.reduce(
                (a, v) => a + v.groupCounter * 1,
                0
              );
              
              if(GroupTraveler <=item.group_max){

              
                Price.push(+item.group_price);
              } 
          }else{
            
            let GroupTraveler = numberTravelerNew.reduce(
              (a, v) => a + v.groupCounter * 1,
              0
            );
        
              if(GroupTraveler <=item.group_max && GroupTraveler >=item.group_min){

               
                Price.push(+item.group_price);
              } 
          }
          }
            } else {
              let total = "";

              if (item.flexible_id == 0) {
                let adult_P = 0;
                let adultTraveler = numberTravelerNew.reduce(
                  (a, v) => a + v.adultCounter * 1,
                  0
                );
                let infantTraveler = numberTravelerNew.reduce(
                  (a, v) => a + v.infantCounter * 1,
                  0
                );
                let childTraveler = numberTravelerNew.reduce(
                  (a, v) => a + v.childCounter * 1,
                  0
                );
                let youthTraveler = numberTravelerNew.reduce(
                  (a, v) => a + v.youthCounter * 1,
                  0
                );
                let seniorTraveler = numberTravelerNew.reduce(
                  (a, v) => a + v.seniorCounter * 1,
                  0
                );

                if (item.adult_price > 0) {
                  adult_P = item.adult_price * adultTraveler;
                }
                let infant_P = 0;
                if (item.infant_price > 0) {
                  infant_P = item.infant_price * infantTraveler;
                }
                let child_P = 0;
                if (item.child_price > 0) {
                  child_P = item.child_price * childTraveler;
                }
                let youth_P = 0;
                if (item.youth_price > 0) {
                  youth_P = item.youth_price * youthTraveler;
                }
                let senior_P = 0;
                if (item.senior_price > 0) {
                  senior_P = item.senior_price * seniorTraveler;
                }

                total = adult_P + infant_P + child_P + youth_P + senior_P;

               // Price = total;
              //  if(CheckedTrip !=0 && CheckedTrip ==1){
              //     let PriceN = total*2;
              //     Price.push(+PriceN);
              //   }else{
              //     Price.push(+total);
              //   }

                if(CheckedTrip ==1){
                  let PriceN = total*2;
                  Price.push(+PriceN);
               }else{
               // Price = item.group_price;
                Price.push(+total);
               }
              }else{
              
                let adultTraveler = numberTravelerNew.reduce(
                  (a, v) => a + v.adultCounter * 1,
                  0
                );
                let infantTraveler = numberTravelerNew.reduce(
                  (a, v) => a + v.infantCounter * 1,
                  0
                );
                let childTraveler = numberTravelerNew.reduce(
                  (a, v) => a + v.childCounter * 1,
                  0
                );
                let youthTraveler = numberTravelerNew.reduce(
                  (a, v) => a + v.youthCounter * 1,
                  0
                );
                let seniorTraveler = numberTravelerNew.reduce(
                  (a, v) => a + v.seniorCounter * 1,
                  0
                );

                let adult_P = 0;
                if (
                  adultTraveler <= item.adult_max &&
                  adultTraveler >= item.adult_min
                ) {
                  adult_P = item.adult_price * adultTraveler;
                }
                let infant_P = 0;
                if (
                  infantTraveler <= item.infant_max &&
                  infantTraveler >= item.infant_min
                ) {
                  infant_P = item.infant_price * infantTraveler;
                }
                let child_P = 0;
                if (
                  childTraveler <= item.child_max &&
                  childTraveler >= item.child_min
                ) {
                  child_P = item.child_price * childTraveler;
                }
                let youth_P = 0;
                if (
                  youthTraveler <= item.youth_max &&
                  youthTraveler >= item.youth_min
                ) {
                  youth_P = item.youth_price * youthTraveler;
                }
                let senior_P = 0;
                if (
                  seniorTraveler <= item.senior_max &&
                  seniorTraveler >= item.senior_min
                ) {
                  senior_P = item.senior_price * seniorTraveler;
                }
               
                total =
                  adult_P + infant_P + child_P + youth_P + senior_P;
               
                if (total != 0) {
                 // Price = total;
                 if(CheckedTrip ==1){
                 // Price = total*2;
                  let PriceN = total*2;
                  Price.push(+PriceN);
                }else{
                  Price.push(+total);
                 // Price = total;
                }
                }

              }
            }
          });
          let PriceTotal = Price.reduce((a, v) => a + v * 1, 0).toFixed(2);
             if(PriceTotal >0){

              if (PriceArr.specialOffer != "no_offer") {
                let result = [];

                PriceArr.specialOffer.map((i, j) => {
              
                if (i.combineoffer== "nocombine") {
                    if (i.specialdata.discount_id == 2) {
                      const disA = i.specialdata.rate_amount;
                      result.push(+disA);
                    } else {
                      const disA = PriceTotal - i.specialdata.rate_amount;
                      result.push(+disA);
                    }
                } else {
                    if (i.specialdata.discount_id == 2) {
                      const disA = i.specialdata.rate_amount;
                      result.push(+disA);
                    } else {
                      const disA = PriceTotal - i.specialdata.rate_amount;
                      result.push(+disA);
                    }
                }
              });
                

                const Amount = result.reduce((a, v) => a + v * 1, 0).toFixed(2);

                // const balanceAmount =
                // Price - (Math.round(Amount * Price) / 100).toFixed(2);
                const $UpliftAmountSenior = (parseInt(PriceArr.priceuplift[0]) / 100) * PriceTotal;
                const UpliftGroupSenior = (parseInt(PriceTotal) + $UpliftAmountSenior);
                const balanceAmount =
                UpliftGroupSenior - (Math.round(Amount * UpliftGroupSenior) / 100).toFixed(2);

                // let totalamount =0;
                // if(TaxPayment !=null && TaxPayment !=0){
                //   let totaltax = (Math.round(TaxPayment * balanceAmount) / 100).toFixed(2);
                //  totalamount = balanceAmount + parseInt(totaltax);
                // }else{
                //   totalamount = balanceAmount;
                // }
                // let totalamount =0;
                // if(TaxPayment !=0){
                //   let totaltax = (Math.round(TaxPayment * balanceAmount) / 100).toFixed(2);
                //  totalamount = balanceAmount + parseInt(totaltax);
                //  setTotalTourPrice(totalamount);
                // }else{
                //   totalamount = balanceAmount;
                //   setTotalTourPrice(totalamount);
                // }
               
                // if (PriceArr.extracharges != "no_extracharge") {
                //   const totaltax = (Math.round(PriceArr.extracharges * balanceAmount) / 100).toFixed(2);
                //   const totalamount = balanceAmount + parseInt(totaltax);
                //   setTotalTourPrice(totalamount);
                // }else{
                //   const totalamount = balanceAmount;
                //   setTotalTourPrice(totalamount);
                // }
                if (PriceArr.extracharges != "no_extracharge") {
                  const totaltax = (Math.round(PriceArr.extracharges * balanceAmount) / 100).toFixed(2);
                  const totalamount = balanceAmount + parseInt(totaltax);
                  setTotalTourPrice(totalamount);
                }else{
                  const totalamount = balanceAmount;
                  setTotalTourPrice(totalamount);
                }
              
              }else{
                // let  totalamount =0;
                // if(TaxPayment !=null && TaxPayment !=0){
                //    let totaltax = (Math.round(TaxPayment * Price) / 100).toFixed(2);
                //   totalamount = parseInt(Price) + parseInt(totaltax);
                //  }else{
                //    totalamount = Price;
                //  }
                // let  totalamount =0;
                // if(TaxPayment !=null && TaxPayment !=0){
                //    let totaltax = (Math.round(TaxPayment * Price) / 100).toFixed(2);
                //   totalamount = parseInt(Price) + parseInt(totaltax);
                //   setTotalTourPrice(totalamount);
                //  }else{
                //    totalamount = Price;
                //    setTotalTourPrice(totalamount);
                //  }
                const $UpliftAmountSenior = (parseInt(PriceArr.priceuplift[0]) / 100) * PriceTotal;
                const UpliftGroupSenior = (parseInt(PriceTotal) + $UpliftAmountSenior);
                if (PriceArr.extracharges != "no_extracharge") {
                  const totaltax = (Math.round(PriceArr.extracharges * UpliftGroupSenior) / 100).toFixed(2);
                  const totalamount = parseInt(UpliftGroupSenior) + parseInt(totaltax);
                  setTotalTourPrice(totalamount);
                }else{
                  const totalamount = UpliftGroupSenior;
                  setTotalTourPrice(totalamount);
                }
              
              
              }
              
             }
            
         // });
        } else {
          setPayment("abc");
        }
      },
      (error) => {}
    );

    getGeoInfo().then(
      (data) => {
       
        setcountry(data.country_name);
        setcountry_code(data.country_calling_code);
        setcurrency(data.currency);
        setip_address(data.ip);
      },
      (error) => {}
    );
    let userData = localStorage.getItem("userDetails");

    if (userData != null) {
      let newData = JSON.parse(userData);
      getCustomerLogin(newData.email).then(
        (data) => {
         
          setcustomerDetails(data);
          let Verified = "";
          if (data.verified == 1) {
            Verified = true;
          }

          const dataSearch = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            country_code: data.country_code,
            verified: Verified,
            uid: data.id,
          };

          let JsonData = JSON.stringify(dataSearch);

          localStorage.setItem("userDetails", JsonData);
        },
        (error) => {}
      );
    }
  }, []);


  return (
    <div>
       <Helmet>
        <title>Best Payment Experience @ Holidays Seychelles</title>
      </Helmet>
      <h1 style={{}}>Best Payment Experience @ Holidays Seychelles</h1>
      <Header />

      {Payment == null ? (
        <PaymentDetailsSkeleton />
      ) : (
        <div className="sticky-scroll topSection appendBottom40">
          <div className="review">
            <div className="landingContainer">
              <h4>Review Your Booking</h4>
            </div>
          </div>
          <div className="Details">
            <div className="landingContainer_details">
              <div className="subDetails">
                <div className={drop.class}>
                  {/* <div className="upper-Scroll">
                    <h6 style={{ color: "black" }}>EXPERIENCE INFORMATION</h6>
                    <button
                      onClick={HotelToggleHandler}
                      style={{
                        backgroundColor: "transparent",
                        border: "transparent",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                    </button>
                  </div> */}
                  <div className="Detail-Hotel">
                    <div className="Hotel-Name">
                      <div className="nameContainer">
                        <div className="paymakeFlex appendBottom5">
                          <h3 className="latoBlack font22 blackText">
                            {TourDetails}
                          </h3>
                        </div>
                        <h5 className="latoBlack font14 blackText">{TourOption}</h5>
                        <p>{TourLocation}</p>
                      </div>
                      <div className="imageContainer">
                        <img src={HotelImage} />
                      </div>
                    </div>

                    <div class="chkCont">
                      <div class="chkCont__col">
                        <div class="paymakeFlex column">
                          <span class="font10 grayText appendBottom3">
                            DATE
                          </span>
                          <span class="latoBlack font24 blackText appendBottom3">
                            {moment(StartDate).format("D MMMM Y")}
                          </span>
                        </div>
                        <div class="chkCont__night">
                          <span></span>
                        </div>
                        <div class="paymakeFlex column">
                          <span class="font10 grayText appendBottom3">
                            Time
                          </span>
                          <span class="latoBlack font24 blackText appendBottom3">
                            {Time=='null'?(<> Coordinated Times</>):(<>{Time}</>)}
                          </span>
                        </div>
                      </div>
                      <div class="chkCont__col">
                        <p class="font16 blackText">
                        {Payment.price[0].slice(0, 1).map((item, key) => {

                            return (
                              <>
                                {item.price_type_id == 1 ? (
                                  <>
                                    {GuestCount.reduce(
                                      (a, v) => a + v.adultCounter * 1,
                                      0
                                    ) > 0 && (
                                        <>
                                          <span class="latoBlack">
                                            {GuestCount.reduce(
                                              (a, v) => a + v.adultCounter * 1,
                                              0
                                            )}
                                          </span>
                                          &nbsp;Adults,&nbsp;
                                        </>
                                      )}

                                    {GuestCount.reduce(
                                      (a, v) => a + v.childCounter * 1,
                                      0
                                    ) > 0 && (
                                        <>
                                          <span class="latoBlack">
                                            {GuestCount.reduce(
                                              (a, v) => a + v.childCounter * 1,
                                              0
                                            )}
                                          </span>
                                          &nbsp;Children&nbsp;
                                        </>
                                      )}

                                    {GuestCount.reduce(
                                      (a, v) => a + v.infantCounter * 1,
                                      0
                                    ) > 0 && (
                                        <>
                                          <span class="latoBlack">
                                            {GuestCount.reduce(
                                              (a, v) => a + v.infantCounter * 1,
                                              0
                                            )}
                                          </span>
                                          &nbsp;Infant,&nbsp;
                                        </>
                                      )}

                                    {GuestCount.reduce(
                                      (a, v) => a + v.youthCounter * 1,
                                      0
                                    ) > 0 && (
                                        <>
                                          <span class="latoBlack">
                                            {GuestCount.reduce(
                                              (a, v) => a + v.youthCounter * 1,
                                              0
                                            )}
                                          </span>
                                          &nbsp;Youth,&nbsp;
                                        </>
                                      )}

                                    {GuestCount.reduce(
                                      (a, v) => a + v.SeniorCounter * 1,
                                      0
                                    ) > 0 && (
                                        <>
                                          <span class="latoBlack">
                                            {GuestCount.reduce(
                                              (a, v) => a + v.SeniorCounter * 1,
                                              0
                                            )}
                                          </span>
                                          &nbsp;Senior,&nbsp;
                                        </>
                                      )}
                                  </>
                                ) : (
                                  <>
                                    {GuestCount.reduce(
                                      (a, v) => a + v.groupCounter * 1,
                                      0
                                    ) > 0 && (
                                        <>
                                          <span class="latoBlack">
                                            {GuestCount.reduce(
                                              (a, v) => a + v.groupCounter * 1,
                                              0
                                            )}
                                          </span>
                                          &nbsp;Travelers,&nbsp;
                                        </>
                                      )}
                                  </>
                                )}
                              </>
                            )
                            })}

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <form>
                <div className="GuestInfo">
                  <div className="upper-Scroll">
                    <h6 style={{ color: "black" }}>GUEST DETAILS</h6>
                    <button
                      onClick={FormToggleHandler}
                      style={{
                        backgroundColor: "transparent",
                        border: "transparent",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                    </button>
                  </div>
                  <div className={drop.formClass}>
                   
                      <div className="guestDtls__row">
                        {renderDropdownMenu()}
                      </div>
                      <div className="guestDtls__row">
                        <div className="paymakeFlex">
                          <div className="guestDtls__col width327 appendRight10">
                            <div className="textFieldCol ">
                              <p className="font11 appendBottom10 guestDtlsTextLbl">
                                <span className="capText">Email Address</span>
                                <span className="grayText appendLeft3">
                                  (Any Booking voucher will be sent to this
                                  email ID)
                                </span>
                              </p>
                              <input
                                type="text"
                                id="email"
                                name="email"
                                className={`frmTextInput ${
                                  EmailError ? "Err" : ""
                                }`}
                                value={EmailId}
                                onChange={(e) => {
                                  OnChangeEmailId(e);
                                }}
                                placeholder="Email ID"
                              />
                              {EmailError && (
                                <p className="error">
                                  {" "}
                                  Please enter a valid Email ID!
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="guestDtls__col width327">
                            <p className="font11 capText appendBottom10">
                              Mobile Number
                            </p>
                            <div
                              className="paymakeFlex textLtr"
                              style={{ marginTop: 12 }}
                            >
                              <div className="guestDtls__contact">
                                <label
                                  for="mCode"
                                  className=" frmSelectCont__contact"
                                >
                                  <select
                                    id="mCode"
                                    className="frmSelect frmSelectContact"
                                    value={MobileCode}
                                    onChange={OnChangeMobileCode}
                                  >
                                    {DialCode.map((item, key) => (
                                      <option value={item.dial_code}>
                                        {item.name}&nbsp;({item.dial_code})
                                      </option>
                                    ))}
                                  </select>
                                </label>
                                <span className="selectedCode">
                                  {MobileCode == "" ? country_code : MobileCode}
                                </span>
                              </div>
                              <div className="flexOne">
                                <div className="textFieldCol ">
                                  <input
                                    type="text"
                                    id="mNo"
                                    name="mNo"
                                    className={`frmTextInput noLeftBorder ${
                                      MobileError ? "Err" : ""
                                    }`}
                                    placeholder="Mobile Number"
                                    value={MobileNumber}
                                    onChange={(e) => {
                                      OnChangeMobileNumber(e);
                                    }}
                                  />
                                  {MobileError && (
                                    <p className="error">
                                      {" "}
                                      Please enter a valid number!
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>

                <div className="GuestInfo" style={{'marginTop': '10px'}}>
                  <div className="upper-Scroll">
                    <h6 style={{ color: "black" }}>Tour Details & Special Requrement</h6>
                   
                   
                  </div>
                  <div className={drop.formClass}>
                   
                      <div className="guestDtls__row">
                      <div className="flexOne">
                          <div className="textFieldCol ">
                          <div class="form-group">
                            <label for="exampleFormControlTextarea1">Special Requrement</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"  onChange={(e) => {
                                OnChangeSpecial(e);
                              }}></textarea>
                          </div>
                          </div>

                          {CheckedTrip ==1 &&
                          (<>
                          <div className="textFieldCol ">
                          <div class="form-group">
                            <label for="exampleFormControlTextarea1">Return Details.(pick up date and time. Flight details if going to the airport)</label>
                            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"  onChange={(e) => {
                                OnChangeReturn(e);
                              }}></textarea>
                          </div>
                          </div></>)
                          }
                        </div>
                      </div>
                      
                      
                   
                  </div>
                </div>
                </form>

                <form
                  id="confirmBookingButton"
                  action={CyberSource}
                  method="post"
                >
                  <input
                    type="hidden"
                    name="bill_to_forename"
                    value={Guest[0].FirstName}
                  />
                  <input
                    type="hidden"
                    name="bill_to_surname"
                    value={Guest[0].LastName}
                  />
                  <input type="hidden" name="currency" value="EUR" />

                  {CheckedTrip ==1 ?(<input type="hidden" name="amount" value={TaxPayment !=null && TaxPayment !=0?TotalTourPrice*2:TotalTourPrice*2} />):
                          (<input type="hidden" name="amount" value={TaxPayment !=null && TaxPayment !=0?TotalTourPrice:TotalTourPrice} />)}
                  
                 
                  <input type="hidden" name="bill_to_email" value={EmailId} />
                  <input
                    type="hidden"
                    name="transaction_uuid"
                    value={TransactionUUID}
                  />
                  <div style={{ padding: "10px 0px" }}>
                    <p style={{ padding: "10px 0px" }}>
                      By proceeding, I agree to Holidays Seychelles User
                      Agreement, Terms of Service and Cancellation & Hotel
                      Booking Policies
                    </p>
                    <button
                      className="btnContinuePayment primaryBtn capText"
                      onClick={
                        customerDetails == "abc" ? loginPage : ConfirmBooking
                      }
                      disabled={DisablePayNowButton}
                    >
                      Pay Now
                    </button>
                  </div>
                </form>
                {/* <div style={{ padding: '15px 20px 15px 20px' }}>
                                    <p>By proceeding, I agree to Holidays Seychelles User Agreement, Terms of Service and Cancellation & Hotel Booking Policies</p>
                                    <span className="pay" onClick={customerDetails == 'abc' ? loginPage : ConfirmBooking}>Pay Now</span>
                                </div> */}
              </div>

              {Payment.tourAvail == "available" ? (
                <>
                  <div className="pricing-details">
                    {displayPayment(Payment.price[0])}

                  </div>

                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        <div className={ChangeLoginScreenClass}>
          <div className="form-container sign-up-container">
            <form className="FormCon">
              <h1>Create Account</h1>
              <SignUp
                country_code={country_code}
                currency={currency}
                ip_address={ip_address}
                country={country}
              />
            </form>
          </div>
          {facebookLogin && (
            <FacebookLogin
              appId="426993597895758"
              autoLoad={true}
              fields="first_name, last_name, email, picture, birthday,gender"
              scope="public_profile, email, user_birthday,user_gender"
              returnScopes={true}
              callback={responseFacebook}
            />
          )}

          <div className="form-container sign-in-container">
            <form className="FormCon" action="#">
              <h1>LOG IN</h1>
              {errorPassword && (
                <p className="error"> Email or Password is not correct !</p>
              )}
              <input
                type="email"
                placeholder="Email"
                value={LoginEmail}
                onChange={ChangeLoginEmail}
              />
              <input
                type="password"
                placeholder="Password"
                value={LoginPassword}
                onChange={ChangeLoginPassword}
              />
              <a href="#">Forgot your password?</a>
              <button onClick={LoginConfirm}>LOG IN</button>
              <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                <div
                  className="SocialmakeFlex fbLoginBtn flexOne"
                  onClick={LoginFaceBook}
                >
                  <span className=" popupSprite popupFbIcon"></span>
                  <span
                    className="font14 whiteText latoBold latoBold"
                    style={{ textTransform: "uppercase" }}
                  >
                    Login with Facebook
                  </span>
                </div>
              </div>
              <div className="SocialmakeFlex socialBtnContainer vrtlCenter">
                <div className="SocialmakeFlex googleLoginBtn flexOne">
                  <span style={{ width: "100%" }}>
                    {" "}
                    <GoogleLogin
                      clientId="924181911314-6ml7tmqad8dkh9ugosligld4b9kkremq.apps.googleusercontent.com"
                      buttonText="Login with Google"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Welcome Back!</h1>
                <p>
                  To keep connected with us please login with your personal info
                </p>
                <button className="ghost" onClick={ChangeLoginReverse}>
                  LOG IN
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hello, Friend!</h1>
                <p>Enter your personal details and start journey with us</p>
                <button className="ghost" onClick={ChangeLoginScreen}>
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PaymentDetailsTransfer;
