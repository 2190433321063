import React, { Component } from 'react';
import "./HotelDetails.css";
import Footer from "../Footer";
import "react-alice-carousel/lib/alice-carousel.css";
import Skeleton from 'react-loading-skeleton';

class HotelDetails extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="Detail-container">
     
                <div className="container" style={{ marginTop: 120, maxWidth:'1210px' }}>
                    <div className="DetailPageTitle">
                        <h4 className="title_dt"><Skeleton style={{width: 300}}/></h4>
                        <span className="stars_dt"><Skeleton style={{width: 100}}/></span>
                    </div>

                    <div className="GalleryImg">
                        <div className="GalleryBig_img">
                            <Skeleton style={{height: 345}}/>
                        </div>
                        <div className="GallerySmall_img">
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <div className="GallerySmallerSection" style={{ paddingRight: 5, paddingBottom: 5 }}>
                                <Skeleton style={{height: 165}} />
                                </div>
                                <div className="GallerySmallerSection" style={{ paddingRight: 5, paddingTop: 5 }}>
                                <Skeleton style={{height: 165}}/>
                                </div>
                            </div>

                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                                <div className="GallerySmallerSection" style={{ paddingLeft: 5, paddingBottom: 5 }}>
                                <Skeleton style={{height: 165}}/>
                                </div>
                                <div className="GallerySmallerSection" style={{ paddingLeft: 5, paddingTop: 5 }}>
                                <Skeleton style={{height: 165}}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container" style={{maxWidth:'1210px'}}>
                    <div id="Overview" className="hotel-details">
                        <div id="faculty" className="Text-Details">
                            <h4><Skeleton style={{width:300}} /></h4>
                            <div className="facilities-container" style={{display: 'flex', justifyContent:'space-between', marginBottom:30}}>
                                <div className="left" style={{width:200}}>
                                <Skeleton count={5} />
                                </div>
                                <div className="right" style={{width:200}}>
                                <Skeleton count={5} style={{width:200}}/>
                                </div>
                                <div className="right" style={{width:200}}>
                                <Skeleton count={5} />
                                </div>
                                <div className="right" style={{width:200}}>
                                <Skeleton count={5} />
                                </div>
                            </div>

                            <div className="main-info">
                                <h4> <Skeleton style={{width:300}} /></h4>
                                <p>
                                <Skeleton count={10}/>
                            </p>
                            </div>
                         
                        </div>

            
                    </div>

                </div>

          
            </div>
        )
    }
}

export default HotelDetails;
