import React, { useState } from 'react';
import Header from "../../Component/NewHeader/NewHeader";
import "./PaymentDetails.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCaretDown, faCheck, faCut } from '@fortawesome/free-solid-svg-icons';
import HotelImage1 from "../../assets/images/hotel/LeRoseVilla.jpg";
import Skeleton from 'react-loading-skeleton';

function PaymentDetails() {


    const [drop, toggledrop] = useState({
        class: "HotelInfo",
        formClass: "form-guest",
        buisnessFormClass: "buisnessShort",
        requestClass: "all-requests"
    })


    let requestFormHandler = () => {
        if (drop.requestClass === "all-requests") {
            toggledrop({
                ...drop,
                requestClass: "all-requests-short"
            })
        }
        else {
            toggledrop({
                ...drop,
                requestClass: "all-requests"
            })
        }
    }

    let buisnessFormHandler = () => {
        if (drop.buisnessFormClass === "buisness") {
            toggledrop({
                ...drop,
                buisnessFormClass: "buisnessShort"
            })
        }
        else {
            toggledrop({
                ...drop,
                buisnessFormClass: "buisness"
            })
        }
    }


    let FormToggleHandler = () => {
        if (drop.formClass === "form-guest") {
            toggledrop({
                ...drop,
                formClass: "form-guest-short"
            })
        }
        else {
            toggledrop({
                ...drop,
                formClass: "form-guest"
            })
        }
    }



    let HotelToggleHandler = () => {
        if (drop.class === "HotelInfo") {
            toggledrop({
                ...drop,
                class: "HotelInfoShort"
            })
        }
        else {
            toggledrop({
                ...drop,
                class: "HotelInfo"
            })
        }
    }
    return (
        <div className="sticky-scroll topSection appendBottom40">


            <div className="review">
                <div className="landingContainer">
                    <h4>Review Your Booking</h4>
                </div>
            </div>
            <div className="Details">
                <div className="landingContainer_details">
                    <div className="subDetails">
                        <div className={drop.class}>
                            <div className="upper-Scroll">
                                <h6 style={{ color: "black" }}><Skeleton style={{ width: 150 }} /></h6>
                                <button onClick={HotelToggleHandler} style={{ backgroundColor: "transparent", border: "transparent" }}><FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon></button>
                            </div>

                            <div className="Detail-Hotel">
                                <div className="Hotel-Name">
                                    <div className="name">
                                        <h2 style={{ display: "inline", marginRight: "20px", color: "black" }}><Skeleton style={{ width: 200 }} /></h2>
                                        <Skeleton style={{ width: 320 }} />
                                        <p><Skeleton style={{ width: 320 }} /></p>
                                    </div>
                                    <div className="image">
                                        <Skeleton style={{ height: 100, width: 150 }} />
                                    </div>
                                </div>

                                <div class="chkCont">
                                    <div class="chkCont__col">
                                        <div class="paymakeFlex column">
                                            <span class="font10 grayText appendBottom3"><Skeleton style={{ width: 100 }} /></span><span class="latoBlack font24 blackText appendBottom3"><Skeleton style={{ width: 100 }} /></span><span class="font10 grayText 444"><Skeleton style={{ width: 100 }} /></span>
                                        </div>
                                        <div class="chkCont__night"><span><Skeleton style={{ width: 50 }} /></span></div>
                                        <div class="paymakeFlex column"><span class="font10 grayText appendBottom3"><Skeleton style={{ width: 100 }} /></span><span class="latoBlack font24 blackText appendBottom3"><Skeleton style={{ width: 100 }} /></span><span class="font10 grayText"><Skeleton style={{ width: 100 }} /></span></div>
                                    </div>
                                    <div class="chkCont__col"><p class="font16 blackText"><span class="latoBlack"><Skeleton style={{ width: 50 }} /></span>&nbsp;<Skeleton style={{ width: 50 }} />&nbsp;| <span><Skeleton style={{ width: 50 }} /></span></p></div>

                                </div>

                                <div className="RoomInfo">
                                    <div className="Room-Name">
                                        <h5 style={{ color: "black" }}><Skeleton style={{ width: 250 }} /></h5>
                                        <p><Skeleton style={{ width: 100 }} /></p>
                                    </div>
                                    <div className="Inclusions">
                                        <h5 style={{ color: "black" }}><Skeleton style={{ width: 650 }} /></h5>
                                        <ul>
                                            <li><Skeleton style={{ width: 650 }} /></li>
                                            <li><Skeleton style={{ width: 650 }} /></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="Important-Info">
                                    <div style={{ backgroundColor: "linear-gradient(90deg, #ff7f3f, #ff3e5e)" }} className="Important-Header">
                                        <h6 style={{ color: "white", fontWeight: "bold" }}><Skeleton style={{ width: 150 }} /></h6>
                                    </div>
                                    <div className="rules">
                                        <h6 style={{ color: "black" }}><Skeleton style={{ width: 650 }} /></h6>
                                        <ul>
                                            <li style={{ color: "black" }}><Skeleton style={{ width: 650 }} /></li>
                                            <li style={{ color: "black" }}><Skeleton style={{ width: 650 }} /></li>
                                            <li style={{ color: "black" }}><Skeleton style={{ width: 450 }} /></li>
                                            <li style={{ color: "black" }}><Skeleton style={{ width: 450 }} /></li>
                                        </ul>
                                        <p style={{ color: "blue" }}><Skeleton style={{ width: 450 }} /></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="pricing-details">
                        <div className="price-breakup">
                            <div className="price-header">
                                <h6 style={{ color: "black" }}><Skeleton style={{ width: 150 }} /></h6>

                            </div>
                            <hr />
                            <div className="total-amount">
                                <div>
                                    <h6 style={{ color: "black" }}><Skeleton style={{ width: 150 }} /></h6>
                                    <p><Skeleton style={{ width: 150 }} /></p>
                                </div>
                                <div>
                                    <h6 style={{ color: "grey" }}><Skeleton style={{ width: 150 }} /></h6>
                                </div>
                            </div>
                            <hr />
                            <div className="discount">
                                <h6 style={{ color: "rgb(0, 161, 156)" }}><Skeleton style={{ width: 150 }} /></h6>
                                <h6 style={{ color: "rgb(0, 161, 156)" }}><Skeleton style={{ width: 150 }} /></h6>
                            </div>
                            <hr />
                            <div className="discounted-price">
                                <h6 style={{ color: "black" }}><Skeleton style={{ width: 150 }} /></h6>
                                <h6 style={{ color: "black" }}><Skeleton style={{ width: 150 }} /></h6>
                            </div>
                            <hr />
                            <div className="taxes">
                                <h6 style={{ color: "black" }}><Skeleton style={{ width: 150 }} /></h6>
                                <h6 style={{ color: "black" }}><Skeleton style={{ width: 150 }} /></h6>
                            </div>
                            <hr />
                            <div className="total-amount">
                                <h6 style={{ color: "black" }}><Skeleton style={{ width: 150 }} /></h6>
                                <h6 style={{ color: "red" }}><Skeleton style={{ width: 150 }} /></h6>
                            </div>
                        </div>
                        {/* <div className="Deal-Codes">
                            <h5 style={{ color: "black" }}><Skeleton style={{ width: 150 }} /></h5>
                            <div className="cpnCont ">
                            <Skeleton style={{ width: 300, height:30 }} />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentDetails;
