import React from 'react';
import './BookingPage.css'
import Skeleton from 'react-loading-skeleton';
function BookingPage(props) {


    return (
        <>
            <div className="container makeFlex spaceBetween">

                <div className="container makeFlex spaceBetween" style={{ padding: '50px' }}>
                    <div className="Container-TileBooking" style={{ marginTop: 90, height: 360, padding: 40 }}>

                        <div className="Detail-Hotel">
                            <div className="Hotel-Name" style={{display:'flex',justifyContent:'space-between'}}>
                                <div className="nameContainer" style={{width:600}}>

                                    <div className="paymakeFlex appendBottom5"><h3 className="latoBlack font22 blackText"><Skeleton style={{ width: 250 }} /></h3><span className="sRating" style={{ display: 'inline-flex', marginTop: '-4px', marginLeft: '10px' }}><Skeleton style={{ width: 80 , height:22}} /></span></div>
                                    <p><Skeleton style={{ width: 450 }} /></p>
                                    <h3 className="latoBlack font22 blackText" style={{marginTop:10, fontSize:18, fontWeight:'bold'}}><Skeleton style={{ width: 200 }} /></h3>

                                    <div class="chkContBooking">
                                        <div class="chkCont__col">
                                            <div class="paymakeFlex column">
                                                <span class="font10 grayText appendBottom3"><Skeleton style={{ width: 150 }} /></span><span class="latoBlack font24 blackText appendBottom3"><Skeleton style={{ width: 150 }} /></span><span class="font10 grayText 444"><Skeleton style={{ width: 150 }} /></span>
                                            </div>
                                            <div class="chkCont__night"><span><Skeleton style={{ width: 150 }} /></span></div>
                                            <div class="paymakeFlex column"><span class="font10 grayText appendBottom3"><Skeleton style={{ width: 150 }} /></span><span class="latoBlack font24 blackText appendBottom3"><Skeleton style={{ width: 150 }} /></span><span class="font10 grayText"><Skeleton style={{ width: 150 }} /></span></div>
                                        </div>
                                        <div class="chkCont__col"><p class="font16 blackText"><span class="latoBlack"><Skeleton style={{ width: 100 }} /></span><span class="latoBlack"><Skeleton style={{ width: 80 }} /></span>&nbsp;<Skeleton style={{ width: 80 }} />&nbsp;| <span><Skeleton style={{ width: 150 }} /></span></p></div>

                                    </div>
                                 
                                    <p class="errorLinkText latoBold font16 blueText"><Skeleton style={{ width: 200 }} /></p>
                                </div>
                                <div className="imageContainerBooking">
                                    <Skeleton style={{ width: '100%', height:280 }} />
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>


        </>
    )
}

export default BookingPage;
