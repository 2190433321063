import React, { Component } from 'react';
import "./Profile.css";
import NewHeader from "../NewHeader/NewHeader";
import { setCustomerLogin, getGeoInfo, getCustomerLogin } from '../axios';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import DialCode from '../PaymentDetails/DialCode';
import { Redirect } from 'react-router-dom';
import {Helmet} from "react-helmet";

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            plannedTrips: true,
            upcoming: false,
            cancelled: false,
            Completed: false,
            ChangeColor: 'reviewPlanned',
            customerDetails: {},
            editProfile: false,
            firstName: "",
            errorFirst: false,
            lastName: "",
            errorLast: false,
            birthday: '',
            errorBirthday: false,
            gender: "",
            errorGender: false,
            editLogin: false,
            mobile: '',
            password: '',
            errorMobile: false,
            errorPassword: false,
            hidden: false,
            changeClassForPassword: 'myPrfilSprit  txtFild__rightpasswordIcon myPrfilSprit__showPswrd',
            Redirect: false,
            ConfirmPassword: '',
            errorConfirm: false,
        };

    }

    EditProfile = (firstname, lastname, birthday, gender) => {
        console.log(birthday);
        this.setState({ editProfile: true });
        this.setState({ firstName: firstname });
        this.setState({ lastName: lastname });
        if (birthday != '0000-00-00') {
            this.setState({ birthday: new Date(birthday) });
        }

        console.log(gender)
        this.setState({ gender: gender });
    }
    CancelProfile = () => {
        this.setState({ editProfile: false });
    }

    ShowPassword = () => {
        this.setState({ hidden: true });
    }

    HidePassword = () => {
        this.setState({ hidden: false });
    }


    EditLogin = (mobile_number, password) => {
        console.log(password);
        this.setState({ editLogin: true });
        this.setState({ mobile: mobile_number });
    }
    CancelLogin = () => {
        this.setState({ editLogin: false });
    }

    // setFirstName = (event) => {
    //     if (event.target.value.length > 0) {
    //         this.setState({ firstName: event.target.value });
    //         this.setState({ errorFirst: false });
    //     } else {
    //         this.setState({ firstName: event.target.value });
    //         this.setState({ errorFirst: true });
    //     }

    // }

    // setLastName = (event) => {
    //     if (event.target.value.length > 0) {
    //         this.setState({ lastName: event.target.value });
    //         this.setState({ errorLast: false });
    //     } else {
    //         this.setState({ lastName: event.target.value });
    //         this.setState({ errorLast: true });
    //     }

    // }
    setFirstName = (event) => {
        let firstregMatch = /^[a-zA-Z ]*$/.test(event.target.value);
        if (event.target.value.length > 0 && firstregMatch && event.target.value.length < 20) {
            this.setState({ firstName: event.target.value });
            this.setState({ errorFirst: false });
        } else {
            this.setState({ firstName: event.target.value });
            this.setState({ errorFirst: true });
        }

    }

    setLastName = (event) => {
        let lastregMatch = /^[a-zA-Z ]*$/.test(event.target.value);
        if (event.target.value.length > 0 && lastregMatch && event.target.value.length < 20) {
            this.setState({ lastName: event.target.value });
            this.setState({ errorLast: false });
        } else {
            this.setState({ lastName: event.target.value });
            this.setState({ errorLast: true });
        }

    }

    setBirthday = (value) => {
        this.setState({ birthday: value });
    }

    setGender = (event) => {
        if (event.target.value != "Select") {
            this.setState({ gender: event.target.value });
            this.setState({ errorGender: false });
        } else {
            this.setState({ gender: event.target.value });
            this.setState({ errorGender: true });
        }

    }

    setMobile = (event) => {
        if (event.target.value.length > 0) {
            this.setState({ mobile: event.target.value });
            this.setState({ errorMobile: false });
        } else if (event.target.value.length > 15) {
            this.setState({ mobile: event.target.value });
            this.setState({ errorMobile: true });
        } else {
            this.setState({ mobile: event.target.value });
            this.setState({ errorMobile: true });
        }

    }

    setPassword = (event) => {
        if (event.target.value.length > 0) {
            this.setState({ password: event.target.value });
            this.setState({ errorPassword: false });
        } else {
            this.setState({ password: event.target.value });
            this.setState({ errorPassword: true });
        }

    }

    setConfPassword = (event) => {
        if (event.target.value.length > 0 && event.target.value == this.state.password) {
            this.setState({ ConfirmPassword: event.target.value });
            this.setState({ errorConfirm: false });
        } else {
            this.setState({ ConfirmPassword: event.target.value });
            this.setState({ errorConfirm: true });
        }

    }

    SaveData = (data) => {

        let CheckErrorFirstName = false;
        let CheckErrorLastName = false;
      
        let firstregMatch = /^[a-zA-Z ]*$/.test(this.state.firstName);
        if (this.state.firstName.length > 0 && firstregMatch && this.state.firstName.length < 20) {
       // if (this.state.firstName.length > 0) {
                CheckErrorFirstName = true;
            this.setState({ errorFirst: false });
        } else {
            CheckErrorFirstName = false;
            this.setState({ errorFirst: true });
        }
        let lastregMatch = /^[a-zA-Z ]*$/.test(this.state.lastName);
        if (this.state.lastName.length > 0 && lastregMatch  && this.state.lastName.length < 20) {
        //if (this.state.lastName.length > 0) {
            CheckErrorLastName = true;
            this.setState({ errorLast: false });
        } else {
            CheckErrorLastName = false;
            this.setState({ errorLast: true });
        }

        if (
            CheckErrorFirstName == true &&
            CheckErrorLastName == true 
          ) {
        let first_name = this.state.firstName;
        let last_name = this.state.lastName;
        let email = data.email;
        let profile_image = data.profile_pic;
        let birthday = moment(this.state.birthday).format("y-MM-DD");
        let gender = this.state.gender;
        let login_source = data.login_source;


        let mobile_number = data.mobile_number;
        let country = data.country;
        let country_code = data.country_code;
        let currency = data.currency;
        let ip_address = data.ip_address;
        let password = "";
        let verified = 1;

        setCustomerLogin(first_name, last_name, email, profile_image, birthday, gender, login_source, mobile_number, country, country_code, currency, ip_address, password, verified).then(
            (data) => {
                console.log(data);
                if (data.id > 0) {
                    window.location.reload(true);
                }
            },
            (error) => {

            }
        );
          }
    }

    SaveDataLogin = (data) => {
       
        let CheckErrorPassword = false;
        let CheckErrorConfirmPassword = false;
        let CheckErrorMobile = false;

        if (this.state.mobile.length > 5) {
                CheckErrorMobile = true;
            this.setState({ errorMobile: false });
        } else if (this.state.mobile.length > 15) {
                CheckErrorMobile = true;
            this.setState({ errorMobile: false });
        } else {
            CheckErrorMobile = false;
            this.setState({ errorMobile: true });
        }

        if (this.state.password.length > 0) {
             CheckErrorPassword = true;
            this.setState({ errorPassword: false });
        } else {
            CheckErrorPassword = false;
            this.setState({ errorPassword: true });
        }
       
         
        if (this.state.ConfirmPassword.length > 0 && this.state.ConfirmPassword == this.state.password) {
            CheckErrorConfirmPassword = true;
            this.setState({ errorConfirm: false });
        } else {
            CheckErrorConfirmPassword = false;
            this.setState({ errorConfirm: true });
        }
        
        if (
            CheckErrorPassword == true &&
            CheckErrorConfirmPassword == true &&
            CheckErrorMobile == true
          ) {
        
          let mobileCodeData = data.country_code;
         if(this.state.MobileCode != ""){
            mobileCodeData = this.state.MobileCode;
         }  
        
        let first_name = data.first_name;
        let last_name = data.last_name;
        let email = data.email;
        let profile_image = data.profile_pic;
        let birthday = moment(data.birthday).format("y-MM-DD");
        let gender = data.gender;
        let login_source = data.login_source;


        let mobile_number = this.state.mobile;
        let country = data.country;
        let country_code = data.country_code;
        let currency = data.currency;
        let ip_address = data.ip_address;
        let password = this.state.password;
        let verified = 1;

        setCustomerLogin(first_name, last_name, email, profile_image, birthday, gender, login_source, mobile_number, country, country_code, currency, ip_address, password, verified).then(
            (data) => {
                console.log(data);
                if (data.id > 0) {
                    window.location.reload(true);
                }
            },
            (error) => {

            }
        );
        }
    }

    componentDidMount() {
        const customerEmail = localStorage.getItem('userDetails');

        const EmailNew = JSON.parse(customerEmail);

        if (EmailNew == null) {
            console.log('rohan');
            this.setState({ Redirect: true });
        } else {
            console.log('ram');
            getCustomerLogin(EmailNew.email).then(
                (data) => {
                    console.log(data);
                    this.setState({ customerDetails: data });
                },
                (error) => {

                });
        }


    }



    render() {
        console.log(this.state.mobile);
        if (this.state.Redirect) {
            return <Redirect to="/" />
        }
        return (
            <div>
                 <Helmet>
                <title> Profile: Holidays Seychelles</title>
                <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
                </Helmet>
                <h1 style={{}}> Profile: Holidays Seychelles</h1>
                <NewHeader page={'inner_listing'} />
                <div className="TripContainer" id="container">
                    <div className={this.state.ChangeColor} style={{ marginTop: '-5px' }}>
                        <div className="landingContainer"><h4>My Profile</h4>
                        </div>
                    </div>

                    {this.state.customerDetails == {} ? (
                        <></>
                    ) : (
                        <div className="contentWrapper contentWrapper--myProfile">
                            <div className="leftSecWrapper">
                                <div className="profilSdeBar profilSdeBar--sticky">
                                    <div className={this.state.customerDetails.login_source == 1 ? "proflPicContanr" : "proflPicContanr proflPicContanrNew"}>
                                        {this.state.customerDetails.profile_pic == "" ? (
                                            <div className="proflPicContanr__nmeIniContanr">
                                                <span className="latoBlack whiteText proflPicContanr__nmeIni">{this.state.customerDetails.first_name.charAt(0)}</span>
                                            </div>
                                        ) : (
                                            <img src={this.state.customerDetails.profile_pic} />
                                        )}

                                    </div>
                                    <div className="profilSdeBar__contntWrap">
                                        <p className="font20 latoBlack blackText" style={{ textTransform: 'capitalize' }}>{this.state.customerDetails.first_name + ' ' + this.state.customerDetails.last_name}</p>
                                        <p className="font12 darkGreyText appendTop10">PERSONAL PROFILE</p>
                                    </div>
                                </div>
                            </div>
                            <div className="rightSecWrapper">
                                <div className="proflCmpltonStats cm__myProfileTheme">
                                    <div className="darkGreyText proflCmpltonStats__prcntagSec">

                                        {this.state.customerDetails.first_name && this.state.customerDetails.last_name && this.state.customerDetails.birthday && this.state.customerDetails.gender && this.state.customerDetails.mobile_number && this.state.customerDetails.password ? (
                                            <>
                                                <p className="latoBold ProfilemakeFlex ProfilespaceBetween">
                                                    <span>Your profile is complete</span>
                                                    <span className="latoBlack blackText">100%</span>
                                                </p>
                                                <div className="proflCmpltonStats__progrsBar">
                                                    <div className="proflCmpltonStats__progrsBarFill" style={{ width: '100%' }}></div>
                                                </div>
                                            </>

                                        ) : (
                                            this.state.customerDetails.first_name && this.state.customerDetails.last_name && this.state.customerDetails.birthday && this.state.customerDetails.gender ? (
                                                <>
                                                    <p className="latoBold ProfilemakeFlex ProfilespaceBetween">
                                                        <span>Your profile is complete</span>
                                                        <span className="latoBlack blackText">70%</span>
                                                    </p>
                                                    <div className="proflCmpltonStats__progrsBar">
                                                        <div className="proflCmpltonStats__progrsBarFill" style={{ width: '70%' }}></div>
                                                    </div>
                                                </>
                                            ) : (
                                                this.state.customerDetails.mobile_number ? (
                                                    <>
                                                        <p className="latoBold ProfilemakeFlex ProfilespaceBetween">
                                                            <span>Your profile is complete</span>
                                                            <span className="latoBlack blackText">60%</span>
                                                        </p>
                                                        <div className="proflCmpltonStats__progrsBar">
                                                            <div className="proflCmpltonStats__progrsBarFill" style={{ width: '60%' }}></div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className="latoBold ProfilemakeFlex ProfilespaceBetween">
                                                            <span>Your profile is complete</span>
                                                            <span className="latoBlack blackText">30%</span>
                                                        </p>
                                                        <div className="proflCmpltonStats__progrsBar">
                                                            <div className="proflCmpltonStats__progrsBarFill" style={{ width: '30%' }}></div>
                                                        </div>
                                                    </>
                                                )
                                            )

                                        )}


                                    </div>
                                    {this.state.customerDetails.first_name && this.state.customerDetails.last_name && this.state.customerDetails.birthday && this.state.customerDetails.gender && this.state.customerDetails.mobile_number && this.state.customerDetails.password ? (
                                        <p className="darkGreyText appendTop10">Congrats! Your profile details is up to date!</p>
                                    ) : (
                                        <> </>
                                    )}

                                    <ul className="font16 latoBold proflCmpltonStats__stpsStatusLst">
                                        <li className="proflCmpltonStats__stpsStatusItm ">
                                            <span className="myPrfilSprit appendRight10 myPrfilSprit__verifiedBig">

                                            </span>Verified Email ID</li>
                                        {this.state.customerDetails.mobile_number ? (
                                            <li className="proflCmpltonStats__stpsStatusItm ">
                                                <span className="myPrfilSprit appendRight10 myPrfilSprit__verifiedBig"></span>Verified mobile Number
                                            </li>
                                        ) : (
                                            <li className="proflCmpltonStats__stpsStatusItm ">
                                                <span className="myPrfilSprit appendRight10 myPrfilSprit__notverifiedBig"></span>Add Mobile Number
                                            </li>
                                        )}

                                        {this.state.customerDetails.first_name && this.state.customerDetails.last_name && this.state.customerDetails.birthday && this.state.customerDetails.gender ? (
                                            <li className="proflCmpltonStats__stpsStatusItm "><span className="myPrfilSprit appendRight10 myPrfilSprit__verifiedBig"></span>Complete Basic Info</li>
                                        ) : (
                                            <li className="proflCmpltonStats__stpsStatusItm "><span className="myPrfilSprit appendRight10 myPrfilSprit__notverifiedBig"></span>Add Basic Info</li>
                                        )}


                                    </ul>
                                </div>

                                <div className="profilCompont cm__myTripSideBar cm__myProfileTheme" id="profilePageComponent0"><div className="detalCrdHadr undefined"><div className="appendRight10"><h3 className="font30 latoBold blackText">Profile</h3><p className="font18 darkGreyText appendTop10">Basic info, for a faster booking experience</p></div><div>

                                    {this.state.editProfile == true ? (
                                        <button type="button" className="font16 latoBold blueText btn__dtailAdEdt" style={{ color: 'rgb(24 133 203)', outline: 0 }} onClick={this.CancelProfile}>
                                            Cancel</button>
                                    ) : (
                                        <button type="button" className="font16 latoBold blueText btn__dtailAdEdt"
                                            onClick={() => this.EditProfile(this.state.customerDetails.first_name, this.state.customerDetails.last_name, this.state.customerDetails.birthday, this.state.customerDetails.gender)} style={{ color: 'rgb(24 133 203)', outline: 0 }} >
                                            Edit</button>
                                    )}
                                </div></div>

                                    {this.state.editProfile == true ? (
                                        <>
                                            <div className="paymakeFlex" style={{ marginTop: 55 }}>
                                                <div className="paymakeFlex column flexOne">
                                                    <div className="paymakeFlex">
                                                        <div className="guestDtls__col width247 appendRight10" style={{ width: 300 }}>
                                                            <div className="textFieldCol ">
                                                                <p className="font11 appendBottom10 guestDtlsTextLbl">
                                                                    <span className="capText">First Name</span></p>
                                                                <input type="text" className={`frmTextInput ${this.state.errorFirst ? "Err" : ""}`} placeholder="First Name" value={this.state.firstName} onChange={this.setFirstName} />
                                                                {this.state.errorFirst && <p className="error">Please enter your first name in allphabet min 1 or max 20 characters </p>}
                                                            </div>
                                                        </div>
                                                        <div className="guestDtls__col width247" style={{ width: 300 }} >
                                                            <div className="textFieldCol ">
                                                                <p className="font11 appendBottom10 guestDtlsTextLbl">
                                                                    <span className="capText">Last Name</span></p>
                                                                <input type="text" id="lName" name="lName" className={`frmTextInput ${this.state.errorLast ? "Err" : ""}`}  placeholder="Last Name" value={this.state.lastName} onChange={this.setLastName} />
                                                                {this.state.errorLast && <p className="error">Please enter your last name in allphabet min 1 or max 20 characters </p>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="paymakeFlex" >
                                                <div className="paymakeFlex column flexOne">
                                                    <div className="paymakeFlex">
                                                        <div className="guestDtls__col width247 appendRight10" style={{ width: 300 }}>
                                                            <div className="textFieldCol ">
                                                                <p className="font11 appendBottom10 guestDtlsTextLbl">
                                                                    <span className="capText">Birthday</span></p>
                                                                <DatePicker value={this.state.birthday} onChange={(value) => { this.setBirthday(value) }} className="frmTextInput " maxDate={new Date()} format="dd-MM-y" />
                                                            </div>
                                                        </div>
                                                        <div className="guestDtls__col width247" style={{ width: 300 }} >
                                                            <div className="textFieldCol ">
                                                                <p className="font11 capText appendBottom10">Gender</p>
                                                                <div className="frmSelectCont">
                                                                    <select id="title" className={`frmSelect ${this.state.errorGender ? "Err" : ""}`} value={this.state.gender} onChange={this.setGender}>
                                                                        <option value="Select">---</option>
                                                                        <option value="Male">Male</option>
                                                                        <option value="Female">Female</option>
                                                                        <option value="Other">Other</option>
                                                                    </select>
                                                                    {this.state.errorGender && <p className="error">Please select your Gender </p>}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>




                                            </div>
                                            <button type="button" className="font16 latoBold blueText btn__dtailAdSave" onClick={() => this.SaveData(this.state.customerDetails)}>
                                                Save</button></>
                                    ) : (
                                        <ul className="profilCompont__dtalLst"><li className="profilCompont__dtalLstItm"><div className="profilCompont__dtalLstItmContnt"><span className="font11 darkGreyText profilCompont__detalkey">FIRST NAME</span><span className="font16 blackText latoBold" style={{ textTransform: 'capitalize' }}>{this.state.customerDetails.first_name}</span></div></li>
                                            <li className="profilCompont__dtalLstItm"><div className="profilCompont__dtalLstItmContnt"><span className="font11 darkGreyText profilCompont__detalkey">LAST NAME</span><span className="font16 blackText latoBold" style={{ textTransform: 'capitalize' }}>{this.state.customerDetails.last_name}</span></div></li><li className="profilCompont__dtalLstItm"><div className="profilCompont__dtalLstItmContnt"><span className="font11 darkGreyText profilCompont__detalkey">BIRTHDAY</span><span className="font16 blackText latoBold">{this.state.customerDetails.birthday == "0000-00-00" ? '---' : moment(this.state.customerDetails.birthday).format("MMMM, Do YYYY")}</span></div></li><li className="profilCompont__dtalLstItm"><div className="profilCompont__dtalLstItmContnt"><span className="font11 darkGreyText profilCompont__detalkey">GENDER</span><span className="font16 blackText latoBold" style={{ textTransform: 'capitalize' }}>{this.state.customerDetails.gender == "" ? "---" : this.state.customerDetails.gender}</span></div></li></ul>
                                    )}




                                </div>
                                <div className="lognDtalComp cm__myTripSideBar cm__myProfileTheme" id="profilePageComponent1"><div className="detalCrdHadr undefined">
                                    <div className="appendRight10"><h3 className="font30 latoBold blackText">Login Details</h3>
                                        <p className="font18 darkGreyText appendTop10">Manage your email address, mobile number and password</p></div>
                                    <div>

                                        {this.state.editLogin == true ? (
                                            <button type="button" className="font16 latoBold blueText btn__dtailAdEdt" style={{ color: 'rgb(24 133 203)', outline: 0 }} onClick={this.CancelLogin}>
                                                Cancel</button>
                                        ) : (
                                            <button type="button" className="font16 latoBold blueText btn__dtailAdEdt"
                                                onClick={() => this.EditLogin(this.state.customerDetails.mobile_number, this.state.customerDetails.password)} style={{ color: 'rgb(24 133 203)', outline: 0 }} >
                                                Edit</button>
                                        )}
                                    </div>
                                </div>
                                    {this.state.editLogin == true ? (
                                        <>
                                            <div className="guestDtls__row" style={{ marginTop: 40 }}>
                                                <div className="paymakeFlex">
                                                    <div className="guestDtls__col width327 appendRight10">
                                                        <div className="textFieldCol " style={{ position: 'relative' }}>
                                                            <p className="font11 appendBottom10 guestDtlsTextLbl">
                                                                <span className="capText">New Password</span>
                                                            </p>
                                                            <input type={this.state.hidden ? 'text' : 'password'} className={`frmTextInput ${this.state.errorPassword ? "Err" : ""}`} placeholder="New Password" value={this.state.password} onChange={this.setPassword} />
                                                            {this.state.hidden == true ? (
                                                                <span className="myPrfilSprit  txtFild__rightpasswordIcon myPrfilSprit__hidePswrd" onClick={this.HidePassword}></span>
                                                            ) : (
                                                                <span className="myPrfilSprit  txtFild__rightpasswordIcon myPrfilSprit__showPswrd" onClick={this.ShowPassword}></span>
                                                            )}
                                                            {this.state.errorPassword && <p className="error">Please enter your password </p>}

                                                        </div>
                                                    </div>
                                                    <div className="guestDtls__col width327 appendRight10">
                                                        <div className="textFieldCol " style={{ position: 'relative' }}>
                                                            <p className="font11 appendBottom10 guestDtlsTextLbl">
                                                                <span className="capText">Confirm Password</span>
                                                            </p>
                                                            <input type={this.state.hidden ? 'text' : 'password'} className={`frmTextInput ${this.state.errorConfirm ? "Err" : ""}`} placeholder="Confirm Password" value={this.state.ConfirmPassword} onChange={this.setConfPassword} />
                                                            {this.state.hidden == true ? (
                                                                <span className="myPrfilSprit  txtFild__rightpasswordIcon myPrfilSprit__hidePswrd" onClick={this.HidePassword}></span>
                                                            ) : (
                                                                <span className="myPrfilSprit  txtFild__rightpasswordIcon myPrfilSprit__showPswrd" onClick={this.ShowPassword}></span>
                                                            )}
                                                            {this.state.errorConfirm && <p className="error">Password not match! </p>}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="paymakeFlex" style={{marginTop:20}}>
                                                    <div className="guestDtls__col width327">
                                                        <p className="font11 capText appendBottom10">Mobile Number</p>
                                                        <div className="paymakeFlex textLtr">
                                                            <div className="guestDtls__contact">
                                                                <label for="mCode" className=" frmSelectCont__contact">
                                                                    <select id="mCode" className="frmSelect frmSelectContact" disabled>
                                                                        {DialCode.map((item, key) => (
                                                                            <option value={item.dial_code}>{item.name}&nbsp;({item.dial_code})</option>
                                                                        ))}
                                                                    </select>
                                                                </label><span className="selectedCode" >{this.state.customerDetails.country_code}</span></div>
                                                            <div className="flexOne">

                                                                <div className="textFieldCol ">
                                                                    <input type="text" id="mNo" name="mNo" className={`frmTextInput noLeftBorder ${this.state.errorMobile ? "Err" : ""}`} placeholder="Mobile Number" value={this.state.mobile} onChange={this.setMobile} />
                                                                    {this.state.errorMobile && <p className="error">Please enter valid number </p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <button type="button" className="font16 latoBold blueText btn__dtailAdSave" onClick={() => this.SaveDataLogin(this.state.customerDetails)}>
                                                Save</button>
                                        </>
                                    ) : (
                                        <ul className="lognDtalComp__dtalLst">
                                            <li className="lognDtalComp__dtalLstItm">
                                                <div className="lognDtalComp__dtalLstItmContnt">
                                                    <span className="font11 darkGreyText lognDtalComp__detalkey">MOBILE NUMBER</span>
                                                    <span className="font16 blackText latoBold">&lrm;{
                                                        this.state.customerDetails.mobile_number ? this.state.customerDetails.country_code + '-' + this.state.customerDetails.mobile_number : "----"}</span>
                                                </div>
                                            </li>
                                            <li className="lognDtalComp__dtalLstItm">
                                                <div className="lognDtalComp__dtalLstItmContnt">
                                                    <span className="font11 darkGreyText lognDtalComp__detalkey">EMAIL ID</span>
                                                    <span className="font16 blackText latoBold">&lrm;{this.state.customerDetails.email}</span>
                                                </div>
                                            </li>
                                            <li className="lognDtalComp__dtalLstItm">
                                                <div className="lognDtalComp__dtalLstItmContnt">
                                                    <span className="font11 darkGreyText lognDtalComp__detalkey">PASSWORD</span>
                                                        <span className="blacktext latoBold lognDtalComp__pswrdTxt" style={{ WebkitTextSecurity: 'disc' }}>123456</span>
                                                   

                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}


                </div>
            </div>
        )
    }
}

export default Profile;
