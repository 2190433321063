import React, { useEffect } from "react";
import "./styles.css";
import NewHeader from "../Component/NewHeader/NewHeader";
import Footer from "../Component/Footer";
import {Helmet} from "react-helmet";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="StaticMainComponent">
      <div className="MainStaticMainComponentContaint">
      <Helmet>
      <title>Privacy Policy : Holidays Seychelles</title>
      <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
    </Helmet>
    <h1 style={{}}> Privacy Policy : Holidays Seychelles</h1>
        <NewHeader page={"inner_listing"} />
        <div
          className="StaticlandingCard appendBottom20 StaticSection page-section"
          style={{ marginTop: 150 }}
        >
          <div className="StaticContainer" id="container">
            <div class="row">
              <div className="aboutPage">
                <h1 className="aboutHeading">Privacy Statement</h1>

                <table>
                  <tr>
                    <th>Last updated</th>
                    <th>May 25 2021</th>
                  </tr>
                </table>

                <h3>Definitions</h3>

                <table style={{ marginTop: 10, marginBottom: 30 }}>
                  <tr>
                    <th>Holidays (Seychelles)</th>
                    <th>
                      Holidays (Seychelles) is the Internet trading name for
                      ESPACE Seychelles Ltd.
                    </th>
                  </tr>
                  <tr>
                    <th>GDPR</th>
                    <th>means the General Data Protection Regulation.</th>
                  </tr>
                  <tr>
                    <th>Responsible Person</th>
                    <th>means Mr Pascal Esparon Director</th>
                  </tr>
                  <tr>
                    <th>Register of Systems</th>
                    <th>
                      means a register of all systems or contexts in which
                      personal data is processedby Holidays (Seychelles)
                    </th>
                  </tr>
                </table>

                <h3>1. Introduction</h3>
                <p className="text">
                  1.1 We are committed to safeguarding the privacy of our
                  website visitors; in this policy we explain how we will treat
                  your personal information.
                </p>
                <p className="text">
                  1.2 We will ask you to consent to our use of cookies in
                  accordance with the terms of this policy when you first visit
                  our website. / By using our website and agreeing to this
                  policy, you consent to our use of cookies in accordance with
                  the terms of this policy.
                </p>

                <h3>2. Credit</h3>
                <p className="text">
                  2.1 This document was created using a template from SEQ Legal
                  (http://www.seqlegal.com).
                </p>

                <h3>3. Collecting personal information</h3>
                <p className="text">
                  3.1 We may collect, store and use the following kinds of
                  personal information:(a) information about your computer and
                  about your visits to and use of this website (including your
                  IP address, geographical location, browser type and version,
                  operating system, referral source, length of visit, page views
                  and website navigation paths);(b) information that you provide
                  to us when registering with our website (including your email
                  address);](c) information that you provide when completing
                  your profile on our website (including yourname, pictures,
                  gender and date of birth) (d) information that you provide to
                  us for the purpose of subscribing to our email
                  notificationsand/or newsletters (including your name and email
                  address)(e) information that you provide to us when using the
                  services on our website, or that is generated in the course of
                  the use of those services (including the timing, frequency and
                  pattern of service use)(f) information relating to any
                  purchases you make of our goods and/or services or any other
                  transactions that you enter into through our website
                  (including your name, address, telephone number, email address
                  and card details)(g) information that you post to our website
                  for publication on the internet (including your user name,
                  your profile pictures and the content of your posts)(h)
                  information contained in or relating to any communication that
                  you send to us or send through our website (including the
                  communication content and metadata associated with the
                  communication)
                </p>
                <p className="text">
                  3.2 Before you disclose to us the personal information of
                  another person, you must obtain that person's consent to both
                  the disclosure and the processing of that personal information
                  in accordance with this policy.
                </p>

                <h3>4. Using personal information</h3>
                <p className="text">
                  4.1 Personal information submitted to us through our website
                  will be used for the purposes specified in this policy or on
                  the relevant pages of the website.
                </p>
                <p className="text">
                  4.2 We may use your personal information to:(a) administer our
                  website and business;(b) personalise our website for you;(c)
                  enable your use of the services available on our website;(d)
                  supply to you, services purchased through our website;(e) send
                  statements, invoices and payment reminders to you, and collect
                  payments from you;(f) send you email notifications that you
                  have specifically requested;(g) send you our email newsletter,
                  if you have requested it (you can inform us at any time if you
                  no longer require the newsletter); (h) send you marketing
                  communications relating to our business which we think may be
                  of interest to you, by post or, where you have specifically
                  agreed to this, by email or similar technology (you can inform
                  us at any time if you no longer require marketing
                  communications);(i) deal with enquiries and complaints made by
                  or about you relating to our website;(j) keep our website
                  secure and prevent fraud;(k) verify compliance with the terms
                  and conditions governing the use of our website (including
                  monitoring private messages sent through our website private
                  messaging service)
                </p>

                <p className="text">
                  4.3 If you submit personal information for publication on our
                  website, we will publish and otherwise use that information in
                  accordance with the licence you grant to us.
                </p>

                <p className="text">
                  4.4 Your privacy settings can be used to limit the publication
                  of your information on our website, and can be adjusted using
                  privacy controls on the website.
                </p>
                <p className="text">
                  4.5 We will not, without your express consent, supply your
                  personal information to any third party for the purpose of
                  their or any other third party's direct marketing.
                </p>

                <p className="text">
                  4.6 All our website financial transactions are handled through
                  our payment services providers. You can review the provider's
                  respective privacy policies on their websites. We will share
                  information with our payment services providers only to the
                  extent necessary for the purposes of processing payments you
                  make via our website, refunding such payments and dealing with
                  complaints and queries relating to such payments and refunds.
                </p>

                <h3>5. Disclosing personal information</h3>
                <p className="text">
                  5.1 We may disclose your personal information to any of our
                  employees, officers, insurers or professional advisers,
                  insofar as reasonably necessary for the purposes set out in
                  this policy.
                </p>
                <p className="text">
                  5.2 We may disclose your personal information:(a) to the
                  extent that we are required to do so by law;(b) in connection
                  with any ongoing or prospective legal proceedings;(c) in order
                  to establish, exercise or defend our legal rights (including
                  providing information to others for the purposes of fraud
                  prevention and reducing credit risk);(d) to any person who we
                  reasonably believe may apply to a court or other competent
                  authority for disclosure of that personal information where,
                  in our reasonable opinion, such courtor authority would be
                  reasonably likely to order disclosure of that personal
                  information.
                </p>

                <p className="text">
                  5.3 Except as provided in this policy, we will not provide
                  your personal information to third parties.
                </p>

                <h3>6. International data transfers</h3>
                <p className="text">
                  6.1 Information that we collect may be stored and processed in
                  and transferred between any ofthe countries in which we
                  operate in order to enable us to use the information in
                  accordance with this policy.
                </p>
                <p className="text">
                  6.2 Personal information that you publish on our website or
                  submit for publication on our website may be available, via
                  the internet, around the world. We cannot prevent the use or
                  misuse of such information by others.
                </p>

                <p className="text">
                  6.3 You expressly agree to the transfers of personal
                  information described in this Section 6.
                </p>

                <h3>7. Retaining personal information</h3>
                <p className="text">
                  7.1 This Section 7 sets out our data retention policies and
                  procedure, which are designed to help ensure that we comply
                  with our legal obligations in relation to the retention and
                  deletion of personal information.
                </p>
                <p className="text">
                  7.2 Personal information that we process for any purpose or
                  purposes shall not be kept for longer than is necessary for
                  that purpose or those purposes.
                </p>

                <p className="text">
                  7.3 Notwithstanding the other provisions of this Section 7, we
                  will retain documents (including electronic documents)
                  containing personal data:(a) to the extent that we are
                  required to do so by law;(b) if we believe that the documents
                  may be relevant to any ongoing or prospective legal
                  proceedings; and(c) in order to establish, exercise or defend
                  our legal rights (including providing information to others
                  for the purposes of fraud prevention and reducing credit
                  risk).
                </p>

                <h3>8. Security of personal information</h3>
                <p className="text">
                  8.1 We will take reasonable technical and organisational
                  precautions to prevent the loss, misuse or alteration of your
                  personal information.
                </p>
                <p className="text">
                  8.2 We will store all the personal information you provide on
                  our secure (password- and firewall-protected) servers.
                </p>

                <p className="text">
                  8.3 All electronic financial transactions entered into through
                  our website will be protected by encryption technology.
                </p>

                <p className="text">
                  8.4 You acknowledge that the transmission of information over
                  the internet is inherently insecure, and we cannot guarantee
                  the security of data sent over the internet.
                </p>

                <p className="text">
                  8.5 You are responsible for keeping the password you use for
                  accessing our website confidential; we will not ask you for
                  your password (except when you log in to our website).
                </p>

                <h3>9. Amendments</h3>
                <p className="text">
                  9.1 We may update this policy from time to time by publishing
                  a new version on our website.
                </p>
                <p className="text">
                  9.2 You should check this page occasionally to ensure you are
                  happy with any changes to this policy.
                </p>

                <p className="text">
                  9.3 We may notify you of changes to this policy by email.
                </p>

                <h3>10. Your rights</h3>
                <p className="text">
                  10.1 You may instruct us to provide you with any personal
                  information we hold about you; provision of such information
                  will be subject to:(a) the supply of appropriate evidence of
                  your identity (for this purpose, we will usually accept a
                  photocopy of your passport certified by a solicitor).
                </p>
                <p className="text">
                  10.2 We may withhold personal information that you request to
                  the extent permitted by law.
                </p>
                <p className="text">
                  10.3 You may instruct us at any time not to process your
                  personal information for marketing purposes.
                </p>

                <p className="text">
                  10.4 In practice, you will usually either expressly agree in
                  advance to our use of your personal information for marketing
                  purposes, or we will provide you with an opportunity to opt
                  out of the use of your personal information for marketing
                  purposes.
                </p>

                <h3>11. Third party websites</h3>
                <p className="text">
                  11.1 Our website includes hyperlinks to, and details of, third
                  party websites.
                </p>
                <p className="text">
                  11.2 We have no control over, and are not responsible for, the
                  privacy policies and practices of third parties.
                </p>

                <h3>12. Updating information</h3>
                <p className="text">
                  12.1 Please let us know if the personal information that we
                  hold about you needs to be corrected or updated.
                </p>

                <h3>13. Cookies</h3>
                <p className="text">13.1 Our website uses cookies.</p>

                <p className="text">
                  13.2 A cookie is a file containing an identifier (a string of
                  letters and numbers) that is sent by a web server to a web
                  browser and is stored by the browser. The identifier is then
                  sent back to the server each time the browser requests a page
                  from the server.
                </p>

                <p className="text">
                  13.3 Cookies may be either "persistent" cookies or "session"
                  cookies: a persistent cookie will be stored by a web browser
                  and will remain valid until its set expiry date, unless
                  deleted by the user before the expiry date; a session cookie,
                  on the other hand, will expire at the end of the user session,
                  when the web browser is closed.
                </p>

                <p className="text">
                  13.4 Cookies do not typically contain any information that
                  personally identifies a user, but personal information that we
                  store about you may be linked to the information stored in and
                  obtained from cookies.
                </p>

                <p className="text">
                  13.5 We use both session and persistent cookies on our
                  website.
                </p>

                <p className="text">
                  13.6 The names of the cookies that we use on our website, and
                  the purposes for which they are used, are set out below:(a) we
                  use cookies on the folder cookies on our website to recognise
                  a computer when a user visits the website, track users as they
                  navigate the website, enable the use of a shopping cart on the
                  website, improve the website's usability, analyse the use of
                  the website, administer the website, prevent fraud and improve
                  the security of the website and personalise the website for
                  each user
                </p>

                <p className="text">
                  13.7 Most browsers allow you to refuse to accept cookies; for
                  example:(a) in Internet Explorer (version 11) you can block
                  cookies using the cookie handling overridesettings available
                  by clicking "Tools", "Internet Options", "Privacy" and then
                  "Advanced";(b) in Firefox (version 36) you can block all
                  cookies by clicking "Tools", "Options", "Privacy", selecting
                  "Use custom settings for history" from the drop-down menu, and
                  unticking "Accept cookies from sites"; and(c) in Chrome
                  (version 41), you can block all cookies by accessing the
                  "Customise and control" menu, and clicking "Settings", "Show
                  advanced settings" and "Content settings", and then selecting
                  "Block sites from setting any data" under the "Cookies"
                  heading.
                </p>

                <p className="text">
                  13.8 Blocking all cookies will have a negative impact upon the
                  usability of many websites.
                </p>

                <p className="text">
                  13.9 If you block cookies, you will not be able to use all the
                  features on our website.
                </p>

                <p className="text">
                  13.10 You can delete cookies already stored on your computer;
                  for example:(a) in Internet Explorer (version 11), you must
                  manually delete cookie files (you can find instructions for
                  doing so at
                  http://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11);
                  (b) in Firefox (version 36), you can delete cookies by
                  clicking "Tools", "Options" and "Privacy", then selecting "Use
                  custom settings for history" from the drop-down menu, clicking
                  "Show Cookies", and then clicking "Remove All Cookies"; and(c)
                  in Chrome (version 41), you can delete all cookies by
                  accessing the "Customise and control" menu, and clicking
                  "Settings", "Show advanced settings" and "Clear browsing
                  data", and then selecting "Cookies and other site and plug-in
                  data" before clicking "Clear browsing data".
                </p>

                <p className="text">
                  13.11 Deleting cookies will have a negative impact on the
                  usability of many websites.
                </p>

                <h3>14. Our details</h3>
                <p className="text">
                  14.1 This website is owned and operated by Holidays
                  (Seychelles)
                </p>

                <p className="text">
                  14.2 We are registered in the Seychelles under the Seychelles
                  TIN No. 761569712, and our registered office is at Aux Cap,
                  Mahe, Seychelles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer showNews={"noNews"} />
    </div>
  );
};

export default PrivacyPolicy;
