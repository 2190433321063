import "./Accomadation.css";
import React from 'react';
import Header from "../../NewHeader/NewHeader";
import Footer from "../../Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import ModalData from "./AccomadationModal/AccomadationModal.jsx";



// Modal.setAppElement('Accomadation');


function Accomadation() {



    return (
        <div>
            <Header />


            

            <div className="duration-Container parallax">

                <Link to="/transportation"><button id="GoForward">Move To
            Transportation <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                <h3 style={{ color: "white" }}>Choose YOUR Accomadation</h3>
                <h6 style={{ color: "white" }}>Start Date: <span style={{ color: "#deb956" }}>21 Dec 2021</span> - End Date: <span style={{ color: "#deb956" }}>25 Dec 2021</span></h6>
                <div className="detailsDuration">
                    <div >
                        <div style={{ display: "flex" }}><FontAwesomeIcon style={{ height: "50px", width: "50px" }} icon={faMapMarker} /><h3 style={{ color: "black" }}>Mahe / Seychelles</h3></div>
                        <div className="add-accomadation"><p style={{ marginLeft: "5px" }}>21 Dec 2021 - 23 Dec 2021 | 2 nights</p>
                            <button className="add" data-toggle="modal" data-target=".bd-example-modal-xl">Add Accomadation</button>
                        </div>
                    </div>
                </div>
                <div className="detailsDuration">
                    <div >
                        <div style={{ display: "flex" }}><FontAwesomeIcon style={{ height: "50px", width: "50px" }} icon={faMapMarker} /><h3 style={{ color: "black" }}>Praslin / Seychelles</h3></div>
                        <div className="add-accomadation"><p style={{ marginLeft: "5px" }}>23 Dec 2021 - 25 Dec 2021 | 2 nights</p>
                            <button className="add"data-toggle="modal" data-target=".bd-example-modal-xl" >Add Accomadation</button>
                        </div>
                    </div>

                </div>
                <Link to="/citiesDuration"><button id="GoBack"><FontAwesomeIcon icon={faArrowLeft} />Move to Cities/Dates</button></Link>
            </div>


            <div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                    <ModalData />
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Accomadation;
