import React, { Component } from 'react';

const AnyReactComponent = ({ image, allData, title, lat, lng }) => <div

    style={{ height: 255, width: 240, marginTop: 5, justifyContent: 'center', borderRadius: 10, boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)', backgroundColor: '#fff', zIndex: 999, position: 'absolute', top: -100, left: 50 }}>
    <img src={image} style={{ width: '100%', height: '160px', borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />

    <div style={{ padding: 15 }}>
        <p style={{ fontSize: 15, textAlign: 'justify' }}>{title}</p>
        <p style={{ fontSize: 15, fontWeight: 'bold', color: '#000', textAlign: 'justify' }}>{allData.details.property_source == 1 ? (<>{allData.ejuniper.HotelOfferAmount == '' ? (<>€{(Math.round(allData.ejuniper.NewAmount * 100) / 100).toFixed(2)}</>) : (<> {parseInt(allData.ejuniper.PercentageAm) == 0 ? (<>€{(Math.round(allData.ejuniper.Amount * 100) / 100).toFixed(2)}</>) : (<>
            <>€{(Math.round(allData.ejuniper.HotelOfferAmount * 100) / 100).toFixed(2)}</></>)}
        </>)}</>) : (<>

            {allData.details.property_source == 2 ? (
                <>
                    {allData.hotellink == '' ? (<></>) : (<>
                        <>€{allData.hotellink.amount}</>
                    </>)}
                </>
            ) : (
                <>
                    {allData.hsPortal == '' ? (<></>) : (<>
                        <>€{allData.hsPortal.amount}</>
                    </>)}
                </>
            )}
        </>)} <span style={{fontWeight:'100'}}>/ night</span></p>
    </div>
</div>;

class CustomMaker extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div onClick={this.props.onClick} style={{ backgroundColor: this.props.isSelected || this.props.isSelectedIcon ? '#000' : '#fff', color: this.props.isSelected || this.props.isSelectedIcon ? '#fff' : '#000', padding: 5, width: 45, borderRadius: 20, textAlign: 'center', fontWeight: 'bold', fontSize: 13 }}>{this.props.allData.details.property_source == 1 ? (<>{this.props.allData.ejuniper.HotelOfferAmount == '' ? (<>€{(Math.round(this.props.allData.ejuniper.NewAmount * 100) / 100).toFixed(0)}</>) : (<> {parseInt(this.props.allData.ejuniper.PercentageAm) == 0 ? (<>€{(Math.round(this.props.allData.ejuniper.Amount * 100) / 100).toFixed(0)}</>) : (<>
                    <>€{(Math.round(this.props.allData.ejuniper.HotelOfferAmount * 100) / 100).toFixed(0)}</></>)}
                </>)}</>) : (<>

                    {this.props.allData.details.property_source == 2 ? (
                        <>
                            {this.props.allData.hotellink == '' ? (<></>) : (<>
                                <>€{this.props.allData.hotellink.amount}</>
                            </>)}
                        </>
                    ) : (
                        <>
                            {this.props.allData.hsPortal == '' ? (<></>) : (<>{this.props.allData.hsPortal && (<>€{this.props.allData.hsPortal.amount}</>)}
                            </>)}
                        </>
                    )}
                </>)}</div>
                {this.props.isSelected ? (
                    <AnyReactComponent
                        lat={this.props.lat}
                        lng={this.props.lng}
                        image={this.props.allData.property_source == 1 ?
                            this.props.allData.property_gallery == null
                              ? "images/placeholder.png"
                              : this.props.allData.property_gallery[0].image:
                              this.props.allData.property_gallery == null
                              ? "images/placeholder.png"
                              :"https://supplier.holidays-seychelles.com/uploads/property_gallery_main/" + this.props.allData.property_gallery[0].image
                          }
                        title={this.props.allData.details.property_name}
                        allData={this.props.allData}
                    />
                ) : (
                    <div></div>
                )}
            </>
        );
    }
}

export default CustomMaker;
