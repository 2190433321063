import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        marginTop: 5
    },
    reportTitle:{
        fontSize: 10,
        textAlign: 'center',
        color: '#000',
        marginLeft: 'auto',
        marginRight: 'auto'
       
    }
  });


  const InvoiceAddress = () => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>P.O.Box 2021, Anse Aux Pins, Mahe Seychelles</Text>
    </View>
  );
  
  export default InvoiceAddress