import { Link } from "react-router-dom";
 function ExperiencesSoldout() {

return(
<div className="">
<div className="row">
    <div className="col-md-12 col-sm-12 col-lg-12">
        <div className="d-flax justifi-content-center">
            <div className="images-part">
            <img src={"images/noHotel.png"} style={{ margin: "0 auto" }}/>
            </div>
            <div className="soldoutcontent">
                <h3>We're sorry</h3>
                <p>This experience is not available at this moment. Please try again later or look for an alternative experience. </p>

                <Link to="/">
                <span className="hsw_searchButtonList">Search again</span>
                </Link>
            </div>
        </div>
    </div>
</div>
</div>
)}
export default ExperiencesSoldout;