import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faChild, faGlassMartiniAlt } from '@fortawesome/free-solid-svg-icons';
export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  starsCount = (starCount) => {

    if (starCount == 1) {
        return (
            <>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
            </>
        )
    } else if (starCount == 2) {
        return (
            <>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
            </>
        )
    } else if (starCount == 3) {
        return (
            <>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
            </>
        )
    } else if (starCount == 4) {
        return (
            <>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#ccc'></FontAwesomeIcon>
            </>
        )
    } else if (starCount == 5) {
        return (
            <>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
                <FontAwesomeIcon icon={faStar} color='#000'></FontAwesomeIcon>
            </>
        )
    }

}
  render() {
    return (
      <Map google={this.props.google} zoom={15} initialCenter={{
        lat: this.props.lat,
        lng: this.props.lng
      }} onClick={this.onMapClicked}>

        <Marker
          onMouseover={this.onMarkerClick}
          onMouseout={this.onMapClicked}
          name={'Current location'}
          animation={window.google.maps.Animation.BOUNCE}
        />

        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}>
          <div
            style={{ height: 'auto', width: 240, marginTop: 5, justifyContent: 'center' }}>
            <img src={this.props.src} style={{ width: '100%', height: '160px' }} />

            <div style={{ display: 'flex', justifyContent:'space-between', paddingTop:10}}>
              <p style={{ fontSize: 17, textAlign: 'justify' }}>{this.props.ProDetails.property_name}</p>
              <p style={{ fontSize: 15, fontWeight: 'bold', color: '#000', textAlign: 'justify' }}>{this.starsCount(this.props.ProDetails.star_category_id)}</p>
            </div>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: ("AIzaSyDUMnrX91-4WMTa5ixm3hQjVWaFcMsQ4ek")
})(MapContainer)