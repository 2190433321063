import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import moment from "moment";
import { getEventandActivity } from "../axios";
import { Base64 } from "js-base64";
import "./Event.css";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarWeek, faClock } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function Event() {
  const [eventPage, setEventPage] = useState(null);

  useEffect(() => {
    getEventandActivity()
      .then(function (response) {
        // handle success

        setEventPage(response);
      })
      .catch(function (error) {
        // handle error
      });
  }, []);
//console.log(eventPage);
  return (
    <div
      className="appendBottom20 EventSection sticky-scroll"
      id="Offers_Listing"
    >
      <div className="container">
        {eventPage == null ? (
          <div className="EventlandingCard ">
            <div className="blackText latoBold">
              <h2 style={{ marginBottom: 5 }}>
                {" "}
                <span>
                  <Skeleton />{" "}
                </span>
              </h2>
              <p className="eventPara">
                <span>
                  <Skeleton />{" "}
                </span>
              </p>
            </div>
            <div
              className="EventlandingCardSlider superOffers"
              style={{ direction: "ltr" }}
            >
              <a className="Event-tile">
                <span>
                  <Skeleton />{" "}
                </span>
                <img />
                <div className="text-topEvent">
                  <h4>
                    <span>
                      <Skeleton />{" "}
                    </span>
                  </h4>
                  <p>
                    <span>
                      <Skeleton />{" "}
                    </span>
                  </p>
                </div>
              </a>
            </div>
          </div>
        ) : (
          <>
            <div className="EventlandingCard ">
              <div className="blackText latoBold">
                <h2 style={{ marginBottom: 5 }}>Events & Activities</h2>
                <p className="eventPara">
                  Everyday is a journey and your journey starts here
                </p>
              </div>
              <div
                className="EventlandingCardSlider superOffers"
                style={{ direction: "ltr" }}
              >
                <Slider {...settings}>
                  {eventPage.map(
                    (item, idx) =>
                      item != "no_activity" && (
                        <a
                          className="Event-tile"
                          href={
                            "/activityDetails?tourid=" +
                            Base64.btoa(item.data.id) +
                            "&name=" +
                            encodeURIComponent(item.data.tour_name) +
                            "&location=" +
                            encodeURIComponent(item.data.tour_location) +
                            "&dateselected=" +
                            encodeURIComponent(moment(item.tour_date).format("Y-MM-DD"))
                          }
                          target="_blank"
                        >
                          <img src={item.Image} />
                          <div className="text-topEvent">
                            <p class="latoBlack font22 blackText appendBottom12">
                              <span>{item.Name}</span>
                            </p>

                            <div className="DayTime">
                              {Object.values(item.tourpriceschedulenew.schedule)
                                .length > 1 ? (
                                <p
                                  className="dayText"
                                  data-tip
                                  data-for={item.data.id}
                                  style={{ marginTop: 20 }}
                                >
                                  View all schedules{" "}
                                </p>
                              ) : (
                                <>
                                  {" "}
                                  <p
                                    className="dayText"
                                    style={{ marginTop: 10 }}
                                  >
                                    {" "}
                                    <FontAwesomeIcon
                                      icon={faCalendarWeek}
                                      style={{
                                        fontSize: "15px",
                                        color: "#4f9fcd",
                                        marginRight: "10px",
                                      }}
                                    />{" "}
                                    {item.days}{" "}
                                  </p>
                                  <p className="dayTime">
                                    <FontAwesomeIcon
                                      icon={faClock}
                                      style={{
                                        fontSize: "15px",
                                        color: "#4f9fcd",
                                        marginRight: "10px",
                                      }}
                                    />
                                    {item.tourtiming != null &&
                                      item.tourtiming
                                        .map((tourtimevalue, index) => (
                                          <>
                                            {tourtimevalue.to_time != null
                                              ? tourtimevalue.from_time +
                                                "-" +
                                                tourtimevalue.to_time
                                              : tourtimevalue.from_time}
                                          </>
                                        ))
                                        .reduce((prev, curr) => [
                                          prev,
                                          ", ",
                                          curr,
                                        ])}
                                  </p>
                                </>
                              )}

                              <div className="customTooltip">
                                <ReactTooltip
                                  place="top"
                                  type="light"
                                  id={item.data.id}
                                  style={{
                                    width: "200px",
                                    height: "100%",
                                    color: " #fff",
                                    background: "#0074B7",
                                  }}
                                >
                                  {Object.entries(
                                    item.tourpriceschedulenew.schedule
                                  ) == null ? (
                                    <div className="tour-grade-loading-wrapper container">
                                      <div className="loading-wrapper mb-3">
                                        <div className="loading-message px-lg-3 py-3">
                                          Loading available options...
                                        </div>
                                        <Skeleton
                                          style={{
                                            width: "95%",
                                            height: 20,
                                            marginBottom: 10,
                                            marginTop: 10,
                                          }}
                                        />
                                        <Skeleton
                                          style={{
                                            width: "90%",
                                            height: 20,
                                            marginBottom: 10,
                                          }}
                                        />
                                        <Skeleton
                                          style={{
                                            width: "80%",
                                            height: 20,
                                            marginBottom: 10,
                                          }}
                                        />
                                        <Skeleton
                                          style={{
                                            width: "70%",
                                            height: 20,
                                            marginBottom: 10,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      {Object.values(
                                        item.tourpriceschedulenew.schedule
                                      ).map((i, j) => {
                                        return (
                                          <>
                                            <div className="reactpoopup">
                                              <div className="px-0">
                                                <label
                                                  for="rb0"
                                                  className="mb-0 w-100 enabled"
                                                >
                                                  <div
                                                    className="tour-grade-title h3 mb-0 font-weight-medium tg-title"
                                                    style={{ fontSize: "20px" }}
                                                  >
                                                    {i.option_name}
                                                  </div>
                                                </label>
                                              </div>
                                              {Object.values(
                                                item.tourpriceschedulenew
                                                  .schedule_day
                                              ).map((day, k) => {
                                                if (k == j) {
                                                  return (
                                                    <>
                                                      <p
                                                        className="dayText"
                                                        style={{ marginTop: 5 }}
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faCalendarWeek}
                                                          style={{
                                                            fontSize: "15px",
                                                            color: "#4f9fcd",
                                                            marginRight: "10px",
                                                          }}
                                                        />{" "}
                                                        {day == "undefined" ? (
                                                          <>Cordinate Date</>
                                                        ) : (
                                                          <>{day}</>
                                                        )}{" "}
                                                      </p>
                                                    </>
                                                  );
                                                }
                                              })}
                                              {Object.values(
                                                item.tourpriceschedulenew
                                                  .schedule_time
                                              ).map((time, m) => {
                                                if (m == j) {
                                                  return (
                                                    <>
                                                      <p
                                                        className="dayText"
                                                        style={{ marginTop: 5 }}
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faClock}
                                                          style={{
                                                            fontSize: "15px",
                                                            color: "#4f9fcd",
                                                            marginRight: "10px",
                                                          }}
                                                        />
                                                        {time == "undefined" ? (
                                                          <>Cordinate Time</>
                                                        ) : (
                                                          <>
                                                            {time !=
                                                              "undefined" &&
                                                              time
                                                                .map(
                                                                  (
                                                                    tourtimevalue,
                                                                    index
                                                                  ) => (
                                                                    <>
                                                                      {tourtimevalue.to_time !=
                                                                      null
                                                                        ? tourtimevalue.from_time +
                                                                          "-" +
                                                                          tourtimevalue.to_time
                                                                        : tourtimevalue.from_time}
                                                                    </>
                                                                  )
                                                                )
                                                                .reduce(
                                                                  (
                                                                    prev,
                                                                    curr
                                                                  ) => [
                                                                    prev,
                                                                    ", ",
                                                                    curr,
                                                                  ]
                                                                )}
                                                          </>
                                                        )}
                                                      </p>
                                                    </>
                                                  );
                                                }
                                              })}
                                            </div>
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                                </ReactTooltip>
                              </div>
                            </div>
                          </div>
                        </a>
                      )
                  )}
                </Slider>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
