import React from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../Component/Header'

export default function Home() {
    return (
       
       
            <Header />

    )
}
