import React from 'react';
import { useTimer } from 'react-timer-hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import './PaymentDetails.css';

function Timer({ expiryTimestamp, autoStart, format }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning
  } = useTimer({ expiryTimestamp,autoStart, format, onExpire: () => onExpireFunction() });

let onExpireFunction = () => {
    window.location.reload(true);
}
  return (
      <div style={{marginLeft:320,}}>
      <h6 className="deYlFr">Holding Pricing… <FontAwesomeIcon icon={faClock} color='rgb(57, 111, 198)'></FontAwesomeIcon> <span className="jwTkAI twqCr">{hours}</span>:<span  className="jwTkAI twqCr">{minutes}</span>:<span  className="jwTkAI twqCr">{seconds}</span></h6>
      </div>
  );
}

export default Timer;