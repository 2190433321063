/* eslint-disable */

import React, { useState, useEffect, Component } from "react";
import "./TourOfferListing.css";
import Slider from "react-slick";
import InputRange from "react-input-range";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons";
import {
  getSpecialOffersForHomeExperiences,
  getSpecialOffersForHomeExperiencesAttraction,
  getSpecialOffersForHomeExperiencesPackage,
  getSpecialOffersForHomeExperiencesTransfer,
  getSpecialOffersForHomeExperiencesLiveAboard,
  getSpecialOffersForHome,
  getSpecialOfferEjunifer,
  getSpecialOfferEjuniferListing,
  getSpecialOffersForFilterHome,
  getSpecialOffersForHomeExperiencesFilters,
  getSpecialOffersForHomeExperiencesAttractionFilters,
  getSpecialOffersForHomeExperiencesPackageFilters,
  getSpecialOffersForHomeExperiencesTransferFilters,
  getSpecialOffersForHomeExperiencesLiveAboardFilters,
} from "../Component/axios";
import TourOfferList from "../Component/TourOffer/TourOfferList";
import TourListSkeleton from "../Component/TourOffer/TourListSkeleton";
import NewHeader from "../Component/NewHeader/NewHeader";
import { List } from "react-virtualized";
import data from "../data";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import TourOfferListingComponent from "./TourOfferListingComponent";
import AttractionOfferListingComponent from "./ExperiencesTourListing/AttractionOfferListingComponent";
import PackageOfferListingComponent from "./ExperiencesTourListing/PackageOfferListingComponent";
import TransfersOfferListingComponent from "./ExperiencesTourListing/TransfersOfferListingComponent";
import LiveaboardOfferListingComponent from "./ExperiencesTourListing/LiveaboardOfferListingComponent";
import {Helmet} from "react-helmet";
import Skeleton from "react-loading-skeleton";
const list = data;
export default class TourOfferListing extends Component {
  // const [ImageChanger, setImageChanger] = useState();
  constructor(props) {
    super(props);
    this.state = {
      dropdownVisible: false,
      searchInput: "",
      itemsZone: [],
      error: null,
      val: "",
      value: "",
      suggestions: [],
      showResults: false,
      defaultZoneval: "Seychelles",
      SelectedPlace: null,
      Zoneval: "",
      specialoffesList: null,
      specialoffesListFilter: [],
      specialoffesListFilterExperience: [],
      specialoffesListFilterExperienceAttraction: [],
      specialoffesListFilterExperiencePackage: [],
      specialoffesListFilterExperienceTransfer: [],
      specialoffesListFilterExperienceLiveaboard: [],
      nav: false,
      selected: "Accommodations",
      modalIsOfferOpen: false,
      OfferDetailsClass: "containerOffer",
      // Experiencesdata: props.OfferData || null,
      accomo: true,
      attract: false,
      package: false,
      transfer: false,
      liveaboard: false,
      Experiencesdata: null,
      ExperiencesdataAttraction: null,
      ExperiencesdataPackage: null,
      ExperiencesdataTransfer: null,
      ExperiencesdataLiveaboard: null,
      OfferData: null,
      EjuniferOfferData: null,
      ImageChanger: [],
      searchData: "",
      urlsearchData: "",
      sorting: false,
      sortingSelect: "Price - Low to High",
      sortingId: null,
      FiltersData: null,
      FiltersDataAttrac: null,
      FiltersDataLiveaboard: null,
      FiltersDataPackage: null,
      FiltersDataTransfer: null,
      valuePrice: { min: 0, max: 10 },
      appliedFilterVal: [],
      appliedFilterValAttrac: [],
      appliedFilterValPackage: [],
      appliedFilterValTransfer: [],
      appliedFilterValLiveaboard: [],
      checked: false,
      checkedItems: new Map(),
      selectActivityDayNew: new Date(),
    };
  }
 
   componentDidMount() {

    var query = new URLSearchParams(this.props.location.search);
    const token = query.get('defaultUrl')
   // console.log(token)

   }

  handleEvent = (id) => {
    this.setState({ SelectedPlace: id });
  };

  onMouseOutFun = (image) => {
    this.setState({ ImageChanger: image });
  };
  onMouseOverFun = (image) => {
    this.setState({ ImageChanger: image });
  };

  componentDidMount() {
    // getSpecialOffersForHomeExperiences().then(
    //   (data) => {
    //     console.log(data);
    //     this.setState({ Experiencesdata: data });
    //   },
    //   (error) => {}
    // );

    // getSpecialOffersForHome().then(
    //     (data) => {
    //         console.log(data);
    //         this.setState({ OfferData: data });
    //     },
    //     (error) => { }
    // );

    getSpecialOffersForHomeExperiences().then(
      (data) => {
       // console.log(data);
        // this.setState({ activityData: data });
        if (data == undefined) {
          this.setState({
            Experiencesdata: null,
          });
        } else {
          this.setState({
            specialoffesListFilterExperience: data,
          });
          this.setState({
            Experiencesdata: data,
          });

          if (data == "no_special_offers") {
            this.setState({
              Experiencesdata: data,
            });
          } else {
            this.SortingFunction(data);
          }
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    getSpecialOffersForHomeExperiencesAttraction().then(
      (data) => {
       // console.log(data);
        // this.setState({ activityData: data });
        if (data == undefined) {
          this.setState({
            ExperiencesdataAttraction: null,
          });
        } else {
          this.setState({
            specialoffesListFilterExperienceAttraction: data,
          });
          this.setState({
            ExperiencesdataAttraction: data,
          });

          if (data == "no_special_offers") {
            this.setState({
              ExperiencesdataAttraction: data,
            });
          } else {
            this.SortingFunction(data);
          }
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    getSpecialOffersForHomeExperiencesPackage().then(
      (data) => {
       // console.log(data);
        // this.setState({ activityData: data });
        if (data == undefined) {
          this.setState({
            ExperiencesdataPackage: null,
          });
        } else {
          this.setState({
            specialoffesListFilterExperiencePackage: data,
          });
          this.setState({
            ExperiencesdataPackage: data,
          });

          if (data == "no_special_offers") {
            this.setState({
              ExperiencesdataPackage: data,
            });
          } else {
            this.SortingFunction(data);
          }
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    getSpecialOffersForHomeExperiencesTransfer().then(
      (data) => {
        console.log(data);
        // this.setState({ activityData: data });
        if (data == undefined) {
          this.setState({
            ExperiencesdataTransfer: null,
          });
        } else {
          this.setState({
            specialoffesListFilterExperienceTransfer: data,
          });
          this.setState({
            ExperiencesdataTransfer: data,
          });

          if (data == "no_special_offers") {
            this.setState({
              ExperiencesdataTransfer: data,
            });
          } else {
            this.SortingFunction(data);
          }
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    getSpecialOffersForHomeExperiencesLiveAboard().then(
      (data) => {
       // console.log(data);
        // this.setState({ activityData: data });
        if (data == undefined) {
          this.setState({
            ExperiencesdataLiveaboard: null,
          });
        } else {
          this.setState({
            specialoffesListFilterExperienceLiveaboard: data,
          });
          this.setState({
            ExperiencesdataLiveaboard: data,
          });

          if (data == "no_special_offers") {
            this.setState({
              ExperiencesdataLiveaboard: data,
            });
          } else {
            this.SortingFunction(data);
          }
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    getSpecialOfferEjuniferListing().then(
      (data) => {
       // console.log(data);
        // this.setState({ activityData: data });
        if (data == undefined) {
          this.setState({
            OfferData: null,
          });
        } else {
          this.setState({
            specialoffesListFilter: data,
          });
          this.setState({
            OfferData: data,
          });

          if (data == "no_special_offers") {
            this.setState({
              OfferData: data,
            });
          } else {
            this.SortingFunction(data);
          }
        }
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    // getSpecialOffersForHome().then(
    //   (data) => {
    //     console.log(data);
    //     // this.setState({ activityData: data });
    //     if (data == undefined) {
    //       this.setState({
    //         OfferData: null,
    //       });
    //     } else {
    //       this.setState({
    //         specialoffesListFilter: data,
    //       });
    //       this.setState({
    //         OfferData: data,
    //       });

    //       if (data == "no_special_offers") {
    //         this.setState({
    //           OfferData: data,
    //         });
    //       } else {
    //         this.SortingFunction(data);
    //       }
    //     }
    //   },
    //   (error) => {
    //     this.setState({
    //       error,
    //     });
    //   }
    // );

    getSpecialOffersForFilterHome().then(
      (data) => {
      
        if(data?.budget?.maxPrice){
          this.setState({ FiltersData: data });
          this.setState({ valuePrice: { min: 0, max: data?.budget?.maxPrice } });
        }
       
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    // getSpecialOffersForHomeExperiencesFilters().then(
    //   (data) => {
   
    //     // this.setState({ valuePrice: { min: 0, max: data.budget.maxPrice } });
        
    //     this.setState({ FiltersDataAttrac: data.attraction });
    //     this.setState({ FiltersDataLiveaboard: data.liveaboard });
    //     this.setState({ FiltersDataPackage: data.package });
    //     this.setState({ FiltersDataTransfer: data.transfer });
    //   },
    //   (error) => {
    //     this.setState({
    //       error,
    //     });
    //   }
    // );

    getSpecialOffersForHomeExperiencesAttractionFilters().then(
      (data) => {
      
        this.setState({ FiltersDataAttrac: data });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    getSpecialOffersForHomeExperiencesPackageFilters().then(
      (data) => {
      
        this.setState({ FiltersDataPackage: data});
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );

    getSpecialOffersForHomeExperiencesTransferFilters().then(
      (data) => {
        this.setState({ FiltersDataTransfer: data });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
    getSpecialOffersForHomeExperiencesLiveAboardFilters().then(
      (data) => {
        this.setState({ FiltersDataLiveaboard: data });
      
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
   
  }

  SortingFunction = (data) => {
    if (data != "no_special_offers") {
    
      const sorArr = data;
      const zeroElement = sorArr[0];
    }
  };
 
  getSpecialOff = (data) => {
 
    if (data == "Accommodations") {
      this.setState({ accomo: true });
      this.setState({ liveaboard: false });
      this.setState({ transfer: false });
      this.setState({ package: false });
      this.setState({ attract: false });
    } else if (data == "Attractions") {
      this.setState({ attract: true });
      this.setState({ accomo: false });
      this.setState({ liveaboard: false });
      this.setState({ transfer: false });
      this.setState({ package: false });
    } else if (data == "Packages") {
      this.setState({ package: true });
      this.setState({ attract: false });
      this.setState({ accomo: false });
      this.setState({ liveaboard: false });
      this.setState({ transfer: false });
    } else if (data == "Transfers") {
      this.setState({ transfer: true });
      this.setState({ package: false });
      this.setState({ attract: false });
      this.setState({ accomo: false });
      this.setState({ liveaboard: false });
    } else if (data == "Liveaboard") {
      this.setState({ liveaboard: true });
      this.setState({ transfer: false });
      this.setState({ package: false });
      this.setState({ attract: false });
      this.setState({ accomo: false });
    }
  };

 
  RemoveSingleFilter = (i, index) => {
 
    this.setState({ OfferData: null });

    let applied = [...this.state.appliedFilterVal];

    applied.splice(index, 1);

    this.setState({
      appliedFilterVal: applied,
    });

    document.getElementsByName(i.label).checked = false;

    this.setState((prevState) => prevState.checkedItems.set(i.label, false));

    const SpecialData = [...this.state.specialoffesListFilter];

    const result = [];

    setTimeout(() => {
      SpecialData.map((i, j) => {
        if (this.state.appliedFilterVal.length > 0) {
          this.state.appliedFilterVal.map((item, index) => {
            if (i.HotelOfferName.toLowerCase().trim() === item.label) {
              result.push(i);
            }
          });
        } else {
          result.push(i);
        }
      });
    }, 2000);

    setTimeout(() => {
     this.setState({ OfferData: result });
    }, 2000);
  };

  RemoveMultipleFilter = (maxValue) => {
    this.setState({
      appliedFilterVal: [],
    });
    this.setState({ OfferData: null });
   
    const clearCheckedItems = new Map();
    this.setState({ checkedItems: clearCheckedItems });

    const specialofferData = [...this.state.specialoffesListFilter];

    setTimeout(() => {
      this.setState({ OfferData: specialofferData });
    }, 2000);
  };

  CheckAppliedFilterOfferName = () => {
    this.setState({ OfferData: null });
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    
    const name = target.name;
    let applied = [...this.state.appliedFilterVal];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: value }],
      });
    } else {
      let applied = [...this.state.appliedFilterVal];

      let index = applied.length - 1;

      applied.splice(index, 1);

      this.setState({
        appliedFilterVal: applied,
      });
    }
  
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));
  const OfferData = [...this.state.specialoffesListFilter];

    const result = [];

    setTimeout(() => {
     OfferData !=null &&OfferData.map((i, j) => {
        if (this.state.appliedFilterVal.length > 0) {
          this.state.appliedFilterVal.map((item, index) => {
           
            if (i.HotelOfferName.toLowerCase().trim() === item.label) {
           
              result.push(i);
            }
          });
        } else {
          result.push(i);
        }
      });
    }, 2000);
    setTimeout(() => {
      this.setState({ OfferData: result });
    }, 2000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ OfferData: this.state.specialoffesListFilter });
      }
    }, 2000);
  };

  CheckAppliedFilter = () => {
   
    this.setState({ OfferData: null });
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    
    const name = target.name;
    let applied = [...this.state.appliedFilterVal];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: value }],
      });
    } else {
      let applied = [...this.state.appliedFilterVal];

      let index = applied.length - 1;

      applied.splice(index, 1);

      this.setState({
        appliedFilterVal: applied,
      });
    }
  
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));
  const OfferData = [...this.state.specialoffesListFilter];

    const result = [];

    setTimeout(() => {
     OfferData !=null &&OfferData.map((i, j) => {
        if (this.state.appliedFilterVal.length > 0) {
          this.state.appliedFilterVal.map((item, index) => {
           
            if (i.island.toLowerCase().trim() === item.label) {
           
              result.push(i);
            }
          });
        } else {
          result.push(i);
        }
      });
    }, 2000);
    setTimeout(() => {
      this.setState({ OfferData: result });
    }, 2000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ OfferData: this.state.specialoffesListFilter });
      }
    }, 2000);
  };


  CheckAppliedFilterProperty = () => {
  
    this.setState({ OfferData: null });

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
   
    const name = target.name;
    let applied = [...this.state.appliedFilterVal];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: value }],
      });
    } else {
      let applied = [...this.state.appliedFilterVal];

      let index = applied.length - 1;

      applied.splice(index, 1);

      this.setState({
        appliedFilterVal: applied,
      });
    }
    // this.setState({checked: value});
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));

    const OfferData = [...this.state.specialoffesListFilter];

    const result = [];

    setTimeout(() => {
      OfferData.map((i, j) => {
        this.state.appliedFilterVal.map((item, index) => {
          if (i.property_type.toLowerCase().trim() === item.label) {
            result.push(i);
          }
        });
      });
    }, 2000);

    // const result = hotelData.filter(data => (data.details.city.toLowerCase()).trim() == name);

   

    setTimeout(() => {
      this.setState({ OfferData: result });
    }, 2000);

    setTimeout(() => {
      if (this.state.appliedFilterVal.length == 0) {
        this.setState({ OfferData: OfferData });
      }
    }, 2000);
  };

  changePrice = (value) => {
    this.setState({ OfferData: null });
 
    //  this.setState({ valuePrice: value })

    let name = value.min + "-" + value.max;
    let applied = [...this.state.appliedFilterVal];

    let catlength = applied.length;
    let PriceRangeVal = "abc";
    //var i = 0;

    applied.map((i, j) => {
      if (i.value == "priceRange") {
        PriceRangeVal = j;
        return PriceRangeVal;
      }
    });

   
    if (PriceRangeVal == "abc") {
      this.setState({
        appliedFilterVal: [...applied, { label: name, value: "priceRange" }],
      });
    } else {
      applied[PriceRangeVal] = { label: name, value: "priceRange" };
      this.setState({
        appliedFilterVal: applied,
      });
    }

    const OfferData = [...this.state.specialoffesListFilter];

    const result = [];

    setTimeout(() => {
      OfferData.map((i, j) => {
       // if (i.details.property_source == 1) {
          //if (i.ejuniper.Amount != "0.00") {
            if (
              parseInt(i.HotelOfferAmount) >= value.min &&
              parseInt(i.HotelOfferAmount) <= value.max
            ) {
              result.push(i);
            }
          // } else if (i.ejuniper.NewAmount != "0.00") {
          //   if (
          //     parseInt(i.ejuniper.NewAmount) >= value.min &&
          //     parseInt(i.ejuniper.NewAmount) <= value.max
          //   ) {
          //     result.push(i);
          //   }
          // }
        // } else if (i.details.property_source == 2) {
        //   if (i.hotellink.amount > 0) {
        //     if (
        //       parseInt(i.hotellink.amount) >= value.min &&
        //       parseInt(i.hotellink.amount) <= value.max
        //     ) {
        //       result.push(i);
        //     }
        //   }
        // }
      });
    }, 2000);

    // const result = hotelData.filter(data => (data.details.city.toLowerCase()).trim() == name);

  

    setTimeout(() => {
      this.setState({ OfferData: result });
    }, 2000);
  };


  CheckAppliedFilterOfferNameAttraction = () => {
    this.setState({ ExperiencesdataAttraction: null });
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
   
    const name = target.name;
    let applied = [...this.state.appliedFilterValAttrac];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterValAttrac: [...applied, { label: name, value: value }],
      });
    } else {
      let applied = [...this.state.appliedFilterValAttrac];

      let index = applied.length - 1;

      applied.splice(index, 1);

      this.setState({
        appliedFilterValAttrac: applied,
      });
    }
  
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));
    const result = [];

    setTimeout(() => {
      this.state.specialoffesListFilterExperienceAttraction !=null && this.state.specialoffesListFilterExperienceAttraction.map((i, j) => {
       
        if(this.state.specialoffesListFilterExperienceAttraction[j][0]!='no_Attraction_data'){
          
          Object.values(this.state.specialoffesListFilterExperienceAttraction[j][0]).map((key1, newinde) =>{
         
          this.state.appliedFilterValAttrac.map((item, index) => {
           
            if (key1.tour_data.offer_name.toLowerCase().trim() === item.label) {
              result.push(i);
            }
          });
        });
        }
        
      });
    }, 2000);

    setTimeout(() => {
      this.setState({ ExperiencesdataAttraction: result });
    }, 2000);

    setTimeout(() => {
      if (this.state.appliedFilterValAttrac.length == 0) {
        this.setState({ ExperiencesdataAttraction: this.state.specialoffesListFilterExperienceAttraction });
      }
    }, 2000);
  };

  RemoveSingleFilterAttrac = (i, index) => {
   // console.log(index);
    this.setState({ ExperiencesdataAttraction: null });

    let applied = [...this.state.appliedFilterValAttrac];

    applied.splice(index, 1);

    this.setState({
      appliedFilterValAttrac: applied,
    });

    document.getElementsByName(i.label).checked = false;

    this.setState((prevState) => prevState.checkedItems.set(i.label, false));

    const ExperiencesdataAttraction = [...this.state.specialoffesListFilterExperienceAttraction];
//console.log(ExperiencesdataAttraction);
    const result = [];

    setTimeout(() => {
      ExperiencesdataAttraction.map((i, j) => {
        if(ExperiencesdataAttraction[j][0]!='no_Attraction_data'){
          Object.values(ExperiencesdataAttraction[j][0]).map((key1, newinde) =>{
          //  console.log(key1.tour_data.offer_name)
          this.state.appliedFilterValAttrac.map((item, index) => {
         //   console.log(item)
            if (key1.tour_data.offer_name.toLowerCase().trim() === item.label) {
              result.push(i);
            }
          });
        });
      }
        
      });
    }, 2000);
    // setTimeout(() => {
    //   this.state.specialoffesListFilterExperienceAttraction !=null && this.state.specialoffesListFilterExperienceAttraction.map((i, j) => {
    //     console.log(i);
    //     if(this.state.specialoffesListFilterExperienceAttraction[j][0]!='no_Attraction_data'){
          
    //       Object.values(this.state.specialoffesListFilterExperienceAttraction[j][0]).map((key1, newinde) =>{
    //         console.log(key1.tour_data.offer_name)
    //       this.state.appliedFilterValAttrac.map((item, index) => {
    //         console.log(item)
    //         if (key1.tour_data.offer_name.toLowerCase().trim() === item.label) {
    //           result.push(i);
    //         }
    //       });
    //     });
    //     }
        
    //   });
    // }, 2000);

    //console.log("Item Data",result);

    setTimeout(() => {
      this.setState({ ExperiencesdataAttraction: result });
    }, 2000);
  };



  CheckAppliedFilterOfferNamePackage = () => {
    this.setState({ ExperiencesdataPackage: null });
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    //console.log(value);
    const name = target.name;
    let applied = [...this.state.appliedFilterValPackage];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterValPackage: [...applied, { label: name, value: value }],
      });
    } else {
      let applied = [...this.state.appliedFilterValPackage];

      let index = applied.length - 1;

      applied.splice(index, 1);

      this.setState({
        appliedFilterValPackage: applied,
      });
    }
  
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));
    const result = [];

    setTimeout(() => {
      this.state.specialoffesListFilterExperiencePackage !=null && this.state.specialoffesListFilterExperiencePackage.map((i, j) => {
        //console.log(i);
        if(this.state.specialoffesListFilterExperiencePackage[j][0]!='no_package_data'){
          
          Object.values(this.state.specialoffesListFilterExperiencePackage[j][0]).map((key1, newinde) =>{
            //console.log(key1.tour_data.offer_name)
          this.state.appliedFilterValPackage.map((item, index) => {
           // console.log(item)
            if (key1.tour_data.offer_name.toLowerCase().trim() === item.label) {
              result.push(i);
            }
          });
        });
        }
        
      });
    }, 2000);

    setTimeout(() => {
      this.setState({ ExperiencesdataPackage: result });
    }, 2000);

    setTimeout(() => {
      if (this.state.appliedFilterValPackage.length == 0) {
        this.setState({ ExperiencesdataPackage: this.state.specialoffesListFilterExperiencePackage });
      }
    }, 2000);
  };

  RemoveSingleFilterPackage = (i, index) => {
    //console.log(index);
    this.setState({ ExperiencesdataPackage: null });

    let applied = [...this.state.appliedFilterValAttrac];

    applied.splice(index, 1);

    this.setState({
      appliedFilterValAttrac: applied,
    });

    document.getElementsByName(i.label).checked = false;

    this.setState((prevState) => prevState.checkedItems.set(i.label, false));

    const ExperiencesdataPackage = [...this.state.specialoffesListFilterExperiencePackage];

    const result = [];

    setTimeout(() => {
      ExperiencesdataPackage.map((i, j) => {
        if(ExperiencesdataPackage[j][0]!='no_package_data'){
          Object.values(ExperiencesdataPackage[j][0]).map((key1, newinde) =>{
           // console.log(key1.tour_data.offer_name)
          this.state.appliedFilterValAttrac.map((item, index) => {
           // console.log(item)
            if (key1.tour_data.offer_name.toLowerCase().trim() === item.label) {
              result.push(i);
            }
          });
        });
      }
        
      });
    }, 2000);

    setTimeout(() => {
      this.setState({ ExperiencesdataPackage: result });
    }, 2000);
  };


  CheckAppliedFilterOfferNameTransfer = () => {
    this.setState({ ExperiencesdataTransfer: null });
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
   // console.log(value);
    const name = target.name;
    let applied = [...this.state.appliedFilterValTransfer];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterValTransfer: [...applied, { label: name, value: value }],
      });
    } else {
      let applied = [...this.state.appliedFilterValTransfer];

      let index = applied.length - 1;

      applied.splice(index, 1);

      this.setState({
        appliedFilterValTransfer: applied,
      });
    }
  
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));
    const result = [];

    setTimeout(() => {
      this.state.specialoffesListFilterExperienceTransfer !=null && this.state.specialoffesListFilterExperienceTransfer.map((i, j) => {
        //console.log(i);
        if(this.state.specialoffesListFilterExperienceTransfer[j][0]!='no_transfer_data'){
          
          Object.values(this.state.specialoffesListFilterExperienceTransfer[j][0]).map((key1, newinde) =>{
            //console.log(key1.tour_data.offer_name)
          this.state.appliedFilterValTransfer.map((item, index) => {
            //console.log(item)
            if (key1.tour_data.offer_name.toLowerCase().trim() === item.label) {
              result.push(i);
            }
          });
        });
        }
        
      });
    }, 2000);

    setTimeout(() => {
      this.setState({ ExperiencesdataTransfer: result });
    }, 2000);

    setTimeout(() => {
      if (this.state.appliedFilterValTransfer.length == 0) {
        this.setState({ ExperiencesdataTransfer: this.state.specialoffesListFilterExperienceTransfer });
      }
    }, 2000);
  };

  RemoveSingleFilterTransfer = (i, index) => {
    //console.log(index);
    this.setState({ ExperiencesdataTransfer: null });

    let applied = [...this.state.appliedFilterValTransfer];

    applied.splice(index, 1);

    this.setState({
      appliedFilterValTransfer: applied,
    });

    document.getElementsByName(i.label).checked = false;

    this.setState((prevState) => prevState.checkedItems.set(i.label, false));

    const ExperiencesdataTransfer = [...this.state.specialoffesListFilterExperienceTransfer];

    const result = [];

    setTimeout(() => {
      ExperiencesdataTransfer.map((i, j) => {
        if(ExperiencesdataTransfer[j][0]!='no_transfer_data'){
          Object.values(ExperiencesdataTransfer[j][0]).map((key1, newinde) =>{
           // console.log(key1.tour_data.offer_name)
          this.state.appliedFilterValTransfer.map((item, index) => {
           // console.log(item)
            if (key1.tour_data.offer_name.toLowerCase().trim() === item.label) {
              result.push(i);
            }
          });
        });
      }
        
      });
    }, 2000);

    setTimeout(() => {
      this.setState({ ExperiencesdataTransfer: result });
    }, 2000);
  };

  CheckAppliedFilterOfferNameLiveaboard = () => {
    this.setState({ ExperiencesdataLiveaboard: null });
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    //console.log(value);
    const name = target.name;
    let applied = [...this.state.appliedFilterValLiveaboard];

    const isChecked = event.target.checked;

    if (value) {
      this.setState({
        appliedFilterValLiveaboard: [...applied, { label: name, value: value }],
      });
    } else {
      let applied = [...this.state.appliedFilterValLiveaboard];

      let index = applied.length - 1;

      applied.splice(index, 1);

      this.setState({
        appliedFilterValLiveaboard: applied,
      });
    }
  
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(name, isChecked),
    }));
    const result = [];

    setTimeout(() => {
      this.state.specialoffesListFilterExperienceLiveaboard !=null && this.state.specialoffesListFilterExperienceLiveaboard.map((i, j) => {
        //console.log(i);
        if(this.state.specialoffesListFilterExperienceLiveaboard[j][0]!='no_liveaboard_data'){
          
          Object.values(this.state.specialoffesListFilterExperienceLiveaboard[j][0]).map((key1, newinde) =>{
            //console.log(key1.tour_data.offer_name)
          this.state.appliedFilterValLiveaboard.map((item, index) => {
            //console.log(item)
            if (key1.tour_data.offer_name.toLowerCase().trim() === item.label) {
              result.push(i);
            }
          });
        });
        }
        
      });
    }, 2000);

    setTimeout(() => {
      this.setState({ ExperiencesdataLiveaboard: result });
    }, 2000);

    setTimeout(() => {
      if (this.state.appliedFilterValLiveaboard.length == 0) {
        this.setState({ ExperiencesdataLiveaboard: this.state.specialoffesListFilterExperienceLiveaboard });
      }
    }, 2000);
  };

  RemoveSingleFilterLiveaboard = (i, index) => {
    this.setState({ ExperiencesdataLiveaboard: null });
    let applied = [...this.state.appliedFilterValLiveaboard];
    applied.splice(index, 1);

    this.setState({
      appliedFilterValLiveaboard: applied,
    });

    document.getElementsByName(i.label).checked = false;

    this.setState((prevState) => prevState.checkedItems.set(i.label, false));

    const ExperiencesdataLiveaboard = [...this.state.specialoffesListFilterExperienceLiveaboard];

    const result = [];

    setTimeout(() => {
      ExperiencesdataLiveaboard.map((i, j) => {
        if(ExperiencesdataLiveaboard[j][0]!='no_liveaboard_data'){
          Object.values(ExperiencesdataLiveaboard[j][0]).map((key1, newinde) =>{
            //console.log(key1.tour_data.offer_name)
          this.state.appliedFilterValLiveaboard.map((item, index) => {
            //console.log(item)
            if (key1.tour_data.offer_name.toLowerCase().trim() === item.label) {
              result.push(i);
            }
          });
        });
      }
        
      });
    }, 2000);

    setTimeout(() => {
      this.setState({ ExperiencesdataLiveaboard: result });
    }, 2000);
  };
  
  
 
  rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }) => {
    
    console.log(this.state.ExperiencesdataTransfer);
    return (
      <div
        key={key}
        className="specialoffesListing"
        style={style}
        onMouseOver={(e) =>
          this.handleEvent(this.state.specialoffesList[index].details.id)
        }
        onMouseOut={(e) =>
          this.handleEvent(this.state.specialoffesList[index].details.id)
        }
      >
        <div className="specialoffesListingItem ">
          {" "}
          <span className="listingImg">
            <img
              src={
                this.state.specialoffesList[index].property_gallery == null
                  ? "images/placeholder.png"
                  : this.state.specialoffesList[index].property_gallery[0].image
              }
              alt=""
            />
          </span>
          <div className="listingDetails">
            <p className="makeFlex1 hrtlCenter appendBottom10">
              <span className="hotelRatingTa greenBgTA whiteText font9">
                <span className="sprite icTaRating appendRight3"></span>
                <span className="latoBold">4.0</span>/5
              </span>
            </p>
            <p className="latoBold font14 blackText appendBottom3">
              {this.state.specialoffesList[index].details.property_name}
            </p>
            <div
              className="makeFlex1 spaceBetween"
              style={{ marginTop: "-10px" }}
            >
              <div className="flexOne">
                <p
                  className="latoBold font12 grayText appendBottom3"
                  style={{ textAlign: "left", marginTop: 15 }}
                  alt="Massy"
                >
                  {this.state.specialoffesList[index].details.address_line_1}
                </p>
              </div>
              <div className="noShring textRight" style={{ marginTop: 10 }}>
                <p className="font11 lineThrough grayText appendBottom5">
                  $ {list[index].totalPrice}
                </p>
                <p className="latoBlack font14 blackText appendBottom3">
                  $ {list[index].totalPrice}
                </p>
                <p className="font10 grayText">Per night</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <div>
        <Helmet>
                  <title> Best Travel Deals in Seychelles- Holidays Seychelles</title>
                  <meta name="description" content="Get best travel deals in Seychelles at best price. Seychelles is one of the beautiful places in the world." />
                  <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
                </Helmet>
                <h1 style={{color:'#fff'}}> Best Travel Deals in Seychelles- Holidays Seychelles</h1>
       
          <NewHeader page={"inner_listing"} />

          <div className="container makeFlex spaceBetween">
            <div
              className="container makeFlex spaceBetween"
              style={{ marginTop: 50, padding: "10px" }}
            >
                {this.state.accomo && (
                          <>
              {this.state.FiltersData == null ? (
                <div className="filterWrap appendRight30">
                  <div className="appendBottom35">
                    <p className="makeFlex spaceBetween end">
                      <span className="latoBold font20 blackText">
                        <Skeleton style={{ width: 240, height: 30 }} />
                      </span>
                    </p>

                    <div className="filterRow" id="hlistpg_fr_star_category">
                      <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                        <Skeleton style={{ width: 240, height: 26 }} />
                      </div>
                      <ul className="filterList">
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              ) : this.state.FiltersData == "no-Filter" ? (
                <> </>
              ) : (
                <div className="filterWrap appendRight30">
                  <div className="appendBottom35">
                    {this.state.appliedFilterVal.length > 0 ? (
                      <div className="appliedFiltersContainer">
                        <div
                          className="makeFlex spaceBetween end appendBottom20"
                          style={{
                            marginBottom: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="font20 blackText">
                            Applied Filters
                          </span>
                          <a
                            className="latoBold font12 capText"
                            href="javascript:;"
                            onClick={() =>
                              this.RemoveMultipleFilter(
                                this.state.FiltersData.budget.maxPrice
                              )
                            }
                          >
                            Clear
                          </a>
                        </div>
                        <ul className="appliedFilters">
                        {this.state.appliedFilterVal.map((i, j) => (
                            <li>
                              <span className="latoBold" style={{textTransform: 'capitalize'}} >{i.label}</span>
                              <a
                                href="javascript:;"
                                onClick={() =>
                                  this.RemoveSingleFilter(
                                    i,
                                    j
                                  )
                                }
                              ></a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p
                        className="makeFlex spaceBetween end"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <span
                          className="font20 blackText"
                          style={{ fontWeight: 500 }}
                        >
                          Select Filters
                        </span>
                      </p>
                    )}

                  <div className="filterRow" id="hlistpg_fr_star_category">
                      <div
                        className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter"
                        style={{ marginTop: 15 }}
                      >
                        Location
                      </div>
                      <ul className="filterList">
                        {Object.values(this.state.FiltersData.location).sort((a, b) => parseFloat(b.count) - parseFloat(a.count)).map(
                          (i, j) => (
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  className="icheck"
                                  onChange={this.CheckAppliedFilter}
                                  name={i.filter_label}
                                  checked={
                                    !!this.state.checkedItems.get(
                                      i.filter_label
                                    )
                                  }
                                  disabled={
                                    this.state.FiltersData == null ? true : false
                                  }
                                />
                                <span className="cat_star" style={{textTransform:'capitalize'}}>
                                  {i.filter_label}
                                </span>{" "}
                                <small className="star_score">
                                  ({i.count})
                                </small>{" "}
                              </label>
                            </li>
                          )
                        )}
                      </ul>
                    </div>

                    <div className="filterRow" id="hlistpg_fr_star_category">
                    <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                      Property Type
                    </div>
                    <ul className="filterList">
                      {Object.values(this.state.FiltersData.property_type).sort((a, b) => parseFloat(b.count) - parseFloat(a.count)).map(
                        (i, j) => (
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                className="icheck"
                                onChange={this.CheckAppliedFilterProperty}
                                name={i.filter_label}
                                checked={
                                  !!this.state.checkedItems.get(i.filter_label)
                                }
                                disabled={
                                  this.state.OfferData == null ? true : false
                                }
                              />
                              <span className="cat_star" style={{textTransform:'capitalize'}}>{i.filter_label}</span>{" "}
                              <small className="star_score">({i.count})</small>{" "}
                            </label>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                  <div
                    className="filterRow"
                    id="hlistpg_fr_star_category"
                    style={{ marginBottom: 30 }}
                  >
                    <div className="latoBold font16 blackText appendBottom15 makeFlexNew hrtlCenter">
                      Price Per Night
                    </div>
                    <InputRange
                      maxValue={this.state.FiltersData.budget.maxPrice}
                      minValue={0}
                      value={this.state.valuePrice}
                      onChange={(value) => this.setState({ valuePrice: value })}
                      onChangeComplete={(value) => this.changePrice(value)}
                    />
                    <span className="minValue">
                      <FontAwesomeIcon
                        icon={faEuroSign}
                        style={{ fontSize: "13px", color: "#555" }}
                      ></FontAwesomeIcon>{" "}
                      {this.state.valuePrice.min}
                    </span>
                    <span className="maxValue">
                      <FontAwesomeIcon
                        icon={faEuroSign}
                        style={{ fontSize: "13px", color: "#555" }}
                      ></FontAwesomeIcon>{" "}
                      {this.state.valuePrice.max}
                    </span>
                  </div>
                    <div className="filterRow" id="hlistpg_fr_star_category" style={{ height: '500px', overflow: 'auto'}}>
                      <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                        Offer Name
                      </div>
                      <ul className="filterList">
                        {Object.values(this.state.FiltersData.offer_name)
                          .sort(
                            (a, b) => parseFloat(b.count) - parseFloat(a.count)
                          )
                          .map((i, j) => (
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  className="icheck"
                                  onChange={this.CheckAppliedFilterOfferName}
                                  name={i.filter_label}
                                  checked={
                                    !!this.state.checkedItems.get(
                                      i.filter_label
                                    )
                                  }
                                  disabled={
                                    this.state.OfferData == null ? true : false
                                  }
                                />
                                <span
                                  className="cat_star"
                                  style={{ textTransform: "capitalize", position: 'sticky' }}
                                >
                                  {i.filter_label}
                                </span>{" "}
                                <small className="star_score"  style={{ position: 'sticky' }}>
                                  ({i.count})
                                </small>{" "}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              </>)}
              
              {this.state.attract && (
                          <>
              {this.state.FiltersDataAttrac == null ? (
                <div className="filterWrap appendRight30">
                  

                  

                  <div className="filterRow" id="hlistpg_fr_star_category">
                    <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                      <Skeleton style={{ width: 240, height: 26 }} />
                    </div>
                    <ul className="filterList">
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                      <li>
                        <label>
                          <Skeleton style={{ width: 20, height: 20 }} />
                          <Skeleton
                            style={{ width: 190, height: 15, marginLeft: 10 }}
                          />
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : this.state.FiltersDataAttrac == "no-Filter" ? (
                <> </>
              ) : (
                <div className="filterWrap appendRight30">
                  <div className="appendBottom35">
                    {this.state.appliedFilterValAttrac.length > 0 ? (
                      <div className="appliedFiltersContainer">
                        <div
                          className="makeFlex spaceBetween end appendBottom20"
                          style={{
                            marginBottom: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="font20 blackText">
                            Applied Filters
                          </span>
                          {/* <a
                            className="latoBold font12 capText"
                            href="javascript:;"
                            onClick={() =>
                              this.RemoveMultipleFilter(
                                this.state.FiltersData.budget.maxPrice
                              )
                            }
                          >
                            Clear
                          </a> */}
                        </div>
                        <ul className="appliedFilters">
                          {this.state.appliedFilterValAttrac.map((i, j) => (
                            <li>
                              <span className="latoBold">{i.label}</span>
                              <a
                                href="javascript:;"
                                onClick={() => this.RemoveSingleFilterAttrac(i, j)}
                              ></a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p
                        className="makeFlex spaceBetween end"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <span
                          className="font20 blackText"
                          style={{ fontWeight: 500 }}
                        >
                          Select Filters
                        </span>
                      </p>
                    )}

                    <div className="filterRow" id="hlistpg_fr_star_category">
                      <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                        Offer Name
                      </div>
                      <ul className="filterList">
                        {Object.values(this.state.FiltersDataAttrac?.attraction?.offer_name)
                          .sort(
                            (a, b) => parseFloat(b.count) - parseFloat(a.count)
                          )
                          .map((i, j) => (
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  className="icheck"
                                  onChange={this.CheckAppliedFilterOfferNameAttraction}
                                  name={i.filter_label}
                                  checked={
                                    !!this.state.checkedItems.get(
                                      i.filter_label
                                    )
                                  }
                                  disabled={
                                    this.state.OfferDataAttrac == null ? true : false
                                  }
                                />
                                <span
                                  className="cat_star"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {i.filter_label}
                                </span>{" "}
                                <small className="star_score">
                                  ({i.count})
                                </small>{" "}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  
                  </div>
                </div>
              )}
              </>)}

              {this.state.package && (
                          <>
              {this.state.FiltersDataPackage == null ? (
                <div className="filterWrap appendRight30">
                  <div className="appendBottom35">
                    <p className="makeFlex spaceBetween end">
                      <span className="latoBold font20 blackText">
                        <Skeleton style={{ width: 240, height: 30 }} />
                      </span>
                    </p>

                    <div className="filterRow" id="hlistpg_fr_star_category">
                      <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                        <Skeleton style={{ width: 240, height: 26 }} />
                      </div>
                      <ul className="filterList">
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                  

                 
                </div>
              ) : this.state.FiltersDataPackage == "no-Filter" ? (
                <> </>
              ) : (
                <div className="filterWrap appendRight30">
                  <div className="appendBottom35">
                    {this.state.appliedFilterValPackage.length > 0 ? (
                      <div className="appliedFiltersContainer">
                        <div
                          className="makeFlex spaceBetween end appendBottom20"
                          style={{
                            marginBottom: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="font20 blackText">
                            Applied Filters
                          </span>
                          {/* <a
                            className="latoBold font12 capText"
                            href="javascript:;"
                            onClick={() =>
                              this.RemoveMultipleFilter(
                                this.state.FiltersData.budget.maxPrice
                              )
                            }
                          >
                            Clear
                          </a> */}
                        </div>
                        <ul className="appliedFilters">
                          {this.state.appliedFilterValPackage.map((i, j) => (
                            <li>
                              <span className="latoBold">{i.label}</span>
                              <a
                                href="javascript:;"
                                onClick={() => this.RemoveSingleFilterPackage(i, j)}
                              ></a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p
                        className="makeFlex spaceBetween end"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <span
                          className="font20 blackText"
                          style={{ fontWeight: 500 }}
                        >
                          Select Filters
                        </span>
                      </p>
                    )}

                    <div className="filterRow" id="hlistpg_fr_star_category">
                      <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                        Offer Name
                      </div>
                      <ul className="filterList">
                        {Object.values(this.state.FiltersDataPackage?.package?.offer_name)
                          .sort(
                            (a, b) => parseFloat(b.count) - parseFloat(a.count)
                          )
                          .map((i, j) => (
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  className="icheck"
                                  onChange={this.CheckAppliedFilterOfferNamePackage}
                                  name={i.filter_label}
                                  checked={
                                    !!this.state.checkedItems.get(
                                      i.filter_label
                                    )
                                  }
                                  disabled={
                                    this.state.OfferData == null ? true : false
                                  }
                                />
                                <span
                                  className="cat_star"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {i.filter_label}
                                </span>{" "}
                                <small className="star_score">
                                  ({i.count})
                                </small>{" "}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                   
                  </div>
                </div>
              )}
              </>)}

              {this.state.transfer && (
                          <>
              {this.state.FiltersDataTransfer == null ? (
                <div className="filterWrap appendRight30">
                  <div className="appendBottom35">
                    <p className="makeFlex spaceBetween end">
                      <span className="latoBold font20 blackText">
                        <Skeleton style={{ width: 240, height: 30 }} />
                      </span>
                    </p>

                    <div className="filterRow" id="hlistpg_fr_star_category">
                      <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                        <Skeleton style={{ width: 240, height: 26 }} />
                      </div>
                      <ul className="filterList">
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                 
                </div>
              ) : this.state.FiltersDataTransfer == "no-Filter" ? (
                <> </>
              ) : (
                <div className="filterWrap appendRight30">
                  <div className="appendBottom35">
                    {this.state.appliedFilterValTransfer.length > 0 ? (
                      <div className="appliedFiltersContainer">
                        <div
                          className="makeFlex spaceBetween end appendBottom20"
                          style={{
                            marginBottom: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="font20 blackText">
                            Applied Filters
                          </span>
                          {/* <a
                            className="latoBold font12 capText"
                            href="javascript:;"
                            onClick={() =>
                              this.RemoveMultipleFilter(
                                this.state.FiltersData.budget.maxPrice
                              )
                            }
                          >
                            Clear
                          </a> */}
                        </div>
                        <ul className="appliedFilters">
                          {this.state.appliedFilterValTransfer.map((i, j) => (
                            <li>
                              <span className="latoBold">{i.label}</span>
                              <a
                                href="javascript:;"
                                onClick={() => this.RemoveSingleFilterTransfer(i, j)}
                              ></a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p
                        className="makeFlex spaceBetween end"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <span
                          className="font20 blackText"
                          style={{ fontWeight: 500 }}
                        >
                          Select Filters
                        </span>
                      </p>
                    )}

                    <div className="filterRow" id="hlistpg_fr_star_category">
                      <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                        Offer Name
                      </div>
                      <ul className="filterList">
                        {Object.values(this.state.FiltersDataTransfer?.transfer?.offer_name)
                          .sort(
                            (a, b) => parseFloat(b.count) - parseFloat(a.count)
                          )
                          .map((i, j) => (
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  className="icheck"
                                  onChange={this.CheckAppliedFilterOfferNameTransfer}
                                  name={i.filter_label}
                                  checked={
                                    !!this.state.checkedItems.get(
                                      i.filter_label
                                    )
                                  }
                                  disabled={
                                    this.state.OfferData == null ? true : false
                                  }
                                />
                                <span
                                  className="cat_star"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {i.filter_label}
                                </span>{" "}
                                <small className="star_score">
                                  ({i.count})
                                </small>{" "}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  
                  </div>
                </div>
              )}
              </>)}

              {this.state.liveaboard && (
                          <>
              {this.state.FiltersDataLiveaboard == null ? (
                <div className="filterWrap appendRight30">
                  <div className="appendBottom35">
                    <p className="makeFlex spaceBetween end">
                      <span className="latoBold font20 blackText">
                        <Skeleton style={{ width: 240, height: 30 }} />
                      </span>
                    </p>

                    <div className="filterRow" id="hlistpg_fr_star_category">
                      <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                        <Skeleton style={{ width: 240, height: 26 }} />
                      </div>
                      <ul className="filterList">
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                        <li>
                          <label>
                            <Skeleton style={{ width: 20, height: 20 }} />
                            <Skeleton
                              style={{ width: 190, height: 15, marginLeft: 10 }}
                            />
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              ) : this.state.FiltersDataLiveaboard == "no-Filter" ? (
                <> </>
              ) : (
                <div className="filterWrap appendRight30">
                  <div className="appendBottom35">
                    {this.state.appliedFilterValLiveaboard.length > 0 ? (
                      <div className="appliedFiltersContainer">
                        <div
                          className="makeFlex spaceBetween end appendBottom20"
                          style={{
                            marginBottom: 10,
                            justifyContent: "space-between",
                          }}
                        >
                          <span className="font20 blackText">
                            Applied Filters
                          </span>
                          {/* <a
                            className="latoBold font12 capText"
                            href="javascript:;"
                            onClick={() =>
                              this.RemoveMultipleFilter(
                                this.state.FiltersData.budget.maxPrice
                              )
                            }
                          >
                            Clear
                          </a> */}
                        </div>
                        <ul className="appliedFilters">
                          {this.state.appliedFilterValLiveaboard.map((i, j) => (
                            <li>
                              <span className="latoBold">{i.label}</span>
                              <a
                                href="javascript:;"
                                onClick={() => this.RemoveSingleFilterLiveaboard(i, j)}
                              ></a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      <p
                        className="makeFlex spaceBetween end"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <span
                          className="font20 blackText"
                          style={{ fontWeight: 500 }}
                        >
                          Select Filters
                        </span>
                      </p>
                    )}

                    <div className="filterRow" id="hlistpg_fr_star_category">
                      <div className="latoBold font16 blackText appendBottom15 makeFlex hrtlCenter">
                        Offer Name
                      </div>
                      <ul className="filterList">
                        {Object.values(this.state.FiltersDataLiveaboard?.liveaboard?.offer_name)
                          .sort(
                            (a, b) => parseFloat(b.count) - parseFloat(a.count)
                          )
                          .map((i, j) => (
                            <li>
                              <label>
                                <input
                                  type="checkbox"
                                  className="icheck"
                                  onChange={this.CheckAppliedFilterOfferNameLiveaboard}
                                  name={i.filter_label}
                                  checked={
                                    !!this.state.checkedItems.get(
                                      i.filter_label
                                    )
                                  }
                                  disabled={
                                    this.state.OfferData == null ? true : false
                                  }
                                />
                                <span
                                  className="cat_star"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {i.filter_label}
                                </span>{" "}
                                <small className="star_score">
                                  ({i.count})
                                </small>{" "}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  
                  </div>
                </div>
              )}
              </>)}

              
              <div className="listingWrap">
                <p className="latoBold font20 blackText" style={{ marginBottom: 20 }}>
                  Recommended for You
                </p>
                <div className="container container-custom margin_30_95">
                  <div className="SuperFlex perfectCenter">
                    <ul className="SuperFlex capText superOffersTab" style={{marginLeft: '-19%'}}>
                      <li
                        className={this.state.accomo && "active latoBlack"}
                        onClick={() => this.getSpecialOff("Accommodations")} 
                      >
                        <a className="SuperFlex hrtlCenter column darkGreyText">
                          <span>Accommodations ({ this.state.OfferData !=null &&(this.state.OfferData.length) })</span>
                        </a>
                      </li>
                      <li
                        className={this.state.attract && "active latoBlack"}
                        onClick={() => this.getSpecialOff("Attractions")}
                      >
                        <a className="SuperFlex hrtlCenter column darkGreyText">
                          <span> Attractions & Things To Do </span>
                        </a>
                      </li>
                      <li
                        className={this.state.package && "active latoBlack"}
                        onClick={() => this.getSpecialOff("Packages")}
                      >
                        <a className="SuperFlex hrtlCenter column darkGreyText">
                          <span>Package </span>
                        </a>
                      </li>
                      <li
                        className={this.state.transfer && "active latoBlack"}
                        onClick={() => this.getSpecialOff("Transfers")}
                      >
                        <a className="SuperFlex hrtlCenter column darkGreyText">
                          <span> Land & Ferry Transfers </span>
                        </a>
                      </li>
                      <li
                        className={this.state.liveaboard && "active latoBlack"}
                        onClick={() => this.getSpecialOff("Liveaboard")}
                      >
                        <a className="SuperFlex hrtlCenter column darkGreyText">
                          <span> Liveaboard </span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div
                    className="SuperlandingCardSlider superOffers"
                    style={{ direction: "ltr", marginTop: "20px" }}
                  >
                    {this.state.OfferData === null ? (
                      <>
                        <TourListSkeleton />
                        <TourListSkeleton />
                        <TourListSkeleton />
                      </>
                    ) : (
                      <>
                        {this.state.accomo && (
                          <>
                            {this.state.OfferData==
                            null ? (
                              <>
                                <div className="Container-Tile-NoHotel">
                                  <img
                                    src={"images/noSpecialOffer.png"}
                                    style={{ margin: "0 auto" }}
                                  />
                                  <h3
                                    style={{
                                      margin: "0 auto",
                                      color: "#000",
                                      fontSize: "32px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Sold Out!
                                  </h3>
                                  <p
                                    class="font16 grayText appendTop10 appendBottom20"
                                    style={{ margin: "8px auto" }}
                                  >
                                    No SpecialOffer for Accommodations .
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                <TourOfferListingComponent
                                  OfferData={this.state?.OfferData}/>
                                   
                              </>
                            )}
                          </>
                        )}{" "}
                      </>
                    )}



                {this.state.ExperiencesdataAttraction === null ? (
                      <>
                        <TourListSkeleton />
                        <TourListSkeleton />
                        <TourListSkeleton />
                      </>
                    ) : (
                      <>
                        {this.state.attract && (
                          <>
                            {(this.state.ExperiencesdataAttraction != null && 
                              this.state.ExperiencesdataAttraction?.specialOffersExperiences
                                 =="no_special_offers_exp") ? (
                              <>
                                <div className="Container-Tile-NoHotel">
                                  <img
                                    src={"images/noSpecialOffer.png"}
                                    style={{ width: 280, margin: "0 auto" }}
                                  />
                                  <h3
                                    style={{
                                      margin: "0 auto",
                                      color: "#000",
                                      fontSize: "32px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Sold Out!
                                  </h3>
                                  <p
                                    class="font16 grayText appendTop10 appendBottom20"
                                    style={{ margin: "8px auto" }}
                                  >
                                    No SpecialOffer for Attractions & Things To
                                    Do.
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                {
                                 this.state.ExperiencesdataAttraction!=null &&  Array.isArray(this.state.ExperiencesdataAttraction) && 
                                  this.state.ExperiencesdataAttraction.map(
                                    (item, index) => {
                                     
                                      if(this.state.ExperiencesdataAttraction[index][0]!='no_Attraction_data'){
                                        return (
                                              <>
                                                {Object.values(this.state.ExperiencesdataAttraction[index][0]).map((key1, index) => {
                                                 // console.log("ItNorrrem",key1);
                                              return(
                                               
                                                  <AttractionOfferListingComponent
                                                    tour_gallery={key1.tour_gallery}
                                                    tour_description={key1.tour_description}
                                                    tour_price={key1.tour_price}
                                                    tour_data={key1.tour_data}
                                                    dateSelected={this.state.selectActivityDayNew}
                                                    tour_day={key1.tour_day}
                                                    tour_time={key1.tour_timing}
                                                    tour_exclusion={key1.tour_exclusion}
                                                    specialOffer={key1.specialOffer}
                                                    forMeal={key1.forMeal}
                                                    tour_price_details={key1.tour_price_details}
                                                    tour_price_schedule={key1.tour_price_schedule}
                                                    tour_price_schedule_new={key1.tour_price_schedule_new}
                                                  />

                                                )})}
                                              </>
                                            );
                                          }
                                      }
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}



                  {this.state.ExperiencesdataPackage === null ? (
                      <>
                        <TourListSkeleton />
                        <TourListSkeleton />
                        <TourListSkeleton />
                      </>
                    ) : (
                      <>
                        {this.state.package && (
                          <>
                            {(this.state.ExperiencesdataPackage != null &&
                              this.state.ExperiencesdataPackage.specialOffersExperiences
                              =="no_special_offers_exp") ? (
                              <>
                                <div className="Container-Tile-NoHotel">
                                  <img
                                    src={"images/noSpecialOffer.png"}
                                    style={{ width: 280, margin: "0 auto" }}
                                  />
                                  <h3
                                    style={{
                                      margin: "0 auto",
                                      color: "#000",
                                      fontSize: "32px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Sold Out!
                                  </h3>
                                  <p
                                    class="font16 grayText appendTop10 appendBottom20"
                                    style={{ margin: "8px auto" }}
                                  >
                                    No SpecialOffer for Package.
                                  </p>
                                </div>
                              </>
                            ) : (
                             
                              <>
                                 {this.state.ExperiencesdataPackage!=null &&  Array.isArray(this.state.ExperiencesdataPackage) && 
                                  this.state.ExperiencesdataPackage.map(
                                    (item, index) => {
                                      if(this.state.ExperiencesdataPackage[index][0]!='no_package_data'){
                                        return (
                                              <>
                                                {Object.values(this.state.ExperiencesdataPackage[index][0]).map((key1, index) => (
                                                  <PackageOfferListingComponent
                                                    tour_gallery={key1.tour_gallery}
                                                    tour_description={key1.tour_description}
                                                    tour_price={key1.tour_price}
                                                    tour_data={key1.tour_data}
                                                    dateSelected={this.state.selectActivityDayNew}
                                                    tour_day={key1.tour_day}
                                                    tour_time={key1.tour_timing}
                                                    tour_exclusion={key1.tour_exclusion}
                                                    specialOffer={key1.specialOffer}
                                                    forMeal={key1.forMeal}
                                                    tour_price_details={key1.tour_price_details}
                                                    tour_price_schedule={key1.tour_price_schedule}
                                                    tour_price_schedule_new={key1.tour_price_schedule_new}
                                                  />
                                                ))}
                                              </>
                                            );
                                          }
                                     
                                    }
                                  )}
                              </>
                            )}
                          </>
                        )}{" "}
                      </>
                    )}
                  
                    {this.state.ExperiencesdataTransfer === null ? (
                      <>
                        <TourListSkeleton />
                        <TourListSkeleton />
                        <TourListSkeleton />
                      </>
                    ) : (
                      <>
                        {this.state.transfer && (
                          <>
                             {(this.state.ExperiencesdataTransfer != null &&
                              this.state.ExperiencesdataTransfer.specialOffersExperiences
                              =="no_special_offers_exp") ? (
                              <>
                                <div className="Container-Tile-NoHotel">
                                  <img
                                    src={"images/noSpecialOffer.png"}
                                    style={{ width: 280, margin: "0 auto" }}
                                  />
                                  <h3
                                    style={{
                                      margin: "0 auto",
                                      color: "#000",
                                      fontSize: "32px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Sold Out!
                                  </h3>
                                  <p
                                    class="font16 grayText appendTop10 appendBottom20"
                                    style={{ margin: "8px auto" }}
                                  >
                                    No SpecialOffer for Transfer .
                                  </p>
                                </div>
                              </>
                            ) : (
                            
                              <>
                             {this.state.ExperiencesdataTransfer!=null &&  Array.isArray(this.state.ExperiencesdataTransfer) && 
                                  this.state.ExperiencesdataTransfer.map(
                                    (item, index) => {
                                      if(this.state.ExperiencesdataTransfer[index][0]!='no_transfer_data'){
                                        return (
                                              <>
                                                {Object.values(this.state.ExperiencesdataTransfer[index][0]).map((key1, index) => (
                                                <TransfersOfferListingComponent
                                                  tour_gallery={key1.tour_gallery}
                                                  tour_description={key1.tour_description}
                                                  tour_price={key1.tour_price}
                                                  tour_data={key1.tour_data}
                                                  dateSelected={this.state.selectActivityDayNew}
                                                  tour_day={key1.tour_day}
                                                  tour_time={key1.tour_timing}
                                                  tour_exclusion={key1.tour_exclusion}
                                                  specialOffer={key1.specialOffer}
                                                  forMeal={key1.forMeal}
                                                  tour_price_details={key1.tour_price_details}
                                                  tour_price_schedule={key1.tour_price_schedule}
                                                  tour_price_schedule_new={key1.tour_price_schedule_new}
                                                />
                                              ))}
                                            </>
                                          );
                                        }
                                   
                                  }
                                )}
                            </>
                            )}{" "}
                          </>
                        )}{" "}
                      </>
                    )}

                    {this.state.ExperiencesdataLiveaboard === null ? (
                      <>
                        <TourListSkeleton />
                        <TourListSkeleton />
                        <TourListSkeleton />
                      </>
                    ) : (
                      <>
                        {this.state.liveaboard && (
                          <>
                             {(this.state.ExperiencesdataLiveaboard != null &&
                              this.state.ExperiencesdataLiveaboard.specialOffersExperiences
                              =="no_special_offers_exp") ? (
                              
                              <>
                                <div className="Container-Tile-NoHotel">
                                  <img
                                    src={"images/noSpecialOffer.png"}
                                    style={{ width: 280, margin: "0 auto" }}
                                  />
                                  <h3
                                    style={{
                                      margin: "0 auto",
                                      color: "#000",
                                      fontSize: "32px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Sold Out!
                                  </h3>
                                  <p
                                    class="font16 grayText appendTop10 appendBottom20"
                                    style={{ margin: "8px auto" }}
                                  >
                                    No SpecialOffer for LiveAboard .
                                  </p>
                                </div>
                              </>
                            ) : (
                             
                              <>
                             {this.state.ExperiencesdataLiveaboard!=null &&  Array.isArray(this.state.ExperiencesdataLiveaboard) && 
                                  this.state.ExperiencesdataLiveaboard.map(
                                    (item, index) => {
                                  
                                      if(this.state.ExperiencesdataLiveaboard[index][0]!='no_liveaboard_data'){
                                        return (
                                              <>
                                                {Object.values(this.state.ExperiencesdataLiveaboard[index][0]).map((key1, index) => (
                                                <LiveaboardOfferListingComponent
                                                  tour_gallery={key1.tour_gallery}
                                                  tour_description={key1.tour_description}
                                                  tour_price={key1.tour_price}
                                                  tour_data={key1.tour_data}
                                                  dateSelected={this.state.selectActivityDayNew}
                                                  tour_day={key1.tour_day}
                                                  tour_time={key1.tour_timing}
                                                  tour_exclusion={key1.tour_exclusion}
                                                  specialOffer={key1.specialOffer}
                                                  forMeal={key1.forMeal}
                                                  tour_price_details={key1.tour_price_details}
                                                  tour_price_schedule={key1.tour_price_schedule}
                                                  tour_price_schedule_new={key1.tour_price_schedule_new}
                                                />
                                              ))}
                                            </>
                                          );
                                        }
                                   
                                  }
                                )}
                            </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
