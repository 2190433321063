import React, { Component } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import "./Superoffers.css";
import { Base64 } from "js-base64";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  getSpecialOffersForHomeExperiences,
  getSpecialOfferEjunifer,
  Tripurl, 
  SupplierUrl
} from "./axios";

import ReactTooltip from "react-tooltip";

export default class Responsive extends Component {
  constructor(props) {
    super(props);
    const { cookies } = props;

    this.state = {
      nav: false,
      selected: "Accommodations",
      modalIsOfferOpen: false,
      OfferDetailsClass: "containerOffer",
      OfferData: props.OfferData || null,
      accomo: true,
      attract: false,
      package: false,
      transfer: false,
      liveaboard: false,
      Experiencesdata: null,
      Ejuniferdata: null,
      loginData : props?.logindata || null,
    };
  }
  openOfferModal = () => {
    this.setState({ modalIsOfferOpen: true });
  };

  closeOfferModal = () => {
    this.setState({ modalIsOfferOpen: false });
  };

  componentDidMount() {
    getSpecialOffersForHomeExperiences().then(
      (data) => {
        this.setState({ Experiencesdata: data });
      },
      (error) => {}
    );
    getSpecialOfferEjunifer().then(
      (data) => {
        this.setState({ Ejuniferdata: data });
      },
      (error) => {}
    );
  }

  getSpecialOff = (data) => {
    if (data == "Accommodations") {
      this.setState({ accomo: true });
      this.setState({ liveaboard: false });
      this.setState({ transfer: false });
      this.setState({ package: false });
      this.setState({ attract: false });
    } else if (data == "Attractions") {
      this.setState({ attract: true });
      this.setState({ accomo: false });
      this.setState({ liveaboard: false });
      this.setState({ transfer: false });
      this.setState({ package: false });
    } else if (data == "Packages") {
      this.setState({ package: true });
      this.setState({ attract: false });
      this.setState({ accomo: false });
      this.setState({ liveaboard: false });
      this.setState({ transfer: false });
    } else if (data == "Transfers") {
      this.setState({ transfer: true });
      this.setState({ package: false });
      this.setState({ attract: false });
      this.setState({ accomo: false });
      this.setState({ liveaboard: false });
    } else if (data == "Liveaboard") {
      this.setState({ liveaboard: true });
      this.setState({ transfer: false });
      this.setState({ package: false });
      this.setState({ attract: false });
      this.setState({ accomo: false });
    }
  };

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const getAmount = (data, Price, forMeal) => {
      const data12 = Object.entries(forMeal);
      const rateId = data12.filter(([key, value]) => value == Price);

      const Offer1 = data.filter(
        (item, key) =>
          item != "no_offer" && item.tourSpecial.rate_plan_id == rateId[0][0]
      );

      if (Offer1.length > 0) {
        let result = [];

        Offer1.map((i, j) => {
          if (i.combineoffer == "nocombine") {
            if (i.specialdata.discount_id == 2) {
              const disA = i.specialdata.rate_amount;
              result.push(+disA);
            } else {
              const disA = Price - i.specialdata.rate_amount;
              result.push(+disA);
            }
          } else {
            if (i.specialdata.discount_id == 2) {
              const disA = i.specialdata.rate_amount;
              result.push(+disA);
            } else {
              const disA = Price - i.specialdata.rate_amount;
              result.push(+disA);
            }
          }
        });

        const Amount = result.reduce((a, v) => a + v, 0);

        const balanceAmount =
          Price - (Math.round(Amount * Price) / 100).toFixed(2);
        return (
          <>
            <p>
              <span className="font-weight-bold text-lowercase from-price-text">
                From{" "}
              </span>
            </p>
            <p
              className="grayText font16 lineThrough appendBottom5"
              id="hlistpg_hotel_cut_price"
            >
              <span>€</span>
              {Price}
            </p>

            <p
              className="latoBlack font16 blackText appendBottom5"
              id="hlistpg_hotel_shown_price"
            >
              <span>€</span>
              {balanceAmount.toFixed(2)}
            </p>
            <p class="makeFlex hrtlCenter right appendBottom10 directionRtl">
              <span class="redText font12">
                <span>Save </span>
                <span class="latoBold" id="hlistpg_hotel_saved_price">
                  <span>€</span>
                  {(Math.round(Amount * Price) / 100).toFixed(2)}
                </span>
              </span>
              <span class="spriteNew discountIcon relative appendLeft5">
                <span
                  class="latoBold font11 whiteText discountText"
                  id="hlistpg_hotel_saved_price_percentage"
                  style={{
                    lineHeight: "15px",
                    paddingRight: "5px",
                  }}
                >
                  {parseInt(Amount)}%
                </span>
              </span>
            </p>
          </>
        );
      } else {
        return (
          <>
            <p>
              <span className="font-weight-bold text-lowercase from-price-text">
                From{" "}
              </span>
            </p>
            <p
              className="latoBlack font16 blackText appendBottom5"
              id="hlistpg_hotel_shown_price"
            >
              <span>€</span>
              {Price}
            </p>
          </>
        );
      }
    };
    const getAmountHotel = (data) => {
      const data12 = Object.entries(data.forMeal);
      const rateId = data12.filter(([key, value]) => value == data.amount);

      const Offer1 = data.specialOffer.filter(
        (item, key) =>
          item != "no_offer" && item.roomSpecial.rate_plan_id == rateId[0][0]
      );

      if (Offer1.length > 0) {
        let result = [];

        Offer1.map((i, j) => {
          if (i.combineoffer == "nocombine") {
            if (i.specialdata.spl_offer_type_id == 1) {
              const dis =
                data.amount * parseInt(i.specialdata.pay_stay_length) -
                data.amount * parseInt(i.specialdata.pay_stay_value);

              const disA =
                (dis /
                  (data.amount * parseInt(i.specialdata.pay_stay_length))) *
                100;

              result.push(disA);
            } else {
              if (i.specialdata.discount_in == 2) {
                const disA = i.specialdata.rate_discount;
                result.push(+disA);
              } else {
                const disA = data.amount - i.specialdata.rate_discount;
                result.push(+disA);
              }
            }
          } else {
            if (i.specialdata.spl_offer_type_id == 1) {
              const dis =
                data.amount * parseInt(i.specialdata.pay_stay_length) -
                data.amount * parseInt(i.specialdata.pay_stay_value);
              const disA =
                (dis /
                  (data.amount * parseInt(i.specialdata.pay_stay_length))) *
                100;
              result.push(disA);
            } else {
              if (i.specialdata.discount_in == 2) {
                const disA = i.specialdata.rate_discount;
                result.push(+disA);
              } else {
                const disA = data.amount - i.specialdata.rate_discount;
                result.push(+disA);
              }
            }
          }
        });

        const Amount = result.reduce((a, v) => a + v, 0);

        const balanceAmount =
          data.amount - (Math.round(Amount * data.amount) / 100).toFixed(2);

        return (
          <>
            <p class="makeFlex hrtlCenter right appendBottom10 directionRtl">
              <span class="redText font12">
                <span>Save </span>
                <span class="latoBold" id="hlistpg_hotel_saved_price">
                  <span>€</span>
                  {(Math.round(Amount * data.amount) / 100).toFixed(2)}
                </span>
              </span>
              <span class="spriteNew discountIcon relative appendLeft5">
                <span
                  class="latoBold font11 whiteText discountText"
                  id="hlistpg_hotel_saved_price_percentage"
                  style={{
                    lineHeight: "15px",
                    paddingRight: "5px",
                  }}
                >
                  {parseInt(Amount)}%
                </span>
              </span>
            </p>
            <p
              className="grayText font16 lineThrough appendBottom5"
              id="hlistpg_hotel_cut_price"
            >
              <span>€</span>
              {data.amount.toFixed(2)}
            </p>
            <p>
              <span className="font-weight-bold text-lowercase from-price-text">
                From{" "}
              </span>
            </p>
            <p
              className="latoBlack font16 blackText appendBottom5"
              id="hlistpg_hotel_shown_price"
            >
              <span>€</span>
              {balanceAmount.toFixed(2)}
            </p>
          </>
        );
      } else {
        return (
          <>
            <p>
              <span className="font-weight-bold text-lowercase from-price-text">
                From{" "}
              </span>
            </p>
            <p
              className="latoBlack font16 blackText appendBottom5"
              id="hlistpg_hotel_shown_price"
            >
              <span>€</span>
              {data.amount.toFixed(2)}
            </p>
          </>
        );
      }
    };

    return (
      
      <>
        <div
          className=" appendBottom20 superOfferSection  sticky-scroll"
          id="Offers_Listing"
        >
          <div className="container">
            <div className="SuperlandingCard">
              <div className="blackText latoBold">
                <h2>Best Deals and Offers</h2>
                <div className="SuperFlex perfectCenter">
                  <ul className="SuperFlex superOffersTab">
                    <li
                      className={this.state.accomo && "active latoBlack"}
                      onClick={() => this.getSpecialOff("Accommodations")}
                    >
                      <a className="SuperFlex hrtlCenter column darkGreyText">
                        <span>Accommodations ({this.state.Ejuniferdata?.specialOffers != null && this.state.Ejuniferdata?.specialOffers !='no_special_offers'
                         && this.state.Ejuniferdata?.specialOffers &&(this.state.Ejuniferdata?.specialOffers.length)})</span>
                      </a>
                    </li>
                    <li
                      className={this.state.attract && "active latoBlack"}
                      onClick={() => this.getSpecialOff("Attractions")}
                    >
                      <a className="SuperFlex hrtlCenter column darkGreyText">
                        <span>
                          {" "}
                          Attractions & Things To Do (
                          {this.state.Experiencesdata != null &&
                            this.state.Experiencesdata
                              .specialOffersExperiences !=
                              "no_special_offers_exp" ?
                            this.state.Experiencesdata.Attractionspecialoffers.filter(
                              (item) => item[0] !== "no_Attraction_data"
                            ).length:0}
                          )
                        </span>
                      </a>
                    </li>
                    <li
                      className={this.state.package && "active latoBlack"}
                      onClick={() => this.getSpecialOff("Packages")}
                    >
                      <a className="SuperFlex hrtlCenter column darkGreyText">
                        <span>
                          {" "}
                          Packages (
                          {this.state.Experiencesdata != null &&
                            this.state.Experiencesdata
                              .specialOffersExperiences !=
                              "no_special_offers_exp" ?
                            this.state.Experiencesdata.Packagespecialoffers.filter(
                              (item) => item[0] !== "no_package_data"
                            ).length:0}
                          )
                        </span>
                      </a>
                    </li>
                    <li
                      className={this.state.transfer && "active latoBlack"}
                      onClick={() => this.getSpecialOff("Transfers")}
                    >
                      <a className="SuperFlex hrtlCenter column darkGreyText">
                        <span>
                          {" "}
                          Land & Ferry Transfers (
                          {this.state.Experiencesdata != null &&
                            this.state.Experiencesdata
                              .specialOffersExperiences !=
                              "no_special_offers_exp" ?
                            this.state.Experiencesdata.Transferspecialoffers.filter(
                              (item) => item[0] !== "no_transfer_data"
                            ).length:0}
                          )
                        </span>
                      </a>
                    </li>
                    <li
                      className={this.state.liveaboard && "active latoBlack"}
                      onClick={() => this.getSpecialOff("Liveaboard")}
                    >
                      <a className="SuperFlex hrtlCenter column darkGreyText">
                        <span>
                          {" "}
                          Liveaboard (
                          {this.state.Experiencesdata != null &&
                            this.state.Experiencesdata
                              .specialOffersExperiences !=
                              "no_special_offers_exp" ?
                            this.state.Experiencesdata.LiveAboardspecialoffers.filter(
                              (item) => item[0] !== "no_liveaboard_data"
                            ).length:0}
                          )
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="SuperlandingCardSlider superOffers"
                style={{ direction: "ltr" }}
              >
                {this.state.accomo && (
                  <>
                    {this.state.Ejuniferdata == null ? (
                      <>
                        <div style={{ width: 200, margin: "0 auto" }}>
                          <img
                            src="images/noSpecialOffer.png"
                            style={{ width: "100%" }}
                          />
                          <h6 style={{ fontSize: 15, textAlign: "center" }}>
                            No Special Offer for Accommodations{" "}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <Slider {...settings}>
                        {this.state.Ejuniferdata.specialOffers != null && this.state.Ejuniferdata.specialOffers !='no_special_offers'
                         && this.state.Ejuniferdata.specialOffers.map(
                            (item, index) => {
                              let querySearch = [
                                {
                                  adultCounter: 2,
                                  childCounter: 0,
                                  roomCounter: 1,
                                  isExpanded: true,
                                },
                              ];

                              let JsonState = {
                                defaultZoneval: item.property_name,
                                startDate: moment(item.checkin_date).format(
                                  "Y-MM-DD"
                                ),
                                endDate: moment(item.checkout_date).format(
                                  "Y-MM-DD"
                                ),
                                guestCombination: querySearch,
                                accopage: 1,
                                accosize: 20,
                              };
                              const hotelArr = JSON.stringify(JsonState);
                              return (
                                <div
                                  className="slideItem"
                                  style={{ display: "inline-block" }}
                                >
                                  <div className="SuperFlex column itemCard soCardHeight pointer">
                                    <div className="SuperFlex cardInnerInfo">
                                      <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                        <div className="itemImgWrap makeRelative">
                                          <a
                                            href={
                                              "/hotelDetails?hotelid=" +
                                              Base64.btoa(item.property_id) +
                                              "&query=" +
                                              encodeURIComponent(hotelArr)
                                            }
                                            target="_blank"
                                          >
                                            {/* <img
                                      src={
                                        item.property_gallery[0].image
                                      } */}
                                            <img
                                              src={
                                                this.state.Ejuniferdata
                                                  .specialOffersImage[index][0]
                                                  .image
                                              }
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="itemDesc latoBlack">
                                        <a
                                          href={
                                            "/hotelDetails?hotelid=" +
                                            Base64.btoa(item.property_id) +
                                            "&query=" +
                                            encodeURIComponent(hotelArr)
                                          }
                                          style={{fontSize: '14px' }}
                                          target="_blank"
                                          className="blackText font18 lineHeight22 appendBottom10 "
                                        >
                                          {item.property_name.substring(0, 30)}
                                        </a>
                                        <span className="redBdr appendBottom10">
                                          <em></em>
                                        </span>
                                        <div className="Small-Text">
                                            <p className="address" style={{ color: 'gray', fontSize: '10px' }}> { item.address_line_1.substring(0, 40)}</p>
                                          </div>

                                        {parseInt(item.PercentageAm) == 0 ? (
                                          <>
                                            <p>
                                              <span className="font-weight-bold text-lowercase from-price-text">
                                                From{" "}
                                              </span>
                                            </p>
                                            <p
                                              className="latoBlack font16 blackText appendBottom5"
                                              id="hlistpg_hotel_shown_price"
                                            >
                                              <span>€</span>
                                              {(
                                                Math.round(item.Amount * 100) /
                                                100
                                              ).toFixed(2)}
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <div className="containerFrom appendBottom5" style={{display: 'flex'}}>
                                            <p>
                                              <span className="font-weight-bold from-price-text">
                                               Per Night From{" "}
                                              </span>
                                            </p>
                                           
                                        </div>
                                        <div className="containerFromAmount" style={{display: 'flex'}}>
                                            <p
                                              className="grayText font16 lineThrough appendBottom5"
                                              id="hlistpg_hotel_cut_price"
                                            >
                                              <span>€</span>
                                              {(
                                                Math.round(item.Amount * 100) /
                                                100
                                              ).toFixed(2)}
                                            </p>
                                            <p
                                              className="latoBlack font16 blackText appendBottom5"
                                              id="hlistpg_hotel_shown_price" style={{marginLeft: '5px'}}
                                            >
                                              <span>€</span>
                                              {(
                                                Math.round(
                                                  item.HotelOfferAmount * 100
                                                ) / 100
                                              ).toFixed(2)}
                                            </p>
                                            </div>
                                            <p class="hrtlCenter appendBottom10 directionRtl">
                                              <span class="redText font12">
                                                <span>Save </span>
                                                <span
                                                  class="latoBold"
                                                  id="hlistpg_hotel_saved_price"
                                                >
                                                  <span>€</span>
                                                  {(
                                                    Math.round(
                                                      item.HotelOfferAmNew * 100
                                                    ) / 100
                                                  ).toFixed(2)}
                                                </span>
                                              </span>
                                              <span class="spriteNew discountIcon relative appendLeft5">
                                                <span
                                                  class="latoBold font11 whiteText discountText"
                                                  id="hlistpg_hotel_saved_price_percentage"
                                                  style={{
                                                    lineHeight: "15px",
                                                    paddingRight: "5px",
                                                  }}
                                                >
                                                  {parseInt(item.PercentageAm)}%
                                                </span>
                                              </span>
                                            </p>
                                          </>
                                        )}
                                        {/* <p
                                          className="blackText appendBottom5"
                                          style={{ fontSize: 10}}
                                        >
                                          Per Night
                                        </p> */}
                                       
                                       
                                    </div>
                                    </div>
                                    <p className="SuperFlex hrtlCenter appendBottom10" style={{ margin: 'auto',marginBottom: '10px'}}>
                          <span className="SuperFlex flexOne spaceBetween font16">
                          
                                <div className="Small-Text">
                                            <p className="address" style={{ color: 'gray', fontSize: '13px' }}> Offer Available From {moment(item.checkin_date).format(
                                  "DD MMM Y"
                                )} *Condition Apply</p>
                                          </div>
                          </span>
                        </p>
                                     
                                    {/* <p className="SuperFlex hrtlCenter">
                          <span className="SuperFlex flexOne spaceBetween font13">
                            <a href={"/tourofferlisting"} target="_blank" className="latoBlack pushRight">Know More</a>
                          </span>
                        </p> */}
                                    <div class="persuasion ">
                                      <div class="persuasion__item pc__cashbackDeal">
                                        <span class="sprite infoIconGreen"></span>
                                        <span data-tip data-for={item.id}>
                                          {item.HotelOfferName.substring(0, 40)}{" "}
                                          *Offer
                                        </span>
                                      </div>

                                      <div className="customTooltip">
                                        <ReactTooltip
                                         place="right"
                                         // offset = {{ left: -80 }}
                                          type="light"
                                          id={item.id}
                                          className="overrideMe"
                                          data-html={true}
                                          // insecure={true}
                                          // multiline={true}
                                        >
                                          <div className="reactpoopup">
                                            <div className="px-0">
                                              <label
                                                for="rb0"
                                                className="mb-0 w-100 enabled"
                                              >
                                                <div
                                                  className="tour-grade-title h3 mb-0 font-weight-medium tg-title"
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  {" "}
                                                  {item.multipple_offer.replace(
                                                    ", ",
                                                    ", "
                                                  )}
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        </ReactTooltip>
                                      </div>
                                    </div>
                                    <div class="title__placeHolder font12 pc__bottomBox"></div>
                                  </div>
                                </div>
                              );
                              //   }
                            }
                          )}
                        {this.state.OfferData != null && this.state.OfferData.specialOffers != 'no_special_offers' &&
                          this.state.OfferData.data.map((item, index) => {
                            if (item != "nodata") {
                              let checkin = new Date();
                              let dateString = new Date(); // date string
                              let actualDate = new Date(dateString); // convert to actual date
                              let checkout = new Date(
                                actualDate.getFullYear(),
                                actualDate.getMonth(),
                                actualDate.getDate() + 1
                              );
                              let querySearch = [
                                {
                                  adultCounter: 1,
                                  childCounter: 0,
                                  roomCounter: 1,
                                  isExpanded: true,
                                },
                              ];

                              let JsonState = {
                                defaultZoneval: this.state.OfferData
                                  .specialOffers[index].property_name,
                                startDate: moment(checkin).format("Y-MM-DD"),
                                endDate: moment(checkout).format("Y-MM-DD"),
                                guestCombination: querySearch,
                                accopage: 1,
                                accosize: 20,
                              };
                              const hotelArr = JSON.stringify(JsonState);
                              return (
                                <div
                                  className="slideItem"
                                  style={{ display: "inline-block" }}
                                >
                                  <div className="SuperFlex column itemCard soCardHeight pointer">
                                    <div className="SuperFlex cardInnerInfo">
                                      <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                        <div className="itemImgWrap makeRelative">
                                          <a
                                            href={
                                              "/hotelDetails?hotelid=" +
                                              Base64.btoa(
                                                this.state.OfferData
                                                  .specialOffers[index]
                                                  .property_id
                                              ) +
                                              "&query=" +
                                              encodeURIComponent(hotelArr)
                                            }
                                            target="_blank"
                                          >
                                            <img
                                              src={
                                                SupplierUrl+"/uploads/property_gallery_main/" +
                                                this.state.OfferData
                                                  .specialOffersImage[index][0]
                                                  .image
                                              }
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="itemDesc latoBlack">
                                        <a
                                          href={
                                            "/hotelDetails?hotelid=" +
                                            Base64.btoa(
                                              this.state.OfferData
                                                .specialOffers[index]
                                                .property_id
                                            ) +
                                            "&query=" +
                                            encodeURIComponent(hotelArr)
                                          }
                                          style={{fontSize: '14px' }}
                                          target="_blank"
                                          className="blackText font18 lineHeight22 appendBottom10 "
                                        >
                                          {this.state.OfferData.specialOffers[
                                            index
                                          ].property_name.substring(0, 30)}
                                        </a>
                                        <span className="redBdr appendBottom10">
                                          <em></em>
                                        </span>

                                        <div className="Small-Text">
                                            <p className="address" style={{ color: 'gray', fontSize: '10px' }}> { this.state.OfferData.specialOffers[
                                            index
                                          ].address_line_1.substring(0, 40)}</p>
                                          </div>

                                        {getAmountHotel(item)}
                                        <p
                                          className="blackText appendBottom5"
                                          style={{ fontSize: 10 }}
                                        >
                                          Per Night
                                        </p>
                                      </div>
                                    </div>

                                    <div class="persuasion ">
                                      <div class="persuasion__item pc__cashbackDeal">
                                        <span class="sprite infoIconGreen"></span>
                                        <span>
                                          {this.state.OfferData.specialOffers[
                                            index
                                          ].offer_name.substring(0, 40)}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="title__placeHolder font12 pc__bottomBox"></div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </Slider>
                    )}
                  </>
                )}

                {this.state.attract && (
                  <>
                    {(this.state.Experiencesdata != null &&
                      this.state.Experiencesdata.Attractionspecialoffers ==
                        "no_Attraction_data") ||
                    this.state.Experiencesdata.specialOffersExperiences ==
                      "no_special_offers_exp" ? (
                      <>
                        <div style={{ width: 200, margin: "0 auto" }}>
                          <img
                            src="images/noSpecialOffer.png"
                            style={{ width: "100%" }}
                          />
                          <h6 style={{ fontSize: 15, textAlign: "center" }}>
                            No Special Offer for Attractions & Things To Do{" "}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <Slider {...settings}>
                        {this.state.Experiencesdata != null &&
                          this.state.Experiencesdata.specialOffersExperiences !=
                            "no_special_offers_exp" &&
                          Object.values(
                            this.state.Experiencesdata.specialOffersExperiences
                          ).map((item, index) => {
                            //console.log(item);
                            //console.log(this.state.Experiencesdata);

                            if (
                              this.state.Experiencesdata
                                .Attractionspecialoffers[index] !=
                              "no_Attraction_data"
                            ) {
                              const specialData = Object.values(
                                this.state.Experiencesdata
                                  .Attractionspecialoffers[index]
                              )[0][item.tour_id].specialOffer;
                              const Price = Object.values(
                                this.state.Experiencesdata
                                  .Attractionspecialoffers[index]
                              )[0][item.tour_id].tour_price;
                              const forMeal = Object.values(
                                this.state.Experiencesdata
                                  .Attractionspecialoffers[index]
                              )[0][item.tour_id].forMeal;
                              const TourData = Object.values(
                                this.state.Experiencesdata
                                  .Attractionspecialoffers[index]
                              )[0][item.tour_id].tour_data;
                              return (
                                <div
                                  className="slideItem"
                                  style={{ display: "inline-block" }}
                                >
                                  <div className="SuperFlex column itemCard soCardHeight pointer">
                                    <div className="SuperFlex cardInnerInfo">
                                      <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                        <div className="itemImgWrap makeRelative">
                                          <a
                                            href={
                                              "/activityDetails?tourid=" +
                                              Base64.btoa(TourData.id) +
                                              "&name=" +
                                              encodeURIComponent(
                                                TourData.tour_name
                                              ) +
                                              "&location=" +
                                              encodeURIComponent(
                                                TourData.tour_location
                                              ) +
                                              "&dateselected=" +
                                              encodeURIComponent(new Date())
                                            }
                                            target="_blank"
                                          >
                                            <img
                                              src={
                                                this.state.Experiencesdata
                                                  .ExperiencesImage[index][0]
                                              }
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="itemDesc latoBlack">
                                        <a
                                          href={
                                            "/activityDetails?tourid=" +
                                            Base64.btoa(TourData.id) +
                                            "&name=" +
                                            encodeURIComponent(
                                              TourData.tour_name
                                            ) +
                                            "&location=" +
                                            encodeURIComponent(
                                              TourData.tour_location
                                            ) +
                                            "&dateselected=" +
                                            encodeURIComponent(new Date())
                                          }
                                          style={{ fontSize: '14px'}}
                                          target="_blank"
                                          className="blackText font18 lineHeight22 appendBottom10 "
                                        >
                                          {TourData.tour_name.substring(0, 30)}
                                        </a>
                                        <span className="redBdr appendBottom10">
                                          <em></em>
                                        </span>
                                        
                                        <div className="Small-Text">
                                            <p className="address" style={{ color: 'gray', fontSize: '10px' }}> { item.tour_location.substring(0, 40)}</p>
                                          </div>

                                        {getAmount(specialData, Price, forMeal)}
                                        <p
                                          className="blackText appendBottom5"
                                          style={{ fontSize: 10 }}
                                        >
                                          Per Person
                                        </p>
                                      </div>
                                    </div>

                                    <div class="persuasion ">
                                      <div class="persuasion__item pc__cashbackDeal">
                                        <span class="sprite infoIconGreen"></span>
                                        <span>
                                          {item.offer_name.substring(0, 40)}
                                        </span>
                                      </div>
                                    </div>
                                    <div class="title__placeHolder font12 pc__bottomBox"></div>
                                  </div>
                                </div>
                              );
                            }
                          })}
                      </Slider>
                    )}
                  </>
                )}
                {this.state.package && (
                  <>
                    {(this.state.Experiencesdata != null &&
                      this.state.Experiencesdata.Packagespecialoffers ==
                        "no_package_data") ||
                    this.state.Experiencesdata.specialOffersExperiences ==
                      "no_special_offers_exp" ? (
                      <>
                        <div style={{ width: 200, margin: "0 auto" }}>
                          <img
                            src="images/noSpecialOffer.png"
                            style={{ width: "100%" }}
                          />
                          <h6 style={{ fontSize: 15, textAlign: "center" }}>
                            No Special Offer for Package{" "}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <Slider {...settings}>
                        {this.state.Experiencesdata != null &&
                          this.state.Experiencesdata.specialOffersExperiences !=
                            "no_special_offers_exp" &&
                          this.state.Experiencesdata.specialOffersExperiences.map(
                            (item, index) => {
                              if (
                                this.state.Experiencesdata.Packagespecialoffers[
                                  index
                                ] != "no_package_data"
                              ) {
                                const specialData = Object.values(
                                  this.state.Experiencesdata
                                    .Packagespecialoffers[index]
                                )[0][item.tour_id].specialOffer;
                                const Price = Object.values(
                                  this.state.Experiencesdata
                                    .Packagespecialoffers[index]
                                )[0][item.tour_id].tour_price;
                                const forMeal = Object.values(
                                  this.state.Experiencesdata
                                    .Packagespecialoffers[index]
                                )[0][item.tour_id].forMeal;
                                const TourData = Object.values(
                                  this.state.Experiencesdata
                                    .Packagespecialoffers[index]
                                )[0][item.tour_id].tour_data;
                                return (
                                  <div
                                    className="slideItem"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div className="SuperFlex column itemCard soCardHeight pointer">
                                      <div className="SuperFlex cardInnerInfo">
                                        <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                          <div className="itemImgWrap makeRelative">
                                            <a
                                              href={
                                                "/packageDetails?tourid=" +
                                                Base64.btoa(TourData.id) +
                                                "&name=" +
                                                encodeURIComponent(
                                                  TourData.tour_name
                                                ) +
                                                "&location=" +
                                                encodeURIComponent(
                                                  TourData.tour_location
                                                ) +
                                                "&dateselected=" +
                                                encodeURIComponent(new Date())
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={
                                                  this.state.Experiencesdata
                                                    .ExperiencesImage[index][0]
                                                }
                                                alt=""
                                              />
                                            </a>
                                          </div>
                                        </div>
                                        <div className="itemDesc latoBlack">
                                          <a
                                            href={
                                              "/packageDetails?tourid=" +
                                              Base64.btoa(TourData.id) +
                                              "&name=" +
                                              encodeURIComponent(
                                                TourData.tour_name
                                              ) +
                                              "&location=" +
                                              encodeURIComponent(
                                                TourData.tour_location
                                              ) +
                                              "&dateselected=" +
                                              encodeURIComponent(new Date())
                                            }
                                            style={{ fontSize: '14px' }}
                                            target="_blank"
                                            className="blackText font18 lineHeight22 appendBottom10 "
                                          >
                                            {TourData.tour_name.substring(0, 30)}
                                          </a>
                                          <span className="redBdr appendBottom10">
                                            <em></em>
                                          </span>
                                          <div className="Small-Text">
                                            <p className="address" style={{ color: 'gray', fontSize: '10px' }}> { item.tour_location.substring(0, 40)}</p>
                                          </div>


                                          {getAmount(
                                            specialData,
                                            Price,
                                            forMeal
                                          )}
                                          <p
                                            className="blackText appendBottom5"
                                            style={{ fontSize: 10 }}
                                          >
                                            Per Group
                                          </p>
                                        </div>
                                      </div>

                                      <div class="persuasion ">
                                        <div class="persuasion__item pc__cashbackDeal">
                                          <span class="sprite infoIconGreen"></span>
                                          <span>
                                            {item.offer_name.substring(0, 40)}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="title__placeHolder font12 pc__bottomBox"></div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                      </Slider>
                    )}{" "}
                  </>
                )}

                {this.state.transfer && (
                  <>
                    {(this.state.Experiencesdata != null &&
                      this.state.Experiencesdata.Transferspecialoffers ==
                        "no_transfer_data") ||
                    this.state.Experiencesdata.specialOffersExperiences ==
                      "no_special_offers_exp" ? (
                      <>
                        <div style={{ width: 200, margin: "0 auto" }}>
                          <img
                            src="images/noSpecialOffer.png"
                            style={{ width: "100%" }}
                          />
                          <h6 style={{ fontSize: 15, textAlign: "center" }}>
                            No Special Offer for Transfer{" "}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <Slider {...settings}>
                        {this.state.Experiencesdata != null &&
                          this.state.Experiencesdata.specialOffersExperiences !=
                            "no_special_offers_exp" &&
                          this.state.Experiencesdata.specialOffersExperiences.map(
                            (item, index) => {
                              if (
                                this.state.Experiencesdata
                                  .Transferspecialoffers[index] !=
                                "no_transfer_data"
                              ) {
                                const specialData = Object.values(
                                  this.state.Experiencesdata
                                    .Transferspecialoffers[index]
                                )[0][item.tour_id].specialOffer;
                                const Price = Object.values(
                                  this.state.Experiencesdata
                                    .Transferspecialoffers[index]
                                )[0][item.tour_id].tour_price;
                                const forMeal = Object.values(
                                  this.state.Experiencesdata
                                    .Transferspecialoffers[index]
                                )[0][item.tour_id].forMeal;
                                const TourData = Object.values(
                                  this.state.Experiencesdata
                                    .Transferspecialoffers[index]
                                )[0][item.tour_id].tour_data;
                                return (
                                  <div
                                    className="slideItem"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div className="SuperFlex column itemCard soCardHeight pointer">
                                      <div className="SuperFlex cardInnerInfo">
                                        <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                          <div className="itemImgWrap makeRelative">
                                            <a
                                              href={
                                                "/transferDetails?tourid=" +
                                                Base64.btoa(TourData.id) +
                                                "&name=" +
                                                encodeURIComponent(
                                                  TourData.tour_name
                                                ) +
                                                "&location=" +
                                                encodeURIComponent(
                                                  TourData.tour_location
                                                ) +
                                                "&dateselected=" +
                                                encodeURIComponent(new Date())
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={
                                                  this.state.Experiencesdata
                                                    .ExperiencesImage[index][0]
                                                }
                                                alt=""
                                              />
                                            </a>
                                          </div>
                                        </div>
                                        <div className="itemDesc latoBlack">
                                          <a
                                            href={
                                              "/transferDetails?tourid=" +
                                              Base64.btoa(TourData.id) +
                                              "&name=" +
                                              encodeURIComponent(
                                                TourData.tour_name
                                              ) +
                                              "&location=" +
                                              encodeURIComponent(
                                                TourData.tour_location
                                              ) +
                                              "&dateselected=" +
                                              encodeURIComponent(new Date())
                                            }
                                            style={{ fontSize: '14px' }}
                                            target="_blank"
                                            className="blackText font18 lineHeight22 appendBottom10 "
                                          >
                                            {TourData.tour_name.substring(0, 30)}
                                          </a>
                                          <span className="redBdr appendBottom10">
                                            <em></em>
                                          </span>
                                          <div className="Small-Text">
                                            <p className="address" style={{ color: 'gray', fontSize: '10px' }}> { item.tour_location.substring(0, 40)}</p>
                                          </div>

                                          {getAmount(
                                            specialData,
                                            Price,
                                            forMeal
                                          )}
                                          <p
                                            className="blackText appendBottom5"
                                            style={{ fontSize: 10 }}
                                          >
                                            Per Group
                                          </p>
                                        </div>
                                      </div>

                                      <div class="persuasion ">
                                        <div class="persuasion__item pc__cashbackDeal">
                                          <span class="sprite infoIconGreen"></span>
                                          <span>
                                            {item.offer_name.substring(0, 40)}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="title__placeHolder font12 pc__bottomBox"></div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                      </Slider>
                    )}{" "}
                  </>
                )}

                {this.state.liveaboard && (
                  <>
                    {(this.state.Experiencesdata != null &&
                      this.state.Experiencesdata.LiveAboardspecialoffers ==
                        "no_liveaboard_data") ||
                    this.state.Experiencesdata.specialOffersExperiences ==
                      "no_special_offers_exp" ? (
                      <>
                        <div style={{ width: 200, margin: "0 auto" }}>
                          <img
                            src="images/noSpecialOffer.png"
                            style={{ width: "100%" }}
                          />
                          <h6 style={{ fontSize: 15, textAlign: "center" }}>
                            No Special Offer for LiveAboard{" "}
                          </h6>
                        </div>
                      </>
                    ) : (
                      <Slider {...settings}>
                        {this.state.Experiencesdata != null &&
                          this.state.Experiencesdata.specialOffersExperiences !=
                            "no_special_offers_exp" &&
                          this.state.Experiencesdata.specialOffersExperiences.map(
                            (item, index) => {
                              if (
                                this.state.Experiencesdata
                                  .LiveAboardspecialoffers[index] !=
                                "no_liveaboard_data"
                              ) {
                                const specialData = Object.values(
                                  this.state.Experiencesdata
                                    .LiveAboardspecialoffers[index]
                                )[0][item.tour_id].specialOffer;
                                const Price = Object.values(
                                  this.state.Experiencesdata
                                    .LiveAboardspecialoffers[index]
                                )[0][item.tour_id].tour_price;
                                const forMeal = Object.values(
                                  this.state.Experiencesdata
                                    .LiveAboardspecialoffers[index]
                                )[0][item.tour_id].forMeal;
                                const TourData = Object.values(
                                  this.state.Experiencesdata
                                    .LiveAboardspecialoffers[index]
                                )[0][item.tour_id].tour_data;
                                return (
                                  <div
                                    className="slideItem"
                                    style={{ display: "inline-block" }}
                                  >
                                    <div className="SuperFlex column itemCard soCardHeight pointer">
                                      <div className="SuperFlex cardInnerInfo">
                                        <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                          <div className="itemImgWrap makeRelative">
                                            <a
                                              href={
                                                "/liveaboardDetails?tourid=" +
                                                Base64.btoa(TourData.id) +
                                                "&name=" +
                                                encodeURIComponent(
                                                  TourData.tour_name
                                                ) +
                                                "&location=" +
                                                encodeURIComponent(
                                                  TourData.tour_location
                                                ) +
                                                "&dateselected=" +
                                                encodeURIComponent(new Date())
                                              }
                                              target="_blank"
                                            >
                                              <img
                                                src={
                                                  this.state.Experiencesdata
                                                    .ExperiencesImage[index][0]
                                                }
                                                alt=""
                                              />
                                            </a>
                                          </div>
                                        </div>
                                        <div className="itemDesc latoBlack">
                                          <a
                                            href={
                                              "/liveaboardDetails?tourid=" +
                                              Base64.btoa(TourData.id) +
                                              "&name=" +
                                              encodeURIComponent(
                                                TourData.tour_name
                                              ) +
                                              "&location=" +
                                              encodeURIComponent(
                                                TourData.tour_location
                                              ) +
                                              "&dateselected=" +
                                              encodeURIComponent(new Date())
                                            }
                                            style={{ fontSize: '14px' }}
                                            target="_blank"
                                            className="blackText font18 lineHeight22 appendBottom10 "
                                          >
                                            {TourData.tour_name.substring(0, 30)}
                                          </a>
                                          <span className="redBdr appendBottom10">
                                            <em></em>
                                          </span>
                                          <div className="Small-Text">
                                            <p className="address" style={{ color: 'gray', fontSize: '10px' }}> { item.tour_location.substring(0, 40)}</p>
                                          </div>
                                          {getAmount(
                                            specialData,
                                            Price,
                                            forMeal
                                          )}
                                          <p
                                            className="blackText appendBottom5"
                                            style={{ fontSize: 10 }}
                                          >
                                            Per Group
                                          </p>
                                        </div>
                                      </div>

                                      <div class="persuasion ">
                                        <div class="persuasion__item pc__cashbackDeal">
                                          <span class="sprite infoIconGreen"></span>
                                          <span>
                                            {item.offer_name.substring(0, 40)}
                                          </span>
                                        </div>
                                      </div>
                                      <div class="title__placeHolder font12 pc__bottomBox"></div>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          )}
                      </Slider>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="appendBottom20 mb-5">
            <div className="container">
              <div className="buildBackgroundImg BuildlandingCard ">
                  <div className="wid-pages">
                  <h2>Build your Own Trip: Trips made easier for everyone, everywhere</h2>
              <p>
                Create fully customized day by day itinerary of places to
                stay and things to do, transfers during your Seychelles Travels. You can save
                your trip plan so you can keep working on it later or finalized it and book your trip.
              </p>
             { this.state.loginData ==null?(<> <a
                className="btn_1 rounded"
                //onClick={this.openOfferModal}
                href={`${Tripurl}/makebuildtrip/`}
                style={{ marginTop: 25, marginLeft: '35%', fontSize: '20px', fontWeight: 600 }}>
                Start Planning
              </a></>):(<> <a
                className="btn_1 rounded"
                //onClick={this.openOfferModal}
                href={`${Tripurl}/hstotriplogin/${ btoa(this.state.loginData) }`}
                style={{ marginTop: 25, marginLeft: '35%', fontSize: '20px', fontWeight: 600 }}
              >
                Start Planning
              </a></>)} 
             
                  </div>
              </div>
            </div>
          </div>

          <Modal
            isOpen={this.state.modalIsOfferOpen}
            onRequestClose={this.closeOfferModal}
            style={this.customStyles}
            contentLabel="Example Modal"
          >
            <div className="modal-content">
              <div className="text-right">
                <button
                  onClick={this.closeOfferModal}
                  className="close pull-right"
                >
                  <span aria-hidden="true" style={{ margin: "10px" }}>
                    &times;
                  </span>
                </button>
              </div>

              <div
                style={{
                  backgroundColor: "#fff",
                  padding: '11px',
                  margin: '0px 0px 15px'
                }}
              >
                <div className="container" style={{ border: '1px solid', padding: '11px', borderRadius: '7px', borderColor: '#c6a54d' }}>
                  <p className="text" style={{ fontSize: '18px', fontWeight: '500' }}>
                    Build “My Trip” APP or ‘The Trip Builder’ works with all the
                    other APPs together. Once we have all the experience providers
                    listed. We will activate this APP. Thank you for your
                    patience.
                  </p>

                </div>
              </div>
            </div>
          </Modal>
      </>
    );
  }
}
