import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 20
    },
    billTo: {
        color: '#000',
        fontSize: 16,
        textAlign: 'center',
    },
  });


  const BillTo = ({invoice}) => (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Booking Details</Text>
        {/* <Text>Holidays Seychelles</Text>
        <Text>Mahe Seychelles</Text>
        <Text>+248 437 6666</Text>
        <Text>info@holidays-seychelles.com</Text> */}
    </View>
  );
  
  export default BillTo