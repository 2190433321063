import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import "./Superoffers.css";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Skeleton from 'react-loading-skeleton';

export default class Responsive extends Component {
    constructor(props) {
        super(props);
        const { cookies } = props;
        this.state = {
            nav: false,
            selected: "Accommodations",
            modalIsOfferOpen: false,
            OfferDetailsClass: "containerOffer",
            OfferData: null
        };
    }
    openOfferModal = () => {
        this.setState({ modalIsOfferOpen: true });
    };

    closeOfferModal = () => {
        this.setState({ modalIsOfferOpen: false });
    };

    render() {
        return (
            <>
                <div
                    className="SuperlandingCard appendBottom20 superOfferSection page-section sticky-scroll"
                    id="Offers_Listing"
                >
                    <div className="blackText latoBold">
                        <h2><Skeleton /></h2>
                        <div className="SuperFlex perfectCenter">
                            <ul className="SuperFlex capText superOffersTab">
                                <li className="active latoBlack ">
                                    <a className="SuperFlex hrtlCenter column darkGreyText">
                                        <span><Skeleton /> </span>
                                    </a>
                                </li>
                                <li className="latoBlack  ">
                                    <a className="SuperFlex hrtlCenter column darkGreyText">
                                        <span><Skeleton /> </span>
                                    </a>
                                </li>
                                <li className="latoBlack  ">
                                    <a className="SuperFlex hrtlCenter column darkGreyText">
                                        <span><Skeleton /> </span>
                                    </a>
                                </li>
                                <li className="latoBlack  ">
                                    <a className="SuperFlex hrtlCenter column darkGreyText">
                                        <span> <Skeleton /> </span>
                                    </a>
                                </li>
                                <li className="latoBlack  ">
                                    <a className="SuperFlex hrtlCenter column darkGreyText">
                                        <span> <Skeleton /> </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div
                        className="SuperlandingCardSlider superOffers"
                        style={{ direction: "ltr" }}
                    >


                        <div className="slideItem" style={{ display: "inline-block",  width: '33%'}}>
                            <div className="SuperFlex column itemCard soCardHeight pointer">
                                <div className="SuperFlex cardInnerInfo">
                                    <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                        <div className="itemImgWrap makeRelative">
                                            <Skeleton style={{ borderRadius: '50%', height: '108px', paddingTop:20}} />
                                        </div>
                                    </div>
                                    <div className="itemDesc latoBlack">
                                        <div className="SuperFlex left categTitle">
                                            <div className="capText greyText appendBottom20 soTitleMinHeight"></div>
                                        </div>
                                        <p className="blackText font18 lineHeight22 appendBottom10 ">
                                            <Skeleton />
                                        </p>
                                        <span className="redBdr appendBottom10">
                                            <em></em>
                                        </span>
                                        <span className="Superdesc">
                                            <Skeleton />
                                        </span>
                                    </div>
                                </div>
                                <p className="SuperFlex hrtlCenter">
                                    <span className="darkGreyText font10 tcApplyText appendRight20">
                                        <Skeleton />
                                    </span>
                                    <span className="SuperFlex flexOne spaceBetween font13">
                                        <a className="latoBlack pushRight"> <Skeleton /></a>
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div className="slideItem" style={{ display: "inline-block", width: '33%' }}>
                            <div className="SuperFlex column itemCard soCardHeight pointer">
                                <div className="SuperFlex cardInnerInfo">
                                    <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                        <div className="itemImgWrap makeRelative">
                                            <Skeleton style={{ borderRadius: '50%', height: '108px', paddingTop:20}} />
                                        </div>
                                    </div>
                                    <div className="itemDesc latoBlack">
                                        <div className="SuperFlex left categTitle">
                                            <div className="capText greyText appendBottom20 soTitleMinHeight"></div>
                                        </div>
                                        <p className="blackText font18 lineHeight22 appendBottom10 ">
                                            <Skeleton />
                                        </p>
                                        <span className="redBdr appendBottom10">
                                            <em></em>
                                        </span>
                                        <span className="Superdesc">
                                            <Skeleton />
                                        </span>
                                    </div>
                                </div>
                                <p className="SuperFlex hrtlCenter">
                                    <span className="darkGreyText font10 tcApplyText appendRight20">
                                        <Skeleton />
                                    </span>
                                    <span className="SuperFlex flexOne spaceBetween font13">
                                        <a className="latoBlack pushRight"> <Skeleton /></a>
                                    </span>
                                </p>
                            </div>
                        </div> 
                        <div className="slideItem" style={{ display: "inline-block", width: '33%' }}>
                            <div className="SuperFlex column itemCard soCardHeight pointer">
                                <div className="SuperFlex cardInnerInfo">
                                    <div className="itemThumb SuperFlex column hrtlCenter appendRight20">
                                        <div className="itemImgWrap makeRelative">
                                            <Skeleton style={{ borderRadius: '50%', height: '108px', paddingTop:20}} />
                                        </div>
                                    </div>
                                    <div className="itemDesc latoBlack">
                                        <div className="SuperFlex left categTitle">
                                            <div className="capText greyText appendBottom20 soTitleMinHeight"></div>
                                        </div>
                                        <p className="blackText font18 lineHeight22 appendBottom10 ">
                                            <Skeleton />
                                        </p>
                                        <span className="redBdr appendBottom10">
                                            <em></em>
                                        </span>
                                        <span className="Superdesc">
                                            <Skeleton />
                                        </span>
                                    </div>
                                </div>
                                <p className="SuperFlex hrtlCenter">
                                    <span className="darkGreyText font10 tcApplyText appendRight20">
                                        <Skeleton />
                                    </span>
                                    <span className="SuperFlex flexOne spaceBetween font13">
                                        <a className="latoBlack pushRight"> <Skeleton /></a>
                                    </span>
                                </p>
                            </div>
                        </div> 
                        
                    </div>
                </div>
                
            </>
        );
    }
}
