import React from 'react';
import Header from "../../../NewHeader/NewHeader";
import Footer from "../../../Footer";
import HotelImage from "../../../../assets/images/hotel/hotel.jpg";
import HotelImage2 from "../../../../assets/images/hotel/01.jpg";
import HotelImage3 from "../../../../assets/images/hotel/02.jpg";

import "./AccomadationModal.css";

function AccomadationModal() {
    return (
        <div className=""  >
            
            
            {/* <Header /> */}
            <div className="container mt-5 mb-5" style={{height:"auto"}}>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">x</span>
            </button>
                
                <div className="row">
                    <div className="col-2">
                        <button className="btn btn-outline-warning">Dates</button>
                    </div>
                    <div className="col-2">
                        <button className="btn btn-outline-warning">Guests </button>
                    </div>
                    <div className="col-2">
                        <button className="btn btn-outline-warning">Star Reviews</button>
                    </div>
                    <div className="col-2">
                        <button className="btn btn-outline-warning">Property Type</button>
                    </div>
                    <div className="col-2">
                        <button className="btn btn-outline-warning">More Filters</button>
                    </div>
                </div>
                <div className="hotels-list" style={{marginTop:"20px",marginBottom:"100px" }}>
                    <div className="container">
                        <div className="row">
                            <figure class="figure col-4">
                                <img src={HotelImage} class="figure-img img-fluid rounded" id="image"  alt="... " />
                                    <figcaption class="figure-caption text-right"><span style={{fontSize:"large"}}>A caption for the above image.</span><br/>Remaining Sample text about the hotel</figcaption>
                            </figure>
                            <figure class="figure col-4">
                                <img src={HotelImage2} class="figure-img img-fluid rounded" id="image"  alt="... " />
                                    <figcaption class="figure-caption text-right"><span style={{fontSize:"large"}}>A caption for the above image.</span><br/>Remaining Sample text about the hotel</figcaption>
                            </figure>
                            <figure class="figure col-4">
                                <img src={HotelImage3} class="figure-img img-fluid rounded" id="image" alt="... " />
                                    <figcaption class="figure-caption text-right"><span style={{fontSize:"large"}}>A caption for the above image.</span><br/>Remaining Sample text about the hotel</figcaption>
                            </figure>
                            <figure class="figure col-4">
                                <img src={HotelImage} class="figure-img img-fluid rounded" id="image" alt="... " />
                                    <figcaption class="figure-caption text-right"><span style={{fontSize:"large"}}>A caption for the above image.</span><br/>Remaining Sample text about the hotel</figcaption>
                            </figure>
                            <figure class="figure col-4">
                                <img src={HotelImage2} class="figure-img img-fluid rounded" id="image" alt="... " />
                                    <figcaption class="figure-caption text-right"><span style={{fontSize:"large"}}>A caption for the above image.</span><br/>Remaining Sample text about the hotel</figcaption>
                            </figure>
                            <figure class="figure col-4">
                                <img src={HotelImage3} class="figure-img img-fluid rounded" id="image" alt="... " />
                                    <figcaption class="figure-caption text-right"><span style={{fontSize:"large"}}>A caption for the above image.</span><br/>Remaining Sample text about the hotel</figcaption>
                            </figure>
                            <figure class="figure col-4">
                                <img src={HotelImage} class="figure-img img-fluid rounded" id="image" alt="... " />
                                    <figcaption class="figure-caption text-right"><span style={{fontSize:"large"}}>A caption for the above image.</span><br/>Remaining Sample text about the hotel</figcaption>
                            </figure>
                            <figure class="figure col-4">
                                <img src={HotelImage2} class="figure-img img-fluid rounded" id="image" alt="... " />
                                    <figcaption class="figure-caption text-right"><span style={{fontSize:"large"}}>A caption for the above image.</span><br/>Remaining Sample text about the hotel</figcaption>
                            </figure>
                            <figure class="figure col-4">
                                <img src={HotelImage3} class="figure-img img-fluid rounded" id="image" alt="... " />
                                    <figcaption class="figure-caption text-right"><span style={{fontSize:"large"}}>A caption for the above image.</span><br/>Remaining Sample text about the hotel</figcaption>
                            </figure>

                        </div>
                        </div>
                    </div>
                </div>

                <Footer />

            </div>
    )
}

export default AccomadationModal;
