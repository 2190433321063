export default [
    {
      id: '0',
      image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/1.jpg',
      type: 'Private Room',
      title: 'Bright room in the heart of the city',
      bed: 2,
      bedroom: 3,
      oldPrice: 25,
      newPrice: 20,
      totalPrice: 1202,
      coordinate: {
        latitude: 28.3915637,
        longitude: -16.6291304,
      },
    },
    {
      id: '1',
      image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/2.jpg',
      type: 'Entire Flat',
      title: 'NEW lux. apartment in the center of Santa Cruz',
      bed: 3,
      bedroom: 2,
      oldPrice: 76,
      newPrice: 65,
      totalPrice: 3902,
      coordinate: {
        latitude: 28.3105336,
        longitude: -16.6291300,
      },
    },
    {
      id: '2',
      image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/3.jpg',
      type: 'Private Property',
      title: 'Green House Santa Cruz',
      bed: 2,
      bedroom: 1,
      oldPrice: 64,
      newPrice: 55,
      totalPrice: 3301,
      coordinate: {
        latitude: 28.2515637,
        longitude: -16.3991304,
      },
    },
    {
      id: '3',
      image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/4.jpg',
      type: 'Entire Flat',
      title: 'Typical canarian house',
      bed: 4,
      bedroom: 3,
      oldPrice: 120,
      newPrice: 100,
      totalPrice: 6002,
      coordinate: {
        latitude: 28.4815637,
        longitude: -16.2991304,
      },
    },
    {
        id: '4',
        image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/1.jpg',
        type: 'Private Room',
        title: 'Bright room in the heart of the city',
        bed: 3,
        bedroom: 3,
        oldPrice: 25,
        newPrice: 20,
        totalPrice: 1801,
        coordinate: {
            latitude: 28.1915336,
            longitude: -16.6291300,
        },
      },
      {
        id: '5',
        image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/2.jpg',
        type: 'Private Room',
        title: 'Bright room in the heart of the city',
        bed: 3,
        bedroom: 3,
        oldPrice: 25,
        newPrice: 20,
        totalPrice: 2201,
        coordinate: {
            latitude: 28.2515637,
            longitude: -16.6291301,
        },
      },

      {
        id: '6',
        image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/1.jpg',
        type: 'Private Room',
        title: 'Bright room in the heart of the city',
        bed: 2,
        bedroom: 3,
        oldPrice: 25,
        newPrice: 20,
        totalPrice: 120,
        coordinate: {
            latitude: 28.2915336,
            longitude: -16.6291300,
        },
      },
      {
        id: '7',
        image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/2.jpg',
        type: 'Entire Flat',
        title: 'NEW lux. apartment in the center of Santa Cruz',
        bed: 3,
        bedroom: 2,
        oldPrice: 76,
        newPrice: 65,
        totalPrice: 3903,
        coordinate: {
            latitude: 28.4915637,
            longitude: -16.2291304,
        },
      },
      {
        id: '8',
        image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/3.jpg',
        type: 'Private Property',
        title: 'Green House Santa Cruz',
        bed: 2,
        bedroom: 1,
        oldPrice: 64,
        newPrice: 55,
        totalPrice: 3302,
        coordinate: {
            latitude: 28.1005336,
            longitude: -16.69001300,
        },
      },
      {
        id: '9',
        image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/4.jpg',
        type: 'Entire Flat',
        title: 'Typical canarian house',
        bed: 4,
        bedroom: 3,
        oldPrice: 120,
        newPrice: 100,
        totalPrice: 6001,
        coordinate: {
            latitude: 28.4003848,
            longitude: -16.7400300,
        },
      },
      {
          id: '10',
          image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/1.jpg',
          type: 'Private Room',
          title: 'Bright room in the heart of the city',
          bed: 3,
          bedroom: 3,
          oldPrice: 25,
          newPrice: 20,
          totalPrice: 1802,
          coordinate: {
            latitude: 28.3003848,
            longitude: -16.4400300,
          },
        },
        {
          id: '11',
          image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/2.jpg',
          type: 'Private Room',
          title: 'Bright room in the heart of the city',
          bed: 3,
          bedroom: 3,
          oldPrice: 25,
          newPrice: 20,
          totalPrice: 2202,
          coordinate: {
            latitude: 28.0115336,
            longitude: -16.6291300,
          },
        },
        {
            id: '12',
            image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/1.jpg',
            type: 'Private Room',
            title: 'Bright room in the heart of the city',
            bed: 2,
            bedroom: 3,
            oldPrice: 25,
            newPrice: 20,
            totalPrice: 1201,
            coordinate: {
                latitude: 28.2003848,
                longitude: -16.7400300,
            },
          },
          {
            id: '13',
            image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/2.jpg',
            type: 'Entire Flat',
            title: 'NEW lux. apartment in the center of Santa Cruz',
            bed: 3,
            bedroom: 2,
            oldPrice: 76,
            newPrice: 65,
            totalPrice: 3901,
            coordinate: {
                latitude: 28.3915336,
                longitude: -16.6291300,
            },
          },
          {
            id: '14',
            image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/3.jpg',
            type: 'Private Property',
            title: 'Green House Santa Cruz',
            bed: 2,
            bedroom: 1,
            oldPrice: 64,
            newPrice: 55,
            totalPrice: 3303,
            coordinate: {
                latitude: 28.3115336,
                longitude: -16.69001300,
            },
          },
          {
            id: '15',
            image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/4.jpg',
            type: 'Entire Flat',
            title: 'Typical canarian house',
            bed: 4,
            bedroom: 3,
            oldPrice: 1202,
            newPrice: 100,
            totalPrice: 600,
            coordinate: {
                latitude: 28.3003848,
                longitude: -16.7400300,
            },
          },
          {
              id: '16',
              image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/1.jpg',
              type: 'Private Room',
              title: 'Bright room in the heart of the city',
              bed: 3,
              bedroom: 3,
              oldPrice: 25,
              newPrice: 20,
              totalPrice: 1803,
              coordinate: {
                latitude: 28.3003848,
                longitude: -16.5400300,
              },
            },
            {
              id: '17',
              image: 'https://notjustdev-dummy.s3.us-east-2.amazonaws.com/images/2.jpg',
              type: 'Private Room',
              title: 'Bright room in the heart of the city',
              bed: 3,
              bedroom: 3,
              oldPrice: 25,
              newPrice: 20,
              totalPrice: 220,
              coordinate: {
                latitude: 28.1915336,
                longitude: -16.6291300,
              },
            },

  ];
  