import React from 'react';
import Header from "../../NewHeader/NewHeader";
import Footer from "../../Footer";
import "./Duration.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faEdit, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { Popup } from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import { DatePicker, Space, Menu, Dropdown, Button } from 'antd';
// import 'antd/dist/antd.css';




function Duration() {
    const menu = (
        <Menu>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" >
                    1st menu item
            </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" >
                    2nd menu item
            </a>
            </Menu.Item>
            <Menu.Item>
                <a target="_blank" rel="noopener noreferrer" >
                    3rd menu item
            </a>
            </Menu.Item>
        </Menu>
    );
    return (
        <div>
            <Header />


            <div className="duration-Container parallax">

                <Link to="/accomadation"><button id="GoForward">Move To
            Accomadation <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                <h3 style={{ color: "white" }}>Select Duration For Islands / Cities</h3>
                <h6 style={{ color: "white" }}>Drag and drop your cities to change your route</h6>
                <h6 style={{ color: "white" }}>Start Date: <span style={{ color: "#deb956" }}>21 Dec 2021</span> - End Date: <span style={{ color: "#deb956" }}>25 Dec 2021</span></h6>
                <div className="detailsDuration">
                    <div >
                        <div style={{ display: "flex" }}><FontAwesomeIcon style={{ height: "50px", width: "50px" }} icon={faMapMarker} /><h3 style={{ color: "black" }}>Mahe / Seychelles</h3></div>
                        <p style={{ marginLeft: "5px" }}>21 Dec 2021 - 23 Dec 2021</p>
                    </div>
                    <div style={{ display: 'flex', flexFlow: "row", alignItems: 'center' }}>
                        {/* <p style={{fontSize:"larger"}}>2 nights </p> */}
                        <Popup  trigger={
                            <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                <p style={{ fontSize: "larger" }}>2 nights </p>
                                <FontAwesomeIcon icon={faEdit} style={{ marginLeft: "10px", cursor: "pointer" }} />
                            </div>
                        } position="bottom left">
                            <div >
                                <div className="arrive " >
                                    <h6 >Arrive</h6>
                                    <div className="d-flex justify-content-between" style={{padding:"10px",width:"200px"}}>
                                    <Space style direction="vertical" className="mr-2" size={12}>
                                        <DatePicker  />
                                    </Space>

                                    <Space wrap>
                                        <Dropdown overlay={menu} placement="bottomLeft">
                                            <Button>Time</Button>
                                        </Dropdown>
                                    </Space>

                                    </div>
                                    
                            </div>
                                    <div className="depart">
                                    <h6>Depart</h6>
                                    <div className="d-flex justify-content-between"  style={{padding:"10px",width:"200px"}}>
                                    <Space direction="vertical" className="mr-2" size={12}>
                                        <DatePicker  />
                                    </Space>

                                    <Space wrap>
                                        <Dropdown overlay={menu} placement="bottomLeft">
                                            <Button>Time</Button>
                                        </Dropdown>
                                    </Space>

                                    </div>
                                    </div>
                                </div>
                        </Popup>

                    </div>
                    </div>
                    <div className="detailsDuration">
                        <div >
                            <div style={{ display: "flex" }}><FontAwesomeIcon style={{ height: "50px", width: "50px" }} icon={faMapMarker} /><h3 style={{ color: "black" }}> Praslin / Seychelles</h3></div>
                            <p style={{ marginLeft: "5px" }}>23 Dec 2021 - 25 Dec 2021</p>
                        </div>
                        <div style={{ display: 'flex', flexFlow: "row", alignItems: 'center' }}>
                        <Popup  trigger={
                            <div style={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                                <p style={{ fontSize: "larger" }}>2 nights </p>
                                <FontAwesomeIcon icon={faEdit} style={{ marginLeft: "10px", cursor: "pointer" }} />
                            </div>
                        } position="bottom left">
                            <div >
                                <div className="arrive " >
                                    <h6 >Arrive</h6>
                                    <div className="d-flex justify-content-between" style={{padding:"10px",width:"200px"}}>
                                    <Space style direction="vertical" className="mr-2" size={12}>
                                        <DatePicker  />
                                    </Space>

                                    <Space wrap>
                                        <Dropdown overlay={menu} placement="bottomLeft">
                                            <Button>Time</Button>
                                        </Dropdown>
                                    </Space>

                                    </div>
                                    
                            </div>
                                    <div className="depart">
                                    <h6>Depart</h6>
                                    <div className="d-flex justify-content-between"  style={{padding:"10px",width:"200px"}}>
                                    <Space direction="vertical" className="mr-2" size={12}>
                                        <DatePicker  />
                                    </Space>

                                    <Space wrap>
                                        <Dropdown overlay={menu} placement="bottomLeft">
                                            <Button>Time</Button>
                                        </Dropdown>
                                    </Space>

                                    </div>
                                    </div>
                                </div>
                        </Popup>

                        </div>

                    </div>
                    <Link to="/CustomizeTrips"><button id="GoBack"><FontAwesomeIcon icon={faArrowLeft} />Move to Cities</button></Link>
                </div>
                <Footer />
            </div>
    )
}

export default Duration;
