import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import CustomMaker from './CustomMarker';

// const AnyReactComponent = ({ price, isSelected, onClick }) => <div onClick={onClick} style={{backgroundColor: isSelected ? '#000' :'#fff', color:isSelected ? '#fff' : '#000', padding:5, width: 45, borderRadius:20, textAlign:'center', fontWeight:'bold', fontSize:10}}>$ {price} {isSelected ? "Rohan": ""}</div>;

class GoogleMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlace: null,
            center: {
              lat: parseFloat("-4.32703163457721"),
              lng: parseFloat("55.7734331488609")
             }

        };
    }
    componentDidMount(){
        // this.setState(prevState => {
        //   let center = Object.assign({}, prevState.center);  
        //   center.lat = this.props.data[0].details.latitude; 
        //   center.lng = this.props.data[0].details.longitude;                                      
        //   return { center };  
        // })
        this.setState({
          center: {                   // object that we want to upda   // keep all other key-value pairs
              lat: parseFloat(this.props.data[0].details.latitude), 
              lng: parseFloat(this.props.data[0].details.longitude)       // update the value of specific key
          }
      })
    }
  render() {
    console.log(this.props.data.length);
    console.log(this.props.data[0].details.latitude);
     const center = {
      lat: -4.32703163457721,
      lng: 55.7734331488609
     }
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDUMnrX91-4WMTa5ixm3hQjVWaFcMsQ4ek"}}
          defaultCenter={this.state.center}
          defaultZoom={this.props.data.length > 18 ? 10 : 12.5}
        >

        {this.props.data.map((item, key) => (
            <CustomMaker
            lat={item.details.latitude}
            lng={item.details.longitude}
            price={item.totalPrice}
            isSelected={item.details.id === this.state.selectedPlace}
            isSelectedIcon={item.details.id === this.props.selectedPlace}
            onClick={() => this.setState({selectedPlace : item.details.id})}
            allData= {item}
          />
        )
        )}
          
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;
