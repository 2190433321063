import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Link } from "react-scroll";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import GoogleMap from "./GoogleMap/GoogleMap";
import EventImage1 from "../../assets/images/events/ChildrenCamp.jpg";
import EventImage2 from "../../assets/images/events/CreoleFestival.jpg";
import EventImage3 from "../../assets/images/events/FashionWeek.jpg";
import EventImage4 from "../../assets/images/events/NatureTrail.jpg";
import EventImage5 from "../../assets/images/events/Saturday.jpg";
import EventImage6 from "../../assets/images/events/TourismFestival.jpg";
import EventImage7 from "../../assets/images/events/Tournament.jpg";
import EventImage8 from "../../assets/images/events/Trail.jpg";
import NewHeader from "../NewHeader/NewHeader";

import "./EventDetails.css";
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default function EventDetails() {
  // const { productId } = useParams();
  // const productDataId = productId;
  const [ImageChanger, setImageChanger] = useState();

  const [state, setState] = useState();
  const [eventPage, setEventPage] = useState([]);
  const [Multimedia, setMultimedia] = useState([]);
  const [Location, setLocation] = useState([]);
  const [Attributes, setAttributes] = useState([]);
  const [Communications, setCommunications] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const productId = urlParams.get("productId");
    console.log(productId);

    // "https://api.parrapi.com/api/atlas/product?key=853990929f594a01b5632e55514c96b4&productId=" +
    // https://api.katover.com/api/atlas/product?key=8a42cca7640e416cb75a06f34d3569df&productId=
    axios
      .get(
        "https://api.parrapi.com/api/atlas/product?key=853990929f594a01b5632e55514c96b4&productId=" +
          productId +
          "&out=json"
      )
      .then(function (response) {
        // htandle success
        console.log(response.data);
        setEventPage(response.data);
        setImageChanger(response.data.multimedia[0].serverPath);
        setMultimedia(response.data.multimedia);
        setLocation(response.data.location);
        setAttributes(response.data.attributes);
        setCommunications(response.data.communications);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }, []);

  const onMouseOutFun = (image) => {
    setImageChanger(image);
  };
  const onMouseOverFun = (image) => {
    setImageChanger(image);
  };

  //   handleSetActive = (to) => {
  //     console.log(to);
  //     };
  //     handleSetInactive = (to) => {
  //         console.log(to);
  //     };
  console.log(Location);

  return (
    <div>
      <NewHeader page={"outer"} />
      <div className="MainLayout__LayoutWrapper-iwen70-0 htSJWp">
        <div className="DestinationHeaderStyle__DestHeader-sc-1xzsjuy-0 eAATEh">
          <div className="DestinationHeaderStyle__DestHeaderInner-sc-1xzsjuy-1 jZqGFq">
            <div className="DestinationHeaderStyle__DestHeaderContent-sc-1xzsjuy-2 cuPQgq">
              <div className="DestinationHeaderStyle__DestHeaderLeft-sc-1xzsjuy-3 KXLh">
                <h1>{eventPage.productName}</h1>
                <p>{eventPage.productShortDescription} ...</p>
              </div>
              <div className="DestinationHeaderStyle__DestHeaderRight-sc-1xzsjuy-4 jSbUIF">
                <div>
                  <div className="ImageGallery__MainSlider-f6k28r-0 rpoGx">
                    <img
                      src={ImageChanger}
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        transition: "all 0.3s linear",
                      }}
                    />
                  </div>

                  <div className="ImageGallery__Thumbnail-f6k28r-2 eiNjYN">
                    <Slider {...settings}>
                      {Multimedia.map((item, idx) => (
                        <div
                          style={{ width: "100%", display: "inline-block" }}
                          className="ImageGallery__ThumbnailItem-f6k28r-3 kGtjKJ"
                        >
                          <img
                            src={item.serverPath}
                            onMouseOver={(e) =>
                              onMouseOverFun(e.currentTarget.src)
                            }
                            onMouseOut={(e) =>
                              onMouseOutFun(e.currentTarget.src)
                            }
                            className="ImageGallery__Img-f6k28r-6 icfoNW"
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="CityApp__DestContentWraper-qo8xou-0 bUCVot">
          <div className="SearchByCardStyle__Card-sc-102ccav-0 cEIUow">
            <div
              class="SearchByCardStyle__MiddleTab-sc-102ccav-2 hZjoUk _navSticky navSticky appendBottom15"
              style={{ background: "#0d0d0d" }}
            >
              <a>
                <Link
                  activeClass="active"
                  to="Info"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={0}
                  onSetActive={0}
                  onSetInactive={0}
                  ignoreCancelEvents={false}
                >
                  {eventPage.productName}
                </Link>
              </a>
              <a>
                <Link
                  activeClass="active"
                  to="Maps"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={0}
                  onSetActive={0}
                  onSetInactive={0}
                  ignoreCancelEvents={false}
                >
                  Location
                </Link>
              </a>
              <a>
                <Link
                  activeClass="active"
                  to="Attributes"
                  spy={true}
                  smooth={true}
                  duration={100}
                  offset={0}
                  onSetActive={0}
                  onSetInactive={0}
                  ignoreCancelEvents={false}
                >
                  Attributes
                </Link>
              </a>
            </div>
          </div>
          <div className="container" style={{ maxWidth: "1210px" }}>
            <div id="Info">
              <div className="BestTimeToVisitStyle__BstCard-sc-13q84cl-0 fWTmyb">
                <h2>{eventPage.productName}</h2>
                <h3>{eventPage.productDescription}</h3>
              </div>
            </div>
          </div>
          <div className="container" style={{ maxWidth: "1210px" }}>
            <div id="Maps">
              <div className="BestTimeToVisitStyle__BstCard-sc-13q84cl-0 fWTmyb">
                <div className="google-map">
                  <h4 className="txtHeading font22 latoBlack blackText textHeader">
                    Location{" "}
                  </h4>
                  <div className="GoogleMapLo">
                    <div className="GoogleInner">
                      {Location.length > 0 && (
                        <GoogleMap
                          lat={Location[0].lat}
                          lng={Location[0].lng}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container" style={{ maxWidth: "1210px" }}>
            <div id="Attributes">
              <div className="BestTimeToVisitStyle__BstCard-sc-13q84cl-0 fWTmyb">
                <h2>Attributes</h2>
                <div className="container" style={{ maxWidth: "1210px" }}>
                  <section
                    id="RoomType"
                    class="page-section bottom35"
                    style={{
                      width: "auto",
                      background: "#fff",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <div class="_RoomType">
                      <div class="gap"></div>
                      <div class="comboRoomsSection" id="viewMoreRooms">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Type Description</th>
                              <th>Type ID</th>
                              <th>Type ID Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Attributes.map((item, idx) => (
                              <tr>
                                <td scope="col">{item.attributeTypeId}</td>
                                <td scope="col">
                                  {item.attributeTypeIdDescription}
                                </td>
                                <td scope="col">{item.attributeId}</td>
                                <td scope="col">
                                  {item.attributeIdDescription}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
