import React from 'react'
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-image-lightbox/style.css';
import {Helmet} from "react-helmet";
import Home from './Screen/Home';
import HotelListing from './Screen/HotelListing';
import ActivityListing from './Screen/ActivityListing';
import PackageListing from './Screen/PackageListing';
import TransferListing from './Screen/TransferListing';
import LiveAboardListing from './Screen/LiveAboardListing';
import HotelDetails from "./Component/HotelDetails/HotelDetails";
import AdventureDetails from "./Component/AdventureDetails/AdventureDetails";
import { Route, HashRouter as Router, BrowserRouter, Switch } from 'react-router-dom';
import PaymentDetails from "./Component/PaymentDetails/PaymentDetails";
import PaymentDetailsTour from "./Component/PaymentDetailsTour/PaymentDetails";
import PaymentDetailsTourTransfer from "./Component/PaymentDetailsTour/PaymentDetailsTransfer";
import MobileBanner from "./Component/MobileBanner/MobileBanner";
import PlanTrips from "./Component/PLanTrips/PlanTrips";
import ExpreriancePlanTrips from "./Component/ExpreriancePlanTrips/ExpreriancePlanTrips";
import Wallet from "./Component/Wallet/Wallet";
import Customize from "./Component/CustomizeYourTrips/customize"
import Duration from "./Component/CustomizeYourTrips/Duration/Duration";
import Accomadation from "./Component/CustomizeYourTrips/Accomadation/Accomadation.jsx";
import Transpotation from "./Component/CustomizeYourTrips/Transportation/Transpotation.jsx"
import Profile from "./Component/Profile/Profile";
import ResetPassword from "./Component/ResetPassword/ResetPassword";
import BookingPage from './BookingPage';
import TourBookingPage from './TourBookingPage';
import { CookiesProvider } from 'react-cookie';
import AboutUs from "./staticPages/AboutUs.jsx";
import Visitplanner from "./staticPages/Visitplanner.jsx";
import Buildyourtrip from "./staticPages/Buildyourtrip.jsx";
import Duringyourtrip from "./staticPages/Duringyourtrip.jsx";
import Family from "./staticPages/Family.jsx";
import Faq from "./staticPages/Faq.jsx";
import TndC from "./staticPages/TermsAndConditions.jsx";
import PrivacyPolicy from './staticPages/PrivacyPolicy';
import ActivityDetails from './Component/TourDetails/ActivityDetails';
import PackageDetails from './Component/TourDetails/PackageDetails';
import TransferDetails from './Component/TourDetails/TransferDetails';
import LiveAboardDetails from './Component/TourDetails/LiveAboardDetails';
import EventDetails from './Component/Events/EventDetails';
import TourOfferListing from './Screen/TourOfferListing';
import TravelBlog from './Blog/TravelBlog';
import TravelBlogDetails from './Blog/TravelBlogDetails';

function App() {
  return (
    <CookiesProvider>
      <BrowserRouter>
      {/* <Helmet>
      <title>Holidays-Seychelles- #1 Seychelle's Travel Website</title>
        <meta name="description" content="Seychelles is one the most beautiful places in the world and best honeymoon destination in the world" />
        <meta name="keywords" content="Seychelles, Seychellen, best place for honeymoon,most beautiful places in the world,best honeymoon destination in the world,honeymoon places,honeymoon destinations,beautiful places in the world"/>
      </Helmet> */}
        <Switch>
          <Route path="/hotellisting" component={HotelListing} />
          <Route path="/activitylisting" component={ActivityListing} />
          <Route path="/packagelisting" component={PackageListing} />
          <Route path="/transferlisting" component={TransferListing} />
          <Route path="/liveaboardlisting" component={LiveAboardListing} />
          <Route path="/hotelDetails/" component={HotelDetails}></Route>
          <Route path="/activityDetails/" component={ActivityDetails}></Route>
          <Route path="/packageDetails/" component={PackageDetails}></Route>
          <Route path="/transferDetails/" component={TransferDetails}></Route>
          <Route path="/liveaboardDetails/" component={LiveAboardDetails}></Route>
          <Route path="/adventureDetails" component={AdventureDetails}></Route>
          <Route path="/paymentWall" component={PaymentDetails}></Route>
          <Route path="/paymentWallTour" component={PaymentDetailsTour}></Route>
          <Route path="/paymentWallTourtransfer" component={PaymentDetailsTourTransfer}></Route>
          <Route path="/enterDetails" component={MobileBanner}></Route>
          <Route path="/PlanTrips" component={PlanTrips}></Route>
          <Route path="/ExpreriancePlanTrips" component={ExpreriancePlanTrips}></Route>
          <Route path="/Wallet" component={Wallet}></Route>
          <Route path="/CustomizeTrips" component={Customize}></Route>
          <Route path="/citiesDuration" component={Duration} />
          <Route path="/BookingPage" component={BookingPage} />
          <Route path="/TourBookingPage" component={TourBookingPage} />
          <Route path="/Profile" component={Profile} />
          <Route path="/resetpassword" component={ResetPassword} />
          <Route exact path="/accomadation"  component={Accomadation} />
          <Route exact path="/transportation"  component={Transpotation} />
          <Route exact path="/aboutUs" component={AboutUs} />
          <Route exact path="/terms" component={TndC} />
          <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/visitplanner" component={Visitplanner} />
          <Route exact path="/beforeyourtrip" component={Buildyourtrip} />
          <Route exact path="/duringyourtrip" component={Duringyourtrip} />
          <Route exact path="/eventDetails" component={EventDetails} />
          <Route exact path="/family" component={Family} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/" component={Home} />
          <Route path="/tourofferlisting" component={TourOfferListing} />
          <Route path="/travelblog" component={TravelBlog} />
          <Route path="/travelblogdetails" component={TravelBlogDetails} />
          
          

        </Switch>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
