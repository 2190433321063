import React,{useState} from 'react';
import "./Wallet.css";
import Header from "../NewHeader/NewHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

function Wallet() {

    const [answerState,changeAnswerState]=useState({
        question1:"answerInVisible",
        question2:"answerInVisible",
        question3:"answerInVisible",
        question4:"answerInVisible",
        question5:"answerInVisible",
        question6:"answerInVisible"
    })

    const handleClick1=(e)=>{
        if(answerState.question1 === "answerInVisible"){
            changeAnswerState({
                ...answerState,
                question1:"answerVisible",
               
            })
        }
        else{
            changeAnswerState({
                ...answerState,
                question1:"answerInVisible"
            })
        }
       
    }
    const handleClick2=(e)=>{
        if(answerState.question2 === "answerInVisible"){
            changeAnswerState({
                ...answerState,
                question2:"answerVisible",
               
            })
        }
        else{
            changeAnswerState({
                ...answerState,
                question2:"answerInVisible"
            })
        }
       
    }
    const handleClick3=(e)=>{
        if(answerState.question3 === "answerInVisible"){
            changeAnswerState({
                ...answerState,
                question3:"answerVisible",
               
            })
        }
        else{
            changeAnswerState({
                ...answerState,
                question3:"answerInVisible"
            })
        }
       
    }
    const handleClick4=(e)=>{
        if(answerState.question4 === "answerInVisible"){
            changeAnswerState({
                ...answerState,
                question4:"answerVisible",
               
            })
        }
        else{
            changeAnswerState({
                ...answerState,
                question4:"answerInVisible"
            })
        }
       
    }
    const handleClick5=(e)=>{
        if(answerState.question5 === "answerInVisible"){
            changeAnswerState({
                ...answerState,
                question5:"answerVisible",
               
            })
        }
        else{
            changeAnswerState({
                ...answerState,
                question5:"answerInVisible"
            })
        }
       
    }
    const handleClick6=(e)=>{
        if(answerState.question6 === "answerInVisible"){
            changeAnswerState({
                ...answerState,
                question6:"answerVisible",
               
            })
        }
        else{
            changeAnswerState({
                ...answerState,
                question6:"answerInVisible"
            })
        }
       
    }
    return (
        <div>
            <Header />
          
            <div className="container-wallet">
                <div className="wallet-dropdown" style={{ display: "flex", margin: "100px" }}>
                    <select name="cars" value="My Account" id="Account">
                        <option >My Wallet</option>
                        <option >My Trips</option>
                        <option >MMT Black</option>
                        <option >Profile</option>
                    </select>
                    <p style={{color:"black"}}> {'>'} My Wallet </p>
                </div>
                <div className="wallet-details">
                    <div className="wallet-balance">
                        <div className="balance">
                            <h1 style={{ color: "white" }}>₹0</h1>
                            <h4 style={{ color: "#ffffff", fontWeight: "600", opacity: "0.8" }}>WALLET BALANCE</h4>
                        </div>
                        <div className="balance-details">
                            <div><h4 style={{ color: "black" }}>My Cash</h4>
                                <p className="unrestricted">USE UNRESTRICTED</p>
                            </div>
                            <div>
                                <h4 style={{ color: "black" }}>₹0</h4>

                                <h6 style={{color:"#008cff",cursor:"pointer"}}>How to earn?</h6>
                                </div>
                        </div>
                        <div className="balance-details">
                            <div><h4 style={{ color: "black" }}>Reward Bonus</h4>
                            <p className="restricted">USE WITH RESTRICTIONS</p></div>
                            <div><h4 style={{ color: "black" }}>₹0</h4>
                            <h6 style={{color:"#008cff",cursor:"pointer"}}>How to earn?</h6></div>
                        </div>
                    </div>
                    <div className="Questions">
                        <div className="question">
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                            <h5 style={{color:"black"}}>What is My Cash ?</h5>
                            <FontAwesomeIcon icon={faArrowDown} onClick={handleClick1}/>
                            </div>
                            <p className={answerState.question1}>
                            My Cash is the amount earned via cancellation refunds, reaching MMTBlack milestones and spends accumulated via MMT-ICICI cobrand credit card.The old wallet+ cash linked to any account is now a part of My Cash.
                            </p>
                        </div>
                        <hr />
                        <div className="question">
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                            <h5 style={{color:"black"}}>When does My Cash expire?</h5>
                            <FontAwesomeIcon icon={faArrowDown} onClick={handleClick2}/>
                            </div>
                            <p className={answerState.question2}>
                            My Cash earned via cancellation refunds does not expire. My Cash earned via MMTBlack milestones will expire after 3 months of credit date.My Cash earned via MMT-ICICI cobrand card will expire after 12 months of credit date.
                            </p>
                        </div>
                        <hr />
                        <div className="question">
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                            <h5 style={{color:"black"}}>Where is My Cash applicable?</h5>
                            <FontAwesomeIcon icon={faArrowDown} onClick={handleClick3}/>
                            </div>
                            <p className={answerState.question3}>
                            My Cash can be applied completely on any booking such as Flights, Hotels, Holidays, Cabs and Bus.
                            </p>
                        </div>
                        <hr />
                        <div className="question">
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                            <h5 style={{color:"black"}}>What is Reward Bonus?</h5>
                            <FontAwesomeIcon icon={faArrowDown} onClick={handleClick4}/>
                            </div>
                            <p className={answerState.question4}>
                            Reward Bonus is the promotional amount given by Makemytrip to enable its users to make bookings at the lowest price.
                            </p>
                        </div>
                        <hr />
                        <div className="question">
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                            <h5 style={{color:"black"}}>When does Reward Bonus expire?</h5>
                            <FontAwesomeIcon icon={faArrowDown} onClick={handleClick5}/>
                            </div>
                            <p className={answerState.question5}>
                            Reward Bonus earned via Refer and Earn will expire after 3 months of credit date. Bonus earned via installing the MMT app will expire after 1 month of the install date. All other forms of bonus starts to expire after 7 days from the credit date.
                            </p>
                        </div>
                        <hr />
                        <div className="question">
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                            <h5 style={{color:"black"}}>Where is Reward Bonus applicable?</h5>
                            <FontAwesomeIcon icon={faArrowDown} onClick={handleClick6}/>
                            </div>
                            <p className={answerState.question6}>
                            Reward Bonus can be applied with restrictions all any booking such as Flights, Hotels, Holidays, Cabs, Trains and Bus. To know more about the restrictions related to Reward Bonus, tap on How to use for reward bonus on the My Wallet page.
                            </p>
                        </div>
                        <hr />
                    </div>
                </div>
                
            </div>
            
           <div className="Some-Content">

           </div>
            
        </div>
    )
}

export default Wallet
