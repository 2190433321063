import React, { Component } from 'react';
import "./ResetPassword.css";
import NewHeader from "../NewHeader/NewHeader";
import { UpdatePasswordReset } from '../axios';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

class ResetPassword extends Component {

    constructor() {
        super();
        this.state = {
            ChangeColor: 'reviewPlanned',
            customerDetails: {},
            password: '',
            errorPassword: false,
            hidden: false,
            changeClassForPassword: 'myPrfilSprit  txtFild__rightpasswordIcon myPrfilSprit__showPswrd',
            Redirect: false,
            ConfirmPassword: '',
            errorConfirm: false,
        };

    }

    ShowPassword = () => {
        this.setState({ hidden: true });
    }

    HidePassword = () => {
        this.setState({ hidden: false });
    }

    setPassword = (event) => {
        if (event.target.value.length > 0) {
            this.setState({ password: event.target.value });
            this.setState({ errorPassword: false });
        } else {
            this.setState({ password: event.target.value });
            this.setState({ errorPassword: true });
        }

    }

    setConfPassword = (event) => {
        if (event.target.value.length > 0 && event.target.value == this.state.password) {
            this.setState({ ConfirmPassword: event.target.value });
            this.setState({ errorConfirm: false });
        } else {
            this.setState({ ConfirmPassword: event.target.value });
            this.setState({ errorConfirm: true });
        }

    }

    
  //Reset Password Details
    SaveReset = () => {
        if(this.state.errorConfirm == false){
        let email ='ranjeet.terai@gmail.com';
        let password = this.state.password;
       
        UpdatePasswordReset(email, password).then(
            (data) => {
                console.log(data);
                if (data> 0) {
                    window.location.reload(true);
                }
            },
            (error) => {

            }
        );
        }else{
            this.setState({ errorConfirm: true });
        }
    
    }

    render() {
        return (
            <div>
                <NewHeader page={'inner_listing'} />
                <div className="TripContainer" id="container">
                    <div className={this.state.ChangeColor} style={{ marginTop: '-5px' }}>
                        <div className="landingContainer"><h4>Reset Password</h4>
                        </div>
                    </div>

                  
                        <div className="contentWrapper contentWrapper--myProfile">
                       
                            <div className="rightSecWrapper">
                              
                                <div className="lognDtalComp cm__myTripSideBar cm__myProfileTheme" id="profilePageComponent1"><div className="detalCrdHadr undefined">
                                    <div className="appendRight10"><h3 className="font30 latoBold blackText">Reset Password</h3>
                                       </div>
                                    <div>
                                    </div>
                                </div>
                                  
                                            <div className="guestDtls__row" style={{ marginTop: 40 }}>
                                                <div className="paymakeFlex">
                                                    <div className="guestDtls__col width327 appendRight10">
                                                        <div className="textFieldCol " style={{ position: 'relative' }}>
                                                            <p className="font11 appendBottom10 guestDtlsTextLbl">
                                                                <span className="capText">New Password</span>
                                                            </p>
                                                            <input type={this.state.hidden ? 'text' : 'password'} className={`frmTextInput ${this.state.errorPassword ? "Err" : ""}`} placeholder="New Password" value={this.state.password} onChange={this.setPassword} />
                                                            {this.state.hidden == true ? (
                                                                <span className="myPrfilSprit  txtFild__rightpasswordIcon myPrfilSprit__hidePswrd" onClick={this.HidePassword}></span>
                                                            ) : (
                                                                <span className="myPrfilSprit  txtFild__rightpasswordIcon myPrfilSprit__showPswrd" onClick={this.ShowPassword}></span>
                                                            )}
                                                            {this.state.errorPassword && <p className="error">Please enter your password </p>}

                                                        </div>
                                                    </div>
                                                    <div className="guestDtls__col width327 appendRight10">
                                                        <div className="textFieldCol " style={{ position: 'relative' }}>
                                                            <p className="font11 appendBottom10 guestDtlsTextLbl">
                                                                <span className="capText">Confirm Password</span>
                                                            </p>
                                                            <input type={this.state.hidden ? 'text' : 'password'} className={`frmTextInput ${this.state.errorConfirm ? "Err" : ""}`} placeholder="Confirm Password" value={this.state.ConfirmPassword} onChange={this.setConfPassword} />
                                                            {this.state.hidden == true ? (
                                                                <span className="myPrfilSprit  txtFild__rightpasswordIcon myPrfilSprit__hidePswrd" onClick={this.HidePassword}></span>
                                                            ) : (
                                                                <span className="myPrfilSprit  txtFild__rightpasswordIcon myPrfilSprit__showPswrd" onClick={this.ShowPassword}></span>
                                                            )}
                                                            {this.state.errorConfirm && <p className="error">Password not match! </p>}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <button type="button" className="font16 latoBold blueText btn__dtailAdSave" onClick={() => this.SaveReset()}>
                                                Submit</button>
                                     
                                </div>
                            </div>
                        </div>
                  

                </div>
            </div>
        )
    }
}

export default ResetPassword;
