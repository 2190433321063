import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import React,{Component} from "react";
 
export class MapContainer extends Component {
     containerStyle = {
        position: 'absolute',  
        width: '50%',
        height: '70%'
      }
  render() {
    return (
      <Map google={this.props.google} zoom={14} containerStyle={this.containerStyle}> 
 
        <Marker onClick={this.onMarkerClick}
                name={'Current location'} />
 
        <InfoWindow onClose={this.onInfoWindowClose}>

        </InfoWindow>
      </Map>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ("AIzaSyAv5U8PE_67iy1qEPjubgvyWUUhZ5xNat8")
})(MapContainer)